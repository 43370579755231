import { Link } from '../components/ui/link'

/**
 * Handlers for board events by type and notification
 */

const TypesHandlers = {
  heartbeat: (store, msg) => { store.boardLastSeen = Date.now() },
  kill_switch: (store,msg) => { store.mixer.panicMode = msg.value },
  bad_network: (store,msg ) => {
    store.showToast({
      type: 'POOR_NETWORK',
      heading: 'poor network detected',
      content: <span>happens often?&nbsp;<Link href="https://help.elk.live/en/articles/7193334-poor-network-detected" target="_blank" rel="noopener noreferrer">click here for help!</Link></span>,
      duration: 10000,
    })
  },
  sushi_crash: async (store, msg) => {
    await store.mixer.handleSushiCrash(msg)
  },
  speedtest: async (store, msg) => {
    store.setSpeedTestData(msg.results)
  }
}

const NotificationsHandler = {
  connection_status: (store, msg) => {  store.session.handleICEStatusMessage(msg) },
  bridge_takeover: (store, msg ) => { store.disconnectFromBoard() }
}

export { TypesHandlers, NotificationsHandler }

