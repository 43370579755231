import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { INPUT_OPTION_NONE } from '../../../../store/input-options'

const SendControlsWrapper = styled.div`
  border: 2px solid var(--ELK-White);
  padding: 2px;
  display: flex;
  margin-top: 20px;
  column-gap: 2px;
  cursor: pointer;
  &.disabled {
    border-color: var(--ELK-Dark-Green-Disabled);
    cursor: default;
  }
`

const SendControlsButton = styled.div`
  color: white;
  width: 70px;
  transition: 0.3s;
  padding: 3px 0;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
  &.variant-red.on {
    background: var(--ELK-Candy-Tangerine);
  }
  &.variant-green.on {
    background: var(--ELK-Sea-Foam-Green);
    color: var(--ELK-Black);
  }
  &.disabled {
    color: var(--ELK-Dark-Green-Disabled);
  }
  &.disabled.on {
    background: var(--ELK-Dark-Green-Disabled) !important;
    color: var(--ELK-Dark-Green) !important;
  }
`

const SendControls = observer(({ channelIndex, stereoLinking }) => {
  const store = useStore()
  const isOn = !store.mixer.panicMode && store.mixer.send[stereoLinking ? 0 : channelIndex]
  const isEnabled = store.mixer.inputOptions[stereoLinking ? 0 : channelIndex] !== INPUT_OPTION_NONE

  return (
    <SendControlsWrapper
      className={`${isEnabled ? 'enabled' : 'disabled'}`}
      onClick={() => {
        if (store.mixer.panicMode) {
          console.log('damn!')
          store.mixer.panicModeShouldFlash = true
          return
        }
        if (!isEnabled) return
        if (!stereoLinking) {
          store.mixer.send[channelIndex] = !store.mixer.send[channelIndex]
        } else {
          const send = !store.mixer.send[0]
          store.mixer.send[0] = send
          store.mixer.send[1] = send
        }
      }}
    >
      <SendControlsButton className={`variant-red ${isOn ? 'off' : 'on'} ${isEnabled ? 'enabled' : 'disabled'}`}>
        private
      </SendControlsButton>
      <SendControlsButton className={`variant-green ${isOn ? 'on' : 'off'} ${isEnabled ? 'enabled' : 'disabled'}`}>
        live!
      </SendControlsButton>
    </SendControlsWrapper>
  )
})

export default SendControls