import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useStore, withStore } from '../../../store'
import { openModal } from '../overlays'
import { CloseButton, Dialog } from '../base'
import { Box, Center, H1, H3 } from '../../ui/primitives'
import {
  StopIcon,
  GlobeIcon,
  EmptyPartnerIcon
} from '../../ui/icons'
import { ProfilePicture } from '../../ui/profile-picture'
import { Row, RowText } from '../../ui/row'
import { ActiveStatus } from '../../features/active-status'
import {
  SettingsButton,
  PrimaryColumn,
  ContentWrapper,
  StyledList,
  Grid,
  SelectedPartnerBox,
  SelectedPartnerName,
  RemoveIconWrapper,
  ActiveIconWrapper,
  StyledButton
} from './styles'
import { AUTOMATIC_LABEL, Settings } from './settings'
import { showSessionLimitDialog } from './session-limit-dialog'

/**
 *
 *  If sessionMode is true the behaviour will be adding new partnerIds to the session
 */
export const AddPartnerToSessionDialog = observer(({
  partnerIds,
  close
}) => {
  const store = useStore()
  const profileRefs = useRef([])

  const partners = store.getSortedFriends().filter((friend) => {
    return friend.status === 'Confirmed'
  })

  useEffect(() => {
    partnerIds.forEach(( parnertId ) => {
      store.requestFetchUserInfo(parnertId, true)
    })
    // eslint-disable-next-line
  }, [])

  const [settingsVisible, setSettingsVisible] = useState(store.selectedTurnServerTitle !== AUTOMATIC_LABEL)

  const [selectedPartners, setSelectedPartners] = useState(
    Array.from({ length: 4 }, (v, i) => partnerIds[i] ?? null)
  )
  const [draggedId, setDraggedId] = useState(false)

  const filteredPartners = selectedPartners.filter((el) => {
    return el !== null
  })

  const partnersToAdd = store.sessionMode
    ?
    filteredPartners.filter(id => !store.session.partnerIds.includes(id))
    : filteredPartners

  const isSubmitButtonDisabled = store.sessionMode ? store.sessionMode && partnersToAdd.length <= 0 : filteredPartners.length <= 0

  const selectPartner = (id, index) => {

    if(store.sessionMode && partnersToAdd.length === 1){
      showSessionLimitDialog()
      return
    }

    const emptyIndex = index || selectedPartners.findIndex((partnerId) => partnerId === null)

    if (emptyIndex !== -1) {
      const updatedPartners = [...selectedPartners]
      updatedPartners[emptyIndex] = id
      setSelectedPartners(updatedPartners)
    }
  }

  const removePartner = (index) => {
    const updatedPartners = [...selectedPartners]
    updatedPartners[index] = null
    setSelectedPartners(updatedPartners)
  }

  const onDragStart = (e, id, index) => {
    e.dataTransfer.setDragImage(profileRefs.current[index], 0, 0)
    setDraggedId(id)
  }

  const onDropAdd = (e, index) => {
    e.preventDefault()
    const updatedPartners = [...selectedPartners]

    if (selectedPartners.includes(draggedId)) {
      const draggedIndex = selectedPartners.findIndex((id) => id === draggedId)
      updatedPartners[draggedIndex] = null
      updatedPartners[index] = draggedId
      setSelectedPartners(updatedPartners)
    } else {
      selectPartner(draggedId, index)
    }
  }

  const onDropRemove = (e) => {
    e.preventDefault()
    const selectedIndex = selectedPartners.findIndex((id) => id === draggedId)
    removePartner(selectedIndex)
  }

  const onDropReplace = (e, index) => {
    e.preventDefault()
    const updatedPartners = [...selectedPartners]

    if (selectedPartners.includes(draggedId)) {
      const draggedIndex = selectedPartners.findIndex((id) => id === draggedId)
      updatedPartners[draggedIndex] = null
    }
    updatedPartners[index] = draggedId
    setSelectedPartners(updatedPartners)
  }

  const onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }


  return (
    <Box>
      <Dialog
        width='111.5rem'
        flex
        flexDirection='column'
        bgColor='var(--ELK-White)'
        color='var(--ELK-Black)'
        zIndex='1'
      >
        <CloseButton onClick={() => close()} color='var(--ELK-Black)' />
        <H1>{store.sessionMode ? 'Add people to ongoing session' : 'Start New Session'}</H1>
        <ContentWrapper>
          <PrimaryColumn>
            <StyledList
              droppable
              onDrop={(e) => onDropRemove(e)}
              onDragOver={(e) => onDragOver(e)}
            >
              {partners &&
                partners.map((user, index) => {
                  const alreadySelectedUser = selectedPartners.find(
                    (id) => id === user.id
                  )
                  if (alreadySelectedUser) return false
                  const isActive = !!store.userActive.get(user.id)
                  const isAvailable = !!store.userAvailable.get(user.id)
                  const inSession = isActive && !isAvailable

                  const userData = store.users.get(user.id)
                  const fallbackDataUser =  partners.find(({ id }) => user.id === id)
                  const userDataToDisplay = userData ? userData : {
                    ...fallbackDataUser, name: fallbackDataUser.displayName, profile_image_url: fallbackDataUser.profileImageUrl
                  }

                  return (
                    <Row
                      key={user.id}
                      draggable={!inSession}
                      onDragStart={(e) => {
                        if (inSession) return false
                        onDragStart(e, user.id, index)
                      }}
                      onClick={() => {
                        if (inSession) return false
                        selectPartner(user.id)
                      }}
                    >
                      <Box
                        minWidth='6rem'
                        height='6rem'
                        ref={(el) => (profileRefs.current[index] = el)}
                      >
                        <ProfilePicture user={userDataToDisplay} />
                      </Box>
                      <ActiveIconWrapper>
                        <ActiveStatus user={userDataToDisplay} size='1.8rem' />
                      </ActiveIconWrapper>
                      <RowText
                        color={
                          inSession
                            ? 'var(--ELK-Text-Standard-Inactive)'
                            : 'var(--ELK-Black)'
                        }
                      >
                        {userDataToDisplay?.name}
                      </RowText>
                      {inSession && (
                        <H3
                          color='var(--ELK-High-Contrast-Red)'
                          ml='auto'
                          mr='2.4rem'
                          whiteSpace='nowrap'
                        >
                          already in another session!
                        </H3>
                      )}
                    </Row>
                  )
                })}
            </StyledList>
            <Box flex height='60px'>
              {!store.sessionMode && (
                <>
                  <SettingsButton onClick={() => {
                    setSettingsVisible(!settingsVisible)
                    if (settingsVisible) {
                      store.selectedTurnServerTitle = AUTOMATIC_LABEL
                    }
                  }}
                  >
                    <GlobeIcon size={40} />
                  </SettingsButton>
                  {settingsVisible && (
                    <Settings />
                  )}
                </>
              )}
            </Box>
          </PrimaryColumn>
          <Box flex column ml='26px'>
            <Grid>
              {selectedPartners.map((id, index) => {
                const userData = store.users.get(id)
                if (id == null) {
                  return (
                    <Box key={index}>
                      <Center
                        relative
                        bgColor='var(--ELK-Standard-Inactive)'
                        width='100%'
                        height='100%'
                        droppable
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDropAdd(e, index)}
                      >
                        <EmptyPartnerIcon />
                      </Center>
                    </Box>
                  )
                }

                const fallbackDataUser =  partners.find(({ id : partnerId }) => partnerId === id)
                const userDataToDisplay = userData ? userData : {
                  ...fallbackDataUser, name: fallbackDataUser.displayName, profile_image_url: fallbackDataUser.profileImageUrl
                }

                return (
                  <SelectedPartnerBox
                    key={index}
                    draggable={partnersToAdd.includes(id)}
                    onDragStart={() => setDraggedId(id)}
                    droppable
                    onDragOver={(e) => onDragOver(e)}
                    onDrop={(e) => onDropReplace(e, index)}
                  >
                    <ProfilePicture user={userDataToDisplay} />
                    <SelectedPartnerName>
                      <H3>{userDataToDisplay?.name}</H3>
                      {partnersToAdd.includes(id) && (
                        <RemoveIconWrapper>
                          <StopIcon size='18' onClick={() => removePartner(index)} />
                        </RemoveIconWrapper>
                      )}
                    </SelectedPartnerName>
                  </SelectedPartnerBox>
                )
              })}
            </Grid>
            <StyledButton
              secondary
              onClick={async () => {
                if (!store.board) {
                  store.showSnackBar({
                    heading: "Can't start session when you're not connected to the bridge.",
                    content:
  <>Make sure your device is connected properly (<a rel='noreferrer' target='_blank' href='https://help.elk.live/en/articles/5796614-how-to-connect-your-bridge'>help?</a>)</>,
                    level: 'error'
                  })
                } else if (
                  partnersToAdd.some(
                    (id) => !store.userActive.get(id) || !store.userAvailable.get(id)
                  )
                ) {
                  store.showSnackBar({
                    heading: "Can't start session.",
                    content: "Can't start session unless all users are available",
                    level: 'error'
                  })
                } else {
                  const payload = {}
                  if (store.selectedTurnServerTitle !== AUTOMATIC_LABEL) {
                    payload.turn_server = store.selectedTurnServerIp
                  }
                  if (store.session.id) {
                    await store.addPartnersToOngoingSession(partnersToAdd[0])
                  } else {
                    await store.createSession(partnersToAdd, payload)
                  }
                  close()
                }
              }}
              disabled={isSubmitButtonDisabled}
            >
              {store.sessionMode ? 'back to ongoing session' : 'Let’s Go!'}
            </StyledButton>
          </Box>
        </ContentWrapper>
      </Dialog>
    </Box>
  )
})

export function showAddPartnerToSessionDialog ({ store, partnerIds, afterClose }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <AddPartnerToSessionDialog partnerIds={partnerIds} close={close} />
      ),
    {
      autoDismissable: true,
      onClose: () => {
        afterClose()
      }
    }
  )
}
