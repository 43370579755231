import { APIClient } from '../api/client'
import { version } from '../../package.json'
import { makeAutoObservable } from 'mobx'

import { INPUT_OPTION_NONE } from './input-options'

export const DEFAULT_JITTER_BUFFER_MULTIPLIER = 8
export const DEFAULT_LOCAL_COMPENSATION_DELAY = 6

export const DEFAULT_CORE_OVERRIDE_URL =
  'https://elk-aloha.s3.eu-north-1.amazonaws.com/aloha-core.tar.gz'

export const defaultSettings = {
  version: version,
  subscription: {
    canceledAt: null,
  },
  connection: {
    allowCustomCore: false,
    boardIpAddress: null,
    udpPortOverride: null,
    advertisedIpOverride: null,
    bypassPortForwardingTest: false,
    senderBufferSize: 1,
    testSignalMode: false,
    useTurn: false,
  },
  videoChat: {
    selectedVideoInputDeviceId: null,
    selectedAudioInputDeviceId: null,
    uplinkMaxBandwidth: 1000,
    uplinkFramerate: 15,
    width: 960,
    height: 540,
    enableBandwidthOptimizer: true,
  },
  ui: {
    playRingtone: true,
    showJitterDelaySettings: false,
    showAdvancedSettings: false,
    hideUsbConfirmation: false,
  },
  compensation: {
    mode1Preset: 4,
    mode2Preset: 6,
    mode3Preset: 10,
  },
  ecc: {
    midFilterLength: 256,
    sideFilterLength: 32,
    crossFadeLength: 0.025,
  },
  knownDevices: {},
  partnerData: {},
  sessionData: {},
  hw_settings: {
    master_gain: 0,
    master_muted: false,
    headphones_gain: 0,
    headphones_muted: false,
    line_output_gain: 0,
    input_options: [INPUT_OPTION_NONE, INPUT_OPTION_NONE],
    input_gains: [0, 0],
    subchannels: [0, 0],
    phantom_power: [false, false],
    stereo_link: false,
    compensation_delay: DEFAULT_LOCAL_COMPENSATION_DELAY,
  },
  sync: {
    enableAutoResync: true,
    autoResyncThreshold: 0.5,
    enableAutoJitter: true,
    autoJitterThreshold: 0.02,
    enableSoftResync: true,
    restrictiveHysteresisThreshold: 12,
    minimumDelayInBuffers: 4
  },
}

export class Settings {
  constructor(api) {
    Object.assign(this, clone(defaultSettings))
    makeAutoObservable(this)
  }

  onChanged = null

  reset() {
    Object.assign(this, clone(defaultSettings))
  }

  compareVersions(vA, vB) {
    if (typeof vA === 'undefined' || typeof vB === 'undefined') {
      return false
    }
    const A = vA.split('.')[0] + vA.split('.')[1]
    const B = vB.split('.')[0] + vB.split('.')[1]
    return A === B
  }

  updateFromJson(json) {
    deepMerge(this, json)
  }

  async loadFromCloud(userId) {
    const data = await APIClient.get_user_settings(userId)
    if (typeof data === 'object') {
      const saved_settings_version = data.version
      if (this.compareVersions(saved_settings_version, version)) {
        this.updateFromJson(data)
        this.onChanged()
      } else {
        this.save()
      }
    } else {
      this.save()
    }
  }

  async resetPhantomPowerIfEnabled(userId) {
    if (!this.hw_settings?.phantom_power || this.hw_settings?.phantom_power[0] || this.hw_settings?.phantom_power[1]) {
      this.hw_settings.phantom_power = [false, false]
      await this.save()
    }
  }

  get snapshot() {
    return {
      version: version,
      subscription: this.subscription,
      debug: this.debug,
      connection: this.connection,
      videoChat: this.videoChat,
      ui: this.ui,
      compensation: this.compensation,
      ecc: this.ecc,
      partnerData: this.partnerData,
      knownDevices: this.knownDevices,
      sessionData: this.sessionData,
      sync: this.sync,
      hw_settings: this.hw_settings,
    }
  }

  async save() {
    await APIClient.save_user_settings(this.snapshot)
    this.onChanged()
  }

}

/* utilities */

function deepMerge(target, source) {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], deepMerge(target[key] || {}, source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source)
  return target
}

function clone(source) {
  return JSON.parse(JSON.stringify(source))
}
