import { useStore } from '../../../../store'
import styled from 'styled-components'
import { PAGE_VIEWS } from '../index'
import { observer } from 'mobx-react-lite'

const WarningBanner = styled.div`
  width: 100%;
  color: var(--ELK-Black);
  text-align: center;
  padding: 1rem 0;
  background: #FFBCBC;
  font-size: 1.4rem;
`;

const BannerLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
`

const SubscriptionBanner = ({ setPageView }) => (
<WarningBanner>
    Looks like you don't have an active subscription and therefore the experience is limited. Get your subscription going
    {' '}
    <BannerLink onClick={() => setPageView(PAGE_VIEWS.SUBSCRIPTION)}>
      here
    </BannerLink>
    .
  </WarningBanner>
)

const MaintenanceModeBanner = () => {
  return (
    <WarningBanner>
      <>
        Elk LIVE is experiencing technical issues right now. We should be back soon. Sorry for the inconvenience.
      </>
    </WarningBanner>
  )
}

const MaintenaceScheduleBanner = () => {
    return (
      <WarningBanner>
        <>
          Heads up! We’ll be running some maintenance to the system that might affect your connection. Sorry for any inconvenience!
        </>
      </WarningBanner>
    )
}

export const Banners = observer(({ setPageView }) => {
  const store = useStore()
  return (
    <>
      {store.isMaintenanceModeOn() && <MaintenanceModeBanner />}
      {!store.userHasActiveSubscription && store.subscriptionsEnabled && <SubscriptionBanner setPageView={setPageView} />}
      {store.isMaintenanceScheduled() && <MaintenaceScheduleBanner />}
    </>
  )
})