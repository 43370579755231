import styled from 'styled-components'

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 48px;
  width: 100%;
  color: var(--ELK-White);
  background-color: var(--ELK-Black);
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  &.off {
    color: var(--ELK-White);
    background-color: var(--ELK-High-Prio-Red-Enabled);
  }
  &.on {
    color: var(--ELK-Black);
    background-color: var(--ELK-White);
  }
  &.open {
    color: var(--ELK-White);
    background-color: var(--ELK-Standard-Pressed);
  }
`
