import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dialog, ModalButton } from './base'
import { openModal } from './overlays'
import { Box, Text, H1 } from '../ui/primitives'
import { withStore } from '../../store'
import { isWirelessConnection } from '../../store/electronAPI'

const LowInternetDialog = observer(({ close }) => {

  const isWifi = isWirelessConnection()
  return (
    <Dialog
      width="60rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <H1 fontSize="3.2rem" color="var(--ELK-Black)" textAlign="center" mb="2.5rem">Slow internet connection!</H1>
      <Text textAlign="center" mb="5rem" mt="5rem" color="var(--ELK-Black)" fontSize="1.8rem">
        Your internet connection is pretty slow,
        {isWifi && ' it might be because you are on WiFi, '}
        so you might not get the best performance possible.
        <br /><br />
        Is there anything else stealing bandwidth on your network (streaming, downloads, etc)?
      </Text>

      <Box mt="2rem" maxWidth="32rem" mx="auto" flex column rowGap="2rem">
        <ModalButton primary height="3.8rem" width="100%" fontSize="1.8rem" color="#1c1c1c" py="10px" onClick={() => {close()}}>Ok, got it!</ModalButton>
      </Box>
    </Dialog>
  )
})

export function showLowInternetDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <LowInternetDialog close={close}/>), {
      autoDismissable: true,
      alwaysOnTop: true
    }
  )
}
