import styled from 'styled-components'
import { Box, H2, H3 } from '../../ui/primitives'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--Elk-Background-Grey);
  color: var(--ELK-White);
`

const AddFriendsButton = styled.button`
  display: flex;
  flex-direction: row;
  color: var(--ELK-Black);
  font-weight: 700;
  align-items: center;
`

const VerticalRuler = styled.div`
  min-width: 2px;
  width: 2px;
  background: var(--ELK-Black);
  border-radius: 10px;
  margin: 1.6rem;
`

const FriendsList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 2rem;
`

const MainContent = styled.div`
  display: flex;
  min-height: 0;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0 1.6rem 1.6rem;
`;

const SidebarItem = styled.div`
  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

const Scrollable = styled(Box)`
  width: 100%;
  padding: 1.6rem 1.6rem 1.6rem 0;
  overflow: auto;
`;

const PrimaryContentContainer = styled.div`
  width: 100%;
`;

const SidebarMenuContainer = styled.div`
`;

const SidebarMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.active ? 'var(--ELK-High-Prio-Yellow-Enabled)' : 'transparent'};
  color:  ${(props) => props.active ? 'var(--ELK-Black)' : 'var(--ELK-White)'};
  cursor: pointer;
  padding: 6px;
  padding-left: ${(props) => props.submenu ? '22px' : '6px'};
  font-size: 12px;
  user-select: none;
  font-weight: bold;
  svg {
    pointer-events: all;
    cursor: pointer;
  }
`;

const AddFriendsItem = styled(SidebarMenuItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--ELK-Intense-Pink);
  color: #fff;
  svg {
    margin-right: 10px;
  }
`

const ShareButton = styled(AddFriendsItem)`
  position: absolute;
  bottom: 1.6rem;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ShareButtonModal = styled(AddFriendsItem)`
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`

const Subtitle = styled(H3)`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
`

const AddFriendCard = styled.div`
  position: relative;
  width: 20vh;
  min-height: 214px;
  min-width: 180px;
  max-width: 225px;
  color: white;
  background: var(--ELK-Intense-Pink);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  cursor: pointer;
  h2 {
    margin-top: 2rem;
    font-weight: 700;
  }
   &:hover {
    filter: brightness(0.58);
  }
`

const FriendsStyledH2 = styled(H2)`
  width: 100%;
  font-weight: bold;
`

const EmptyFriendsContainer = styled.div`
    width: 370px;
    min-height: 214px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2 {
      font-weight: 700;
      font-size: 2.8rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 14px;
    }
`

export {
  Container,
  AddFriendsButton,
  VerticalRuler,
  FriendsList,
  MainContent,
  Sidebar,
  SidebarItem,
  Scrollable,
  PrimaryContentContainer,
  SidebarMenuContainer,
  SidebarMenuItem,
  AddFriendsItem,
  ShareButton,
  Subtitle,
  AddFriendCard,
  ShareButtonModal,
  EmptyFriendsContainer,
  FriendsStyledH2
}