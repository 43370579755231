import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import { AddFriendIcon, ShareIcon } from '../../ui/icons'
import { showAddFriendsDialog } from '../../dialogs/add-friends'
import { UserProfile } from './user-profile-view'
import SubscriptionPage from './subscriptions'
import { Card } from '../../cards/card'
import PrivatestudioBanner from '../../features/privatestudio-banner'
import useIsPrivatestudio from '../../../hooks/use-privatestudio'

import { ACTIVE_VIEW_STATE_LOBBY } from '../../../store/active-view-states'
import { Banners } from './banners'

import {
  Container,
  VerticalRuler,
  MainContent,
  Sidebar,
  SidebarItem,
  Scrollable,
  PrimaryContentContainer,
  AddFriendsItem,
  ShareButton
} from './styles'
import InviteShare from './components/share'
import FriendsView from './people-view'
import SidebarMenu from './sidebar-menu'

export const PAGE_VIEWS = {
  FRIENDS: 'friends',
  PROFILE_SETTINGS: 'profile-settings',
  SUBSCRIPTION: 'subscription',
}

const {
  FRIENDS,
  PROFILE_SETTINGS,
  SUBSCRIPTION,
} = PAGE_VIEWS

const PrimaryContent = ({ pageView }) => {
  let selectedView = null;
  switch (pageView) {
    case PROFILE_SETTINGS:
      selectedView = <UserProfile />
      break;
    case SUBSCRIPTION:
      selectedView = <SubscriptionPage />
      break;
    case FRIENDS:
    default:
      selectedView = <FriendsView />
  }

  return (
    <PrimaryContentContainer>
      {selectedView}
    </PrimaryContentContainer>
  );
};

export const LobbyView = observer(() => {
  const store = useStore()
  const isPrivatestudio = useIsPrivatestudio()
  const session = store.session
  const [pageView, setPageView] = useState(FRIENDS);

  useEffect(() => {
    if (store.activeViewState.includes(ACTIVE_VIEW_STATE_LOBBY)) {
      if(store.activeViewState.includes('/')){
        const redirectPageView = store.activeViewState.split('/')[1]
        setPageView(redirectPageView)
        return
      }
      setPageView(FRIENDS)
    }
  }, [store.activeViewState])

  return (
    <Container>
      <Banners setPageView={setPageView}/>
      <MainContent>
        <Sidebar>
          <SidebarItem onClick={ ()=> {
            setPageView(PROFILE_SETTINGS)
          }}>
            <Card user={store.users.get(store.currentUserId)} type="ownUser" />
          </SidebarItem>
          {session && store.sessionMode && (
            <SidebarItem>
              <Card type="liveSession" />
            </SidebarItem>
          )}
          <SidebarItem id="step-2-friends">
              <AddFriendsItem
                active
                onClick={() => showAddFriendsDialog({ store })}
              >
                <AddFriendIcon />
                add friends
              </AddFriendsItem>
          </SidebarItem>
          <SidebarItem>
            <SidebarMenu currentView={pageView} navigate={setPageView} />
          </SidebarItem>
          {isPrivatestudio && (
            <SidebarItem>
              <PrivatestudioBanner />
            </SidebarItem>
          )}
          <ShareButton
            id="share-button"
            active
            onClick={() => { store.showSocialShare = !store.showSocialShare }}
          >
            <ShareIcon />
            Invite to Elk LIVE!
          </ShareButton>
          {store.showSocialShare && (
            <>
              <div className="backdrop" style={{zIndex: 0}} onClick={() => { store.showSocialShare = false }}>
              </div>
              <div className="share-modal">
                <InviteShare onShare={() => { store.showSocialShare = false }} />
              </div>
            </>
          )}
        </Sidebar>
        <VerticalRuler />
        <Scrollable ref={store.scrollableRef}>
          <PrimaryContent pageView={pageView} />
        </Scrollable>
      </MainContent>
    </Container>
  )
})
