import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../store/board-connection-states'
import { TrackControl } from './components/track-control'
import BridgeSettings from '../../features/BridgeSettings'
import { SESSION_PARTNER_STATE_JOINED } from '../../../store/session-partner-states'
import { CollapseIcon } from '../../ui/icons'
import { SettingsWrapper, CloseButton, Container, MixerWrapper, TrackWrapper } from './styles'

export const Mixer = observer(() => {
  const settingsRef = useRef(null)
  const store = useStore()
  const session = store.session

  if (!session) return null

  return (
    <Container>
      <MixerWrapper className={store.showBridgeSettingsView && store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED ? 'expanded' : ''}>
        <SettingsWrapper ref={settingsRef}>
          <CloseButton onClick={() => {
            store.showBridgeSettingsView = false
          }}>
            <CollapseIcon />
          </CloseButton>
          <BridgeSettings />
        </SettingsWrapper>

        <TrackWrapper key={store.mixer.OWN}>
          <TrackControl
            trackId={store.mixer.OWN}
            userId={store.currentUserId}
            disabled={store.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED}
          />
        </TrackWrapper>

        {store.sessionMode && store.session.partnerIds.map((userId) =>
          <PartnerTrack store={store} userId={userId} />
        )}
      </MixerWrapper>
    </Container>
  )
})

const PartnerTrack = observer(({ store, userId }) => {
  const disabled = store.session?.partnerState.get(userId) !== SESSION_PARTNER_STATE_JOINED

  return (
    <TrackWrapper key={userId}>
      <TrackControl
        trackId={userId}
        userId={userId}
        disabled={disabled}
      />
    </TrackWrapper>
  )
})

export default Mixer;
