import React from 'react'
import { observer } from 'mobx-react-lite'
import { CloseButton, Dialog } from '../base'
import { openModal } from '../overlays'
import { Box, H1, Text } from '../../ui/primitives'
import styled from 'styled-components'
import Button from '../../ui/button'

const ModalFooter = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const SessionLimitDialog = observer(({ close }) => {
  return (
    <Dialog
      width="60rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)"/>

      <H1 color="var(--ELK-Black)" textAlign="center" mb="2.5rem">One at a time, please!</H1>
      <Text textAlign="center" mb="3rem" color="black" fontSize="2.4rem">
        To add another bandmate, just go back to your session and repeat the process.
      </Text>
      <ModalFooter color="var(--ELK-Black)">
        <Button
          primary
          onClick={close}>
          Ok! I got it
        </Button>
      </ModalFooter>
    </Dialog>
  )
})

export function showSessionLimitDialog() {
  return openModal(
    ({ close }) => <SessionLimitDialog close={close}/>, {
      autoDismissable: true,
    }
  )
}
