import React from 'react'
import { Box, H3 } from '../ui/primitives'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'
import { ProfilePicture } from '../ui/profile-picture'
import {
  CardContent,
  CardList,
  CardListItem,
  IconWrapper,
} from './card-components'
import {
  SessionEqualizer,
} from '../ui/icons'

export const OwnUserCard = observer(({ user }) => {
  const store = useStore()

  const userIsActive = store.userActive.get(user?.id)
  const userIsAvailable = store.userAvailable.get(user?.id)
  const userInSession = userIsActive && !userIsAvailable

  return (
    <>
      <CardContent>
        <ProfilePicture user={user} />
      </CardContent>
      <CardList>
        <CardListItem px="2.7rem"
                      bgColor={userIsActive ?
                        'var(--ELK-Sea-Foam-Green)' :
                        'var(--ELK-Offline-Grey)'}
        >
          <H3>{`${user?.name} (you)`}</H3>
          {userInSession ? (
            <IconWrapper>
              <SessionEqualizer size={15} color="var(--ELK-Black)" />
            </IconWrapper>
          ) : (
            <Box />
          )}
        </CardListItem>

      </CardList>
    </>
  )
})
