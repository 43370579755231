import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'

import useKeyPressed from '../../../../hooks/use-key-pressed'
import useOnClickOutside from '../../../../hooks/use-on-click-outside'
import { Box } from '../../../ui/primitives'

const sharedCss = css`
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: inherit;
  border: 2px solid transparent;
  outline: none;
`

const Read = styled.span`
  display: ${({ $active }) => ($active === true ? 'none' : 'block')};
  ${sharedCss}

  ${({ $active }) =>
    !$active &&
    css`
      cursor: pointer;
    `}
`

const Edit = styled.input`
  ${({ $active }) =>
    $active === false &&
    css`
      display: none;
    `}
  ${sharedCss}
  border: 2px solid var(--ELK-Black);
  max-width: 100%;
`

function InlineEditableInput({ value, onSave, readFormatter, ...rest }) {
  const [editMode, setEditMode] = useState(false)
  const [inputValue, setInputValue] = useState(value)

  const wrapperRef = useRef(null)
  const inputRef = useRef(null)

  const enterPressed = useKeyPressed('Enter')
  const escPressed = useKeyPressed('Escape')

  const outsideClickHandler = useCallback(() => {
    if (editMode) {
      if (onSave && typeof onSave === 'function') {
        onSave(inputValue)
      }
      setEditMode(false)
    }
  }, [inputValue, editMode, onSave])
  useOnClickOutside(wrapperRef, outsideClickHandler)

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (editMode) {
      inputRef.current.focus()
    }
  }, [editMode])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  // if Escape is pressed, revert the text and close the editor
  useEffect(() => {
    if (editMode && escPressed) {
      setInputValue(value)
      setEditMode(false)
    }
  }, [editMode, escPressed, value])

  // if Enter is pressed, save the text and close the editor
  useEffect(() => {
    if (editMode && enterPressed) {
      if (onSave && typeof onSave === 'function') {
        const cancelled = onSave(inputValue)
        if (cancelled === false) {
          setInputValue(value)
        }
      }
      setEditMode(false)
    }
  }, [editMode, enterPressed, onSave, inputValue, value])

  const handleInputChange = useCallback(
    (ev) => {
      setInputValue(ev.target.value)
    },
    [setInputValue],
  )

  const toggleEditMode = useCallback(
    (ev) => {
      ev.stopPropagation()
      setEditMode(true)
    },
    [setEditMode],
  )

  const onBlur = useCallback(
    (ev) => {
      setInputValue(value)
      setEditMode(false)
    },
    [value],
  )

  const readValueFormatter =
    typeof readFormatter === 'function' ? readFormatter : (v) => v
  return (
    <Box ref={wrapperRef}>
      <Read onClick={toggleEditMode} $active={editMode}>
        {readValueFormatter(value)}
      </Read>
      <Edit
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={onBlur}
        $active={editMode}
        {...rest}
      />
    </Box>
  )
}

export default InlineEditableInput
