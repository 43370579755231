import { useState, useEffect, useCallback } from 'react'

function useKeyPressed(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  const onKeyDown = useCallback(({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }, [targetKey]);

  const onKeyUp = useCallback(({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  }, [targetKey]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);
  return keyPressed;
}

export default useKeyPressed
