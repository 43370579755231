import { withStore } from '../../store'
import { openModal } from './overlays'
import { AddPartnerToSessionDialog } from './AddPartnerToSession'

export function showSetupSessionDialog ({ store, partnerIds }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <AddPartnerToSessionDialog partnerIds={partnerIds} close={close} />
      ),
    {
      autoDismissable: true
    }
  )
}
