import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore, withStore } from '../../store'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Button } from '../ui/button'
import { Box, H1, H2 } from '../ui/primitives'

import { NonInteractiveCard } from '../cards/non-interactive-card'

const SessionInvitationDialog = observer(({ close, session }) => {
  const store = useStore()

  session.partnerIds.forEach((id) => {
    store.requestFetchUserInfo(id)
  })

  useEffect(() => {
    return () => {
      store.stopRingtone()
    }
  })

  return (
    <Dialog
      bgColor="var(--ELK-White)"
      textAlign="center"
      minWidth="58rem"
      px="5.7rem"
      py="4.3rem"
    >
      <CloseButton
        color="var(--ELK-Black)"
        onClick={() => {
          store.declineSession(session)
          close()
        }}
      />

      <Box mb="4rem">
        <H1 color="var(--ELK-Black)" mb="2rem">
          You're invited to a session!
        </H1>
        <H2 color="var(--ELK-Black)">Get ready to play!</H2>
      </Box>

      <Box flex justifyContent="center" columnGap="2rem" mb="4rem">
        {session.partnerIds.map((id) => {
          return (
            <NonInteractiveCard
              key={id}
              user={store.users.get(id)}
            />
          )
        })}
      </Box>

      <Box flex justifyContent="center" columnGap="2rem">
        <Button
          primary
          onClick={() => {
            store.acceptSession(session)
            close()
          }}
          width="22rem"
        >
          Join now
        </Button>
        <Button
          danger
          onClick={() => {
            store.declineSession(session)
            close()
          }}
          width="22rem"
        >
          Nah
        </Button>
      </Box>
    </Dialog>
  )
})

export function showSessionInvitationDialog({ store, session, onClose }) {
  return openModal(
    ({ close }) => withStore(store, <SessionInvitationDialog session={session} close={close} />),
    {
      onClose,
    },
  )
}
