import React from 'react'
import { Box, H3 } from '../ui/primitives'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'
import {
  ActionItem,
  ActionsList,
  CardContent,
  CardList,
  IconWrapper,
  LiveSessionItem,
} from './card-components'
import { SessionEqualizer } from '../ui/icons'
import { PictureGrid } from '../pages/lobby-view/components/picture-grid'
import { logger } from '../../utils/logging'

export const LiveSessionCard = observer(({ actionsOpen }) => {
  const store = useStore()

  return (
    <>
      <CardContent>
        <PictureGrid partnerIds={store.session.partnerIds} />
        {actionsOpen && (
          <ActionsList>
            <ActionItem primary onClick={() => {
              store.leaveSession()
              logger.info('User invoked store.leaveSession()')
            }}>
              <H3>Leave Session</H3>
            </ActionItem>
          </ActionsList>
        )}
      </CardContent>
      <CardList>
        <LiveSessionItem actionsOpen={actionsOpen}>
          <Box />
          <H3>LIVE SESSION!</H3>
          <IconWrapper>
            <SessionEqualizer size={15}></SessionEqualizer>
          </IconWrapper>
        </LiveSessionItem>
      </CardList>
    </>
  )
})
