import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore, withStore } from '../../store'
import { Dialog } from './base'
import { openModal } from './overlays'
import { Box, H1, H2, Span } from '../ui/primitives'
import { Button } from '../ui/button'
import styled from 'styled-components'
import { SoftwareUpdateAnimation } from '../ui/software-update-animation'
import {
  UPDATE_STATE_AVAILABLE,
  UPDATE_STATE_ANOMALY,
  UPDATE_STATE_IN_PROGRESS,
  UPDATE_STATE_SUCCESS
} from '../../store/update-states'
import { showAskForRebootDialog } from './ask-for-reboot'
import { DESKTOP_HW_TYPES } from '../../store/board'

const StyledH2 = styled(H2)`
  font-weight: 400;
  margin-bottom: 5rem;
  margin-top: 1.5rem;
  line-height: 150%;
`

const UpdatesAvailableTextDesktop = ({ version }) => {
  return (
    <>
      <H1>Time for an Update!</H1>
      <StyledH2>
        You need to update Elk LIVE to v{version}.
      </StyledH2>
    </>
  )}

const UpdatesAvailableTextBridge = ({ updateState, version }) => {
  if (updateState === UPDATE_STATE_AVAILABLE) {
    return (
      <>
        <H1>Time for an Update!</H1>
        <StyledH2>
          We need to update the firmware of your Bridge to v{version}.
          <br />
          This update is mandatory and will only take a few minutes.
        </StyledH2>
      </>
    )
  }

  if (updateState === UPDATE_STATE_ANOMALY) {
    return <H1>Update Anomaly</H1>
  }

  if (updateState === UPDATE_STATE_IN_PROGRESS) {
    return (
      <>
        <H1>Update in Progress...</H1>
        <StyledH2>
          Updating Bridge Software.
          <br />
          <Span fontWeight="700">Do not unplug</Span> the device until the
          update is complete.
        </StyledH2>
      </>
    )
  }

  return (
    <>
      <H1>Update completed!</H1>
      <StyledH2 />
    </>
  )
}

const UpdatesAvailableButtons = ({
  store,
  device,
  updateState,
  startUpdate,
  close,
}) => {

  if(DESKTOP_HW_TYPES.includes(device.hwType)){

    if (updateState === UPDATE_STATE_SUCCESS) {
      return (
        <StyledH2>
         Download complete! <br />
          please close the app and drag Elk LIVE to applications folder.
          <br />
          Make sure to manually delete the previous app (Elk Live Desktop)
        </StyledH2>
      )
    }

    return (
      <Button success disabled={updateState === UPDATE_STATE_IN_PROGRESS} onClick={async () => {
        await store.downloadDesktopUpdate()
      }}>
        {updateState === UPDATE_STATE_IN_PROGRESS ? 'Downloading...' : 'Download now!'}
      </Button>
    )
  }

  if (updateState === UPDATE_STATE_AVAILABLE) {
    return (
      <Button success onClick={startUpdate}>
        Install now!
      </Button>
    )
  }

  if (updateState === UPDATE_STATE_ANOMALY) {
    return (
      <>
        <Button primary onClick={close}>
          Try again
        </Button>
        <Button primary onClick={close}>
          Close
        </Button>
      </>
    )
  }

  if (updateState === UPDATE_STATE_IN_PROGRESS) {
    return (
      <Button primary disabled>
        Updating...
      </Button>
    )
  }

  return (
    <Button primary onClick={() => {
      close()
      showAskForRebootDialog({ store, device })
    }}>
      Close
    </Button>
  )
}

const UpdatesAvailableDialog = observer(({ close, device }) => {

  const store = useStore()
  const bridgeVersion = store.serverStartupData?.requiredBridgeVersion
  const desktopVersion = store.serverStartupData?.requiredDesktopVersion
  const updateState = store.swUpdate.state
  const updateProgress = store.swUpdate.update_percent

  return (
    <Dialog
      px="0"
      textAlign="center"
      bgColor="var(--ELK-White)"
      color="var(--ELK-Black)"
    >
      <>
      {DESKTOP_HW_TYPES.includes(device.hwType)
        ? (
            <UpdatesAvailableTextDesktop version={desktopVersion} />
        )
        : (
            <UpdatesAvailableTextBridge updateState={updateState} version={bridgeVersion} />
      )}
        <Box mt="-14rem" mb="-8rem">
          <SoftwareUpdateAnimation
            progressPercentage={updateProgress}
            anomaly={updateState === UPDATE_STATE_ANOMALY}
          />
        </Box>
      </>

      <Box flex columnGap="1rem" justifyContent="center" alignItems="center">
        <UpdatesAvailableButtons
          store={store}
          device={device}
          updateState={updateState}
          close={close}
          startUpdate={() => store.startSoftwareUpdate()}
        />
      </Box>
    </Dialog>
  )
})

export function showUpdatesAvailableDialog({ store, device }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <UpdatesAvailableDialog device={device} close={() => {
          store.disconnectFromBoard()
          close()
        }} />,
      ),
    {
      autoDismissable: false,
      alwaysOnTop: true
    },
  )
}
