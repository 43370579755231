import styled from 'styled-components'
import { CloseIcon } from '../ui/icons'
import { Box, Text } from '../ui/primitives'
import Button from '../ui/button'

export const Dialog = styled(Box)`
  position: relative;
  background: ${props => props.bgColor ?? 'black'};
  padding: ${props => props.py ?? '2rem'} ${props => props.px ?? '2rem'};
  p { margin-bottom: 1em; }
`

export const CloseButton = styled(({ onClick, className }) => (
  <button type="button" className={className} onClick={onClick}>
    <CloseIcon size={30} />
  </button>
))`
  position: absolute;
  right: ${props => props.inset ?? '2rem'}; top: ${props => props.inset ?? '2rem'};
  padding: .5rem;
  color: ${props => props.color && props.color};
`

export const UnderlineButton = styled.button.attrs({type: 'button'})`
  position: relative;
  text-transform: uppercase;
  border-bottom: 1px solid currentColor;
  &:focus { outline: 1px dotted var(--color-light-grey); }
  &, &:after {
    transition: .2s ease;
  }
  &:hover:after {
    position: absolute;
    bottom: -2px; left: 0; right: 0;
    border-bottom: 1px solid white;
    content: '';
  }
`

export const TextArea = styled.textarea`
  color: var(--ELK-Black);
  resize: none;
  width: 100%;
  height: 26rem;
  padding: 1rem 1.2rem;
  border: 1px solid var(--ELK-Border);
  font-style: italic;
  font-size: 2.4rem;
`

export const ModalFooter = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled(Text).attrs({as: 'h1'})`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.075em;
  letter-spacing: .2;
`

export const ModalButton = styled(Button)`
  padding: 30px 20px;
  color: var(--ELK-White);
`