import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { SidebarMenuContainer, SidebarMenuItem } from '../styles'
import { showAdvancedConfigurationSettingsDialog } from '../../../legacy/dialogs/advanced-settings'
import { Arrow } from '../../../ui/icons'
import styled from 'styled-components'
import { PAGE_VIEWS } from '../index'

const MenuArrow = styled(Arrow)`
  transform: ${({ $expanded }) => $expanded ? 'rotate(90deg)' : 'none' };
`;

const SidebarMenu = observer(({ currentView, navigate }) => {
  const store = useStore()
  const [showProfileSettings, setShowProfileSettings] = useState(false)

  useEffect(() => {
    if(store.redirectTo !== null)
      navigate(store.redirectTo)
    store.redirectTo = null
    // eslint-disable-next-line
  }, [store.redirectTo])

  return (
    <SidebarMenuContainer>
      <SidebarMenuItem
        active={currentView === PAGE_VIEWS.FRIENDS}
        onClick={() => {
          store.refreshNonFriends()
          navigate(PAGE_VIEWS.FRIENDS)}
      }
      >
        people
      </SidebarMenuItem>
      {!store.subscriptionsEnabled ? (
        <SidebarMenuItem active={currentView === PAGE_VIEWS.PROFILE_SETTINGS}
                         onClick={() => navigate(PAGE_VIEWS.PROFILE_SETTINGS)}
                         id="profile-settings"
        >
          my profile
        </SidebarMenuItem>
      ) : (
        <>
          <SidebarMenuItem onClick={() => {
            setShowProfileSettings(!showProfileSettings)
          }}>
            profile & account
            <MenuArrow $expanded={showProfileSettings} />
          </SidebarMenuItem>
          <SidebarMenuItem submenu
                           active={currentView === PAGE_VIEWS.PROFILE_SETTINGS}
                           onClick={() => navigate(PAGE_VIEWS.PROFILE_SETTINGS)}
                           id="profile-settings"
          >
            my profile
          </SidebarMenuItem>
          {store.subscriptionsEnabled && (
            <SidebarMenuItem submenu
                             active={currentView === PAGE_VIEWS.SUBSCRIPTION}
                             onClick={() => navigate(PAGE_VIEWS.SUBSCRIPTION)}
            >
              subscription
            </SidebarMenuItem>
          )}
        </>
      )}

      {store.isDeveloperMode && (
        <SidebarMenuItem onClick={() => showAdvancedConfigurationSettingsDialog({ store })}>
          advanced settings
        </SidebarMenuItem>
      )}
      <SidebarMenuItem onClick={() => store.AWSLogout()}>
        logout
      </SidebarMenuItem>
    </SidebarMenuContainer>
  );
})

export default SidebarMenu
