import ReactDOM from 'react-dom'

const PORTAL_ROOT_ID = "__portals"
const ANCHOR_ID = "root"
function getPortalRoot() {
  let root = document.getElementById(PORTAL_ROOT_ID)
  if (!root) {
    root = document.createElement("div")
    root.id = PORTAL_ROOT_ID
    document.getElementById(ANCHOR_ID).insertAdjacentElement('afterend', root)
  }
  return root
}

function Portal({ children, container = getPortalRoot() }) {
  return ReactDOM.createPortal(children, container)
}

export default Portal;
