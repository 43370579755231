import styled, { keyframes } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'
import { H1 } from '../ui/primitives'
import { CloseIcon } from '../ui/icons'

const StyledH1 = styled(H1)`
  letter-spacing: -0.08rem;
  font-size: 3.6rem;
  width: 60rem;
`

const CloseButton = styled.button`
  position: absolute;
  top: 2.6rem;
  right: 2.6rem;
`

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
`
const slideOut = keyframes`
  from { transform: translateY(0%); opacity: 1; }
  to { transform: translateY(100%); opacity: 0; }
`
const SnackbarContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  margin: 0;
  width: 70rem;
`

const Snackbar = styled.div`
  display: flex;
  row-gap: 0.8rem;
  flex-direction: column;
  background: var(--color-blue);
  padding: 3.6rem;
  margin-top: 2px;
  font-size: 1.2rem;
  transition: all 200ms;
  animation: ${slideIn} 200ms forwards;
  &.error {
    background: var(--color-red);
  }
  &.warning {
    background: var(--color-yellow);
    color: var(--color-grey-1);
  }
  &.success {
    background: var(--color-green);
    color: var(--color-grey-1);
  }
  &.exiting {
    animation: ${slideOut} 200ms forwards;
  }
  a {
    text-decoration: underline;
  }
`

const Content = styled.div`
  font-weight: 700;
  font-size: 1.8rem;
  max-width: 55rem;
`

export const Snackbars = observer(() => {
  const store = useStore()
  return (
    <SnackbarContainer className={store.snackbars.map(s => s.variant ?? '').join(' ')}>
      {store.snackbars.map(({ id, heading, content, level, close, exiting, variant }) => (
        <Snackbar key={id} className={`${level} ${variant} ${exiting ? 'exiting' : ''}`.trim()}>
          <CloseButton onClick={() => close()}>
            <CloseIcon size={28} />
          </CloseButton>
          <StyledH1>{heading}</StyledH1>
          <Content>{content}</Content>
        </Snackbar>
      ))}
    </SnackbarContainer>
  )
})
