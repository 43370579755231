import { openModal } from '../overlays'
import { Box, Text } from '../../ui/primitives'
import { Dialog } from '../base'
import React, { useState } from 'react'
import { withStore } from '../../../store'
import { observer } from 'mobx-react-lite'
import {
  UserInfoSection,
  StyledTextArea,
  Title,
  ActionButton
} from '../PeopleProfile/styles'

const UserProfileModal = observer(({ store, user, close }) => {

  const [message,setMessage] = useState('')
  const [messageSent,setMessageSent] = useState(false)

  return (
    <Dialog width="300px" py="0" px="0">
            <UserInfoSection>
              <Title>{messageSent ? 'Message sent!' : 'Send message'}</Title>
              <Text py="5px" fontWeight="300">
                This message will be sent to your friend via email.
              </Text>
              {!messageSent && (
                <>
                  <StyledTextArea
                    onChange={(e) => {
                      setMessage(e.target.value)
                    }}
                    value={message}
                    maxLength={500}
                  ></StyledTextArea>
                  <Box textAlign="end" fontWeight="300">
                    <Text>{message.length}/500</Text>
                  </Box>
                  <ActionButton
                    disabled={!message} onClick={async () => {
                    await store.sendEmailToUser(user.id, message)
                    setMessageSent(true)
                  }}>Send Message
                  </ActionButton>
                </>
              )}
            </UserInfoSection>
    </Dialog>
  )
})

export function showSendMessageDialog({ store, user }) {
  return openModal(
    ({ close }) => withStore(store, <UserProfileModal close={close} store={store} user={user}  />),
    {
      autoDismissable: true,
      alwaysOnTop: true
    },
  )
}