import { observer } from 'mobx-react-lite'
import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useStore, withStore } from '../../store'
import { openModal } from './overlays'
import { CloseButton, Dialog } from './base'
import { Box, Center, H1, H2 } from '../ui/primitives'
import { ProfilePicture } from '../ui/profile-picture'
import { AddUserIcon, CloseIcon, SearchIcon, ShareIcon } from '../ui/icons'
import { IconButton } from '../ui/icon-button'
import { Button } from '../ui/button'
import { APIClient } from '../../api/client'
import { Row, RowText } from '../ui/row'
import { List } from '../ui/list'
import { Spinner } from '../ui/spinner'
import { ShareButtonModal } from '../pages/lobby-view/styles'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0;
  color: var(--ELK-Black);
`

const ButtonWrapper = styled.div`
  margin-left: auto;
`

const StyledH2 = styled(H2)`
  font-weight: normal;
  font-style: italic;
  margin-right: 1.2rem;
  white-space: nowrap;
`

const SearchWrapper = styled.div`
  position: relative;
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--ELK-Border);
  margin-right: 0.6rem;
  padding: 0 1.2rem;
`

const SearchField = styled.input`
  height: 100%;
  display: block;
  flex: 1;
  background: transparent;
  transition: 0.2s ease;
  font-size: 2.4rem;
  border: none;
  margin-left: 2rem;

  &::placeholder {
    color: var(--ELK-Placeholder);
    font-style: italic;
  }
`

const ScrollableList = styled(List)`
  max-height: 345px;
`

const EmptySearchResult = styled(Box)`
    margin: 0px 100px;
    text-align: center;
    height: 325px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 2.6rem;
`

const SearchResults = observer(({ searchResults, searchText, pending, searched, notFoundText, close }) => {

  const store = useStore()

  async function sendFriendRequest(friend_id) {
    // TODO: Temporary fix. Needs to be handled in a better way. Waiting for API updates.
    const keys = Object.keys(searchResults)
    const isEmail = searchText.includes('@')

    if (keys.length === 1 && isEmail) {
      await store.sendFriendRequest({ friendId: friend_id, lookUpString: searchText.toLowerCase() })
    } else {
      await store.sendFriendRequest({ friendId: friend_id })
    }

    APIClient.get_friends(store.currentUserId)
  }

  async function cancelFriendRequest(friend_user_id) {
    await APIClient.cancel_friend_request(store.currentUserId, friend_user_id)
    APIClient.get_friends(store.currentUserId)
  }

  if (!searched) {
    return <div />
  }

  if (pending) {
    return (
      <Box flex justifyContent="center" mt="5rem">
        <Spinner color="var(--ELK-Text-Inactive)" />
      </Box>
    )
  }

  if (searchResults.length === 0) {
    return (
      <EmptySearchResult flex justifyContent="center" mt="5rem" fontSize="3rem">
        Sorry, looks like <i><strong>{notFoundText}</strong></i> is not registered on Elk LIVE yet. What a great opportunity to send an invite!
        <ShareButtonModal
          id="share-button"
          active
          onClick={() => {
            close()
            store.showSocialShare = true
          }}
        >
          <ShareIcon />
          Invite to Elk LIVE!
        </ShareButtonModal>
      </EmptySearchResult>
    )
  }

  const getActionButtons = (status, id) => {
    if (status === 'Confirmed') {
      return (
        <Center>
          <StyledH2>Already Friends</StyledH2>
        </Center>
      )
    } else if (status === 'Requested') {
      return (
        <Center>
          <StyledH2>Request Pending</StyledH2>
          <IconButton danger onClick={() => cancelFriendRequest(id)}>
            <CloseIcon size="2.7rem"></CloseIcon>
          </IconButton>
        </Center>
      )
    } else if (status === 'Pending') {
      return (
        <Center>
          <StyledH2>Wants to be friends with you</StyledH2>
        </Center>
      )
    } else if (status === 'Denied') {
      return (
        <Center>
          <StyledH2>Has denied friendship</StyledH2>
        </Center>
      )
    } else {
      return (
        <IconButton primary onClick={() => sendFriendRequest(id)}>
          <AddUserIcon size="2.7rem"></AddUserIcon>
        </IconButton>
      )
    }
  }

  return (
      <ScrollableList>
        {searchResults &&
          searchResults.map((user) => {
            if (user.id === store.currentUserId) {
              return false
            }
            const alreadyFriend = store.friendsList.find(
              (friend) => friend.id === user.id,
            )
            const status = alreadyFriend ? alreadyFriend.status : null

            return (
              <Row key={user.id}>
                <Box minWidth="6.5rem" height="6.5rem">
                  <ProfilePicture user={user} />
                </Box>
                <RowText>{user.name}</RowText>
                <ButtonWrapper>
                  {getActionButtons(status, user.id)}
                </ButtonWrapper>
              </Row>
            )
          })}
      </ScrollableList>
  )
})

const AddFriendsDialog = observer(({ close }) => {
  const searchRef = useRef(null)
  const store = useStore()
  const [searchResults, setSearchResults] = useState([])
  const [searchText, setSearchText] = useState('')
  const [notFoundText, setNotFoundText] = useState('')
  const [pending, setPending] = useState(false)
  const [searched, setSearched] = useState(false)

  useEffect(() => {
    /* We use a timeout here since the transition gets cancelled when element gets focused */
    setTimeout(() => {
      if (searchRef.current) {
        searchRef.current.focus()
      }
    }, 300)
  })

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      searchForFriends()
    }
  }

  const onSearchChange = (value) => {
    setSearchText(value)
  }

  async function searchForFriends() {
    setPending(true)
    setSearched(true)
    let friends = {}
    try {
      friends = await APIClient.find_friends(searchText)
      setNotFoundText(searchText)
    } catch (err) {
      store.showSnackBar({
        heading: "Something went wrong when trying to search for friends",
        content: "We are experiencing server issues. Please contact support",
        level: "error"
      })
      return
    }
    const res = Object.keys(friends).map(async (id) => {
      const obj = await APIClient.get_userdata_by_id(parseInt(id))
      obj.id = parseInt(id)
      return obj
    })
    setSearchResults(await Promise.all(res))
    setPending(false)
  }

  return (
    <Dialog
      width="82.5rem"
      height="54rem"
      flex
      flexDirection="column"
      bgColor="var(--ELK-White)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)" />
      <H1 color="var(--ELK-Black)">Add Friends</H1>
      <ContentWrapper>
        <Center mb="2.5rem">
          <SearchWrapper>
            <SearchIcon size={30} />
            <SearchField
              ref={searchRef}
              placeholder="Search using email or name"
              type="email"
              onChange={(e) => onSearchChange(e.target.value)}
              onKeyDown={(e) => onKeyDown(e)}
            />
          </SearchWrapper>
          <Button primary onClick={() => searchForFriends()}>
            Search
          </Button>
        </Center>
        <SearchResults
          searched={searched}
          pending={pending}
          searchResults={searchResults}
          searchText={searchText}
          notFoundText={notFoundText}
          close={close}
        />
      </ContentWrapper>
    </Dialog>
  )
})

export function showAddFriendsDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <AddFriendsDialog close={close} />),
    {
      autoDismissable: true,
    },
  )
}
