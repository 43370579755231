import { observer } from 'mobx-react-lite'
import { CloseButton, Dialog } from './base'
import { useStore, withStore } from '../../store'
import styled from 'styled-components'
import { openModal } from './overlays'
import { Box, H1, H2 } from '../ui/primitives'
import { Button } from '../ui/button'
import { APIClient } from '../../api/client'

const StyledH2 = styled(H2)`
  font-weight: 400;
  margin-bottom: 5rem;
  margin-top: 1.5rem;
`

const RemoveFriendDialog = observer(({ close, friend }) => {

  const store = useStore()

  async function removeFriend() {
    await APIClient.remove_friend(store.currentUserId, friend.id)
    APIClient.get_friends(store.currentUserId)
  }

  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      textAlign="center"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)" />
      <H1 color="var(--ELK-Black)">Remove friend?</H1>
      <Box maxWidth="44rem">
        <StyledH2 color="var(--ELK-Black)">
          Are you sure you want to remove {friend.name}?
        </StyledH2>
      </Box>
      <Box>
        <Button primary onClick={() => close()} mr="0.8rem">No, Keep</Button>
        <Button danger onClick={() => {
          removeFriend()
          close()
        }} ml="0.8rem">Yes, Remove</Button>
      </Box>
    </Dialog>
  )
})

export function showRemoveFriendDialog({ store, friend }) {
  return openModal(
    ({ close }) => withStore(store, <RemoveFriendDialog close={close} friend={friend} />),
    {
      autoDismissable: true,
    },
  )
}
