import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import noImgSelected from '../../assets/no-img-selected.svg'
import { useStore } from '../../store'

const Picture = styled.div`
  background-image: url(${({image}) => image});
  background-position: center 25%;
  background-repeat: no-repeat;
  background-size: ${({cover}) => cover ? 'cover' : 'contain'};
  height: 100%;
  width: 100%;
  transition: 0.225s;
`

export const ProfilePicture = observer(({ user, cover = true }) => {
  const store = useStore()
  const friendIsActive = store.userActive.get(user?.id)
  return (
    <Picture
      cover={cover}
      className={friendIsActive ? "" : "disconnected"}
      image={user?.profile_image_url || noImgSelected}
    />
  )
})
