
import styled from 'styled-components'
import { Text } from '../../../ui/primitives'
import betaBanner from '../../../../assets/beta-banner-bg.jpg'

const Container = styled.div`
  margin: 1rem 0 2rem 0;
  width: 940px;
  background: url(${betaBanner});
  color: var(--ELK-White);
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat
`

const Overlay = styled.div`
  background: linear-gradient(89.89deg, rgba(28, 28, 28, 0.67) 4.49%, rgba(28, 28, 28, 0) 94.26%);
  width: 100%;
  padding: 0 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column
`

const BetaOnboarding = () => {
  return (
    <Container>
        <Overlay>
          <Text fontSize="40px" fontWeight="800" letterSpacing="-0.03em" lineHeight="4rem">This is Elk LIVE. Great to see you!</Text>
          <img src="https://elk-live-email-media-assets.s3.eu-north-1.amazonaws.com/mail-header.jpg" style={{display: 'none'}} alt="beta-banner"/>
          <Text fontSize="14px" fontWeight="800" px="5px">add some friends / make a soundcheck / start a live session!</Text>
        </Overlay>
    </Container>
)
}

export default BetaOnboarding
