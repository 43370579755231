import styled, { css, keyframes } from 'styled-components'
import { Box } from '../ui/primitives'
import Tooltip from '../ui/tooltip'

const FooterElement = styled.div`
  flex-shrink: 0;
  flex-basis: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--ELK-Black);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  column-gap: 1rem;
`

const LeftSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  flex-basis: 20%;
  column-gap: 1rem;
`

const CenterSection = styled.div`
  display: flex;
  column-gap: 1rem;
`

const RightSection = styled(Box)`
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
  flex-grow: 1;
  flex-basis: 20%;
`

const QuestionMarkButton = styled.button`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 100%;
  border: 2px solid var(--ELK-White);
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const SquareButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  justify-content: center;
  cursor: pointer;
  width: ${props => props.width ?? '5.2rem'};
  height: 5.2rem;
  flex-shrink: 0;
  &.off {
    background-color: var(--ELK-High-Prio-Red-Enabled);
    &:hover {
      background-color: var(--ELK-High-Prio-Red-Hover);
    }
  }
  &.on {
    background-color: var(--ELK-Sea-Foam-Green);
    color: var(--ELK-Black);
    &:hover {
      background-color: var(--ELK-High-Prio-Green-Hover);
    }
  }
  &.stop {
    background-color: var(--ELK-High-Prio-Red-Enabled);
    &:hover {
      background-color: var(--ELK-High-Prio-Red-Hover);
    }
  }
  &.disabled {
    background: var(--ELK-Footer-Disabled) !important;
    color: var(--ELK-Black) !important;
    cursor: default;
  }
`

const FooterButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 700;
  justify-content: center;
  cursor: pointer;
  width: ${props => props.width ?? '5.2rem'};
  height: 5.2rem;
  background: var(--ELK-Black);
  border: 2px solid var(--ELK-White);
  &:hover {
    border-color: var(--ELK-High-Prio-Green-Enabled);
    color: var(--ELK-High-Prio-Green-Enabled);
    path {
      stroke : var(--ELK-High-Prio-Green-Enabled);
    }
  }
  &.on {
    border-color: var(--ELK-High-Prio-Green-Enabled);
    background: var(--ELK-High-Prio-Green-Enabled);
    color: var(--ELK-Black);
    &:hover {
      background-color: var(--ELK-High-Prio-Green-Hover);
      border-color: var(--ELK-High-Prio-Green-Hover);
    }
  }
  &.disabled,:disabled {
    border-color: var(--ELK-Footer-Disabled) !important;
    color: var(--ELK-Footer-Disabled) !important;
    cursor: default;
    background: none !important;
    path {
      stroke :  var(--ELK-Footer-Disabled);
    }
  }
`

const DurationInfo = styled.div`
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 5.2rem;
  padding: 0 1.5rem;
  background: var(--ELK-List-Grey);
  color: var(--ELK-Black);
  font-weight: 700;
`

const Timer = styled.div`
  font-size: 20px;
  font-variant-numeric: tabular-nums;
`

const FooterTooltip = styled(Tooltip)`
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
  padding: 1rem 1.8rem;
`

const VideoTooltip = styled(FooterTooltip)`
  width: 19.4rem;
`

const NewSessionButton = styled.button`
  width: 100%;
  height: 5.2rem;
  background: var(--ELK-High-Prio-Yellow-Enabled);
  color: var(--ELK-Black);
  font-size: 2.0rem;
  font-weight: 700;
  &:hover {
    background: var(--ELK-High-Prio-Yellow-Hover);
  }
  &.disabled {
    background: var(--ELK-Footer-Disabled);
    cursor: default;
  }
`

const flashAnimation = keyframes`
 0% { opacity: 1; }
 100% { opacity: 0.3; }
`

const MuteAllButton = styled(FooterButton)`
  width: 12rem;
  height: 5.2rem;
  background: var(--ELK-Black);
  border: 2px solid var(--ELK-White);
  font-weight: 700;
  &.off:hover {
    border-color: var(--ELK-High-Prio-Red-Enabled);
    color: var(--ELK-High-Prio-Red-Enabled);
  }
  &.on {
    border: none;
    background-color: var(--ELK-High-Prio-Red-Enabled);
    color: var(--ELK-White);
    &:hover {
      background-color: var(--ELK-High-Prio-Red-Hover);
    }
  }
  &.flashing {
    animation-name: ${flashAnimation};
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  span:before {
    content: "mute all";
  }
  &.on span:before {
    content: "all muted";
  }
  &.on:hover span:before {
    content: "unmute";
  }
  &.disabled {
    border-color: var(--ELK-Footer-Disabled) !important;
    color: var(--ELK-Footer-Disabled) !important;
    cursor: default;
    background: none !important;
  }
`

const PeakMeterWrapper = styled(Box)`
  width: calc(73px - 1.5rem); // Should ideally be derived from theme or similar (Master VU width - footer padding)
  display: flex;
  column-gap: 3px;
  justify-content: flex-end;
`

const VideoSyncButton = styled(FooterButton)`
  &:hover {
    border-color: var(--ELK-Raspberry-Pink);
    color: ${({ $videoSyncActive }) =>
  $videoSyncActive ? 'var(--ELK-White)' : 'var(--ELK-Raspberry-Pink)'};
  }

  ${({ $videoSyncActive }) =>
  $videoSyncActive &&
  css`
      background-color: var(--ELK-Raspberry-Pink);
      border-color: var(--ELK-Raspberry-Pink);
      color: var(--ELK-White);
    `};
`

const HelpContainer = styled.div`
  display: flex;
  align-items: center;
`

const BorderlessFooterButton = styled(FooterButton)`
  display: flex;
  background-color: var(--ELK-High-Prio-Yellow-Enabled);
  color: var(--ELK-Black);
  width: 104px; 
  border: none;
  padding: 12px;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: var(--ELK-Black);
    cursor: pointer;
    path {
      stroke : initial;
    }
  }
`

export {
  CenterSection,
  RightSection,
  FooterElement,
  LeftSection,
  QuestionMarkButton,
  SquareButton,
  FooterButton,
  DurationInfo,
  Timer,
  FooterTooltip,
  VideoTooltip,
  NewSessionButton,
  flashAnimation,
  MuteAllButton,
  PeakMeterWrapper,
  VideoSyncButton,
  HelpContainer,
  BorderlessFooterButton
}
