import React from 'react'
import styled from 'styled-components'

import { useStore } from '../../../../store'

import BridgeInputWidgets from './default'
import DesktopInputWidgets from './desktop'

export const InputWidgetsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  flex-direction: column;
  flex-grow: 1;
  column-gap: 1.5rem;
  flex-basis: 50%;
  @media (max-height: 720px) {
    flex-direction: row;
  }
`

function InputWidgets(props) {
  const store = useStore()

  if (store.board?.isDesktopDevice) {
    return <DesktopInputWidgets {...props} />;
  }

  return <BridgeInputWidgets {...props} />
}

export default InputWidgets
