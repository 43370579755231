import { useStore } from '../../../../store'
import React, { useRef } from 'react'
import styled from 'styled-components' //'styled-components' -> /macro for debug purposes
import { observer } from 'mobx-react-lite'
import { Box, H2 } from '../../../ui/primitives'
import { PeakMeter } from './peak-meter'
import { SignalMeter } from './signal-meter'
import { Fader } from './fader'
import { KnobControl } from './knob-control'
import { ProfilePicture } from '../../../ui/profile-picture'
import { MuteButton } from './mute-button'
import { InputsIcon, DownArrowIcon } from '../../../ui/icons'
import { JitterDelayControls } from './jitter-delay-controls'
import { SessionStatus } from './session-status'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../../store/board-connection-states'
import { INPUT_OPTION_NONE } from '../../../../store/input-options.js'

const NoInputMessage = styled.div`
  font-size: 2rem;
  font-weight: 700;
`

const NoInputSubMessage = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
`

const InputsButton = styled.button`
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  font-weight: 600;
  margin-bottom: 2rem;
  font-size: 14px;
  color: ${(props) =>
    props.disabled ? 'var(--ELK-Dark-Grey)' : 'var(--ELK-White)'};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 15px 5px;
  align-items: center;
  padding: 0 20px;
  flex-grow: 1;
  max-height: 300px;
`

export const ChannelTitle = styled(H2)`
  white-space: nowrap;
  font-size: 20px;
  font-weight: normal;
  align-items: center;
`

export const SourceTitle = styled(H2)`
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  align-items: center;
`

const Name = styled(H2)`
  font-size: 18px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  padding: 14px 0;
`

const ProfilePictureWrapper = styled.div`
  height: 14vh;
  max-height: 300px;
  min-height: 100px;
  display: block;
  background: #363636;
  width: 100%;
  @media (max-height: 720px) {
    display: none;
  }
`

const SubTrack = observer(
  ({ trackId, stereo, channelIndex, disabled = true }) => {
    const store = useStore()

    const track = store.mixer.tracks[trackId]
    const peakMeters = store.mixer.peakMeters
    const peakMeterUpdatedAt = store.mixer.peakMeterUpdatedAt
    const signalIndicatorUpdatedAt = store.mixer.signalIndicatorUpdatedAt

    if(!track) return null

    return (
      <Box relative flex expanded column pt="4rem">
        <Grid>
          <Box
            flex
            row
            columnGap="6px"
            mx="auto"
            height="100%"
            minHeight="81px"
            maxHeight="225px"
          >
            {stereo ? (
              [0, 1].map((idx) => (
                <Box width="17px" height="100%">
                  <PeakMeter
                    getValue={() => peakMeters.get(`${trackId}/level_${idx}`)}
                    getUpdatedAt={() =>
                      peakMeterUpdatedAt.get(`${trackId}/level_${idx}`)
                    }
                    errorLimit={Infinity}
                    disabled={disabled}
                  />
                </Box>
              ))
            ) : (
              <Box width="17px" height="100%">
                <PeakMeter
                  getValue={() =>
                    peakMeters.get(`${trackId}/level_${channelIndex}`)
                  }
                  getUpdatedAt={() =>
                    peakMeterUpdatedAt.get(`${trackId}/level_${channelIndex}`)
                  }
                  errorLimit={Infinity}
                  disabled={disabled}
                />
              </Box>
            )}
          </Box>
          <Box height="100%" width="72px" minHeight="81px" maxHeight="225px">
            <Fader
              onChange={(value) => {
                if (stereo) {
                  track.volume[0] = value
                  track.volume[1] = value
                } else {
                  track.volume[channelIndex] = value
                }
              }}
              disabled={disabled}
              value={stereo ? track.volume[0] : track.volume[channelIndex]}
            />
          </Box>
          <Box width="17px" height="17px" mx="auto">
            <SignalMeter
              signalIndicatorUpdatedAt={
                stereo
                  ? () =>
                      Math.max(
                        signalIndicatorUpdatedAt.get(`${trackId}/level_0`),
                        signalIndicatorUpdatedAt.get(`${trackId}/level_1`),
                      )
                  : () =>
                      signalIndicatorUpdatedAt.get(
                        `${trackId}/level_${channelIndex}`,
                      )
              }
              inputClipIndicatorUpdatedAt={
                stereo
                  ? () =>
                      Math.max(
                        signalIndicatorUpdatedAt.get(`${trackId}/clip_0`),
                        signalIndicatorUpdatedAt.get(`${trackId}/clip_1`),
                      )
                  : () =>
                      signalIndicatorUpdatedAt.get(
                        `${trackId}/clip_${channelIndex}`,
                      )
              }
              height={17}
              width={17}
              disabled={disabled}
            />
          </Box>
          <Box mx="auto">
            <MuteButton
              muted={stereo ? track.muted[0] : track.muted[channelIndex]}
              onChange={(value) => {
                if (stereo) {
                  track.muted[0] = value
                  track.muted[1] = value
                } else {
                  track.muted[channelIndex] = value
                }
              }}
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Box mt="15px" mb="15px" alignItems="center">
          <KnobControl
            defaultValue={0.5}
            panMode={true}
            stereoMode={stereo}
            titleMin="L"
            titleMax="R"
            onChanging={(value) => {
              if (stereo) {
                if (value <= 0.5) {
                  track.pan[0] = 0
                  track.pan[1] = 2 * value
                } else {
                  track.pan[0] = 2 * (value - 0.5)
                  track.pan[1] = 1
                }
              } else {
                track.pan[channelIndex] = value
              }
            }}
            value={
              stereo
                ? (track.pan[0] + track.pan[1]) / 2
                : track.pan[channelIndex]
            }
            disabled={disabled}
          />
        </Box>
      </Box>
    )
  },
)

export const TrackControl = observer(
  ({ label, trackId, userId, disabled = true }) => {
    const store = useStore()
    const buttonRef = useRef(null)
    const user = store.users.get(userId)
    const userName = user?.name
    const stereoLinked = store.mixer.tracks[trackId]?.stereoLinked
    const inputOptions = userId === store.currentUserId ? store.mixer.inputOptions : store.mixer.tracks[trackId]?.inputs

    return (
      <Box
        bgColor="var(--ELK-Black)"
        flex
        column
        alignItems="center"
      >
        {stereoLinked ? (
          <SubTrack
            trackId={trackId}
            label={label}
            stereo={true}
            disabled={disabled}
          />
        ) : (
          <Box flex expanded>
            {inputOptions?.[0] === INPUT_OPTION_NONE ? null : (
              <SubTrack
                trackId={trackId}
                label={label}
                stereo={false}
                channelIndex={0}
                disabled={disabled}
              />
            )}
            {inputOptions?.[1] === INPUT_OPTION_NONE ? null : (
              <SubTrack
                trackId={trackId}
                label={label}
                stereo={false}
                channelIndex={1}
                disabled={disabled}
              />
            )}
            {inputOptions?.[0] === INPUT_OPTION_NONE && inputOptions?.[1] === INPUT_OPTION_NONE && (
              <Box flex column textAlign="center" width="180px">
                <Box flex grow="1" alignItems="center" justifyContent="center">
                  <NoInputMessage>no inputs<br/> selected</NoInputMessage>
                </Box>
                {userId === store.currentUserId && (
                  <Box flex column alignItems="center">
                    <NoInputSubMessage>Choose an input<br/> to stay in the game!</NoInputSubMessage>
                    <Box mt="20px" mb="20px" color="var(--ELK-High-Prio-Yellow-Enabled)">
                      <DownArrowIcon />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
        {user?.id === store.currentUserId ? (
          <InputsButton
            disabled={
              store.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED
            }
            ref={buttonRef}
            onClick={() => {
              store.showBridgeSettingsView = !store.showBridgeSettingsView
            }}
          >
            <InputsIcon />
          </InputsButton>
        ) : null}
        {store?.settings?.ui?.showJitterDelaySettings &&
        userId !== store.currentUserId ? (
          <Box mb="2rem">
            <JitterDelayControls userId={userId} />
          </Box>
        ) : null}
        {userId !== store.currentUserId ? (
          <Box height="60px" width="100%">
            <SessionStatus shorten={true} userId={userId} />
          </Box>
        ) : null}
        <ProfilePictureWrapper>
          <ProfilePicture user={user} cover={false} />
        </ProfilePictureWrapper>
        <Box
          bgColor="var(--ELK-Black)"
          width="100%"
          textAlign="center"
          justifyContent="center"
          flex
        >
          <Name width="150px">{userName || <>&nbsp;</>}{userId === store.currentUserId && (<>&nbsp;(you)</>)}</Name>
        </Box>
      </Box>
    )
  },
)
