import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { CardContainer } from './card-components'
import { FriendCard } from './friend-card'
import { LiveSessionCard } from './live-session-card'
import { OwnUserCard } from './own-user-card'
import { NonFriendCard } from './non-friend-card'

export const Card = observer(({ user, type }) => {
  const [actionsOpen, setActionsOpen] = useState(false)

  return (
    <CardContainer
      onMouseEnter={() => setActionsOpen(true)}
      onMouseLeave={() => setActionsOpen(false)}
    >
      {type === 'ownUser' && (
        <OwnUserCard user={user} actionsOpen={actionsOpen} />
      )}

      {type === 'friend' && (
        <FriendCard user={user} actionsOpen={actionsOpen} handleToggleActions={(isOpen) => setActionsOpen(isOpen)}/>
      )}

      {type === 'non-friend' && (
        <NonFriendCard user={user} actionsOpen={actionsOpen} handleToggleActions={(isOpen) => setActionsOpen(isOpen)}/>
      )}

      {type === 'liveSession' && (
        <LiveSessionCard actionsOpen={actionsOpen} />
      )}
    </CardContainer>
  )
})
