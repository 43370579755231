import { version, aloha_server_url, aloha_server_port, aloha_server_secure } from '../../package.json'
import UAParser from 'ua-parser-js'

/*
 * Gets Cloud Server URL by stripping `app.` part from the hostname
 * For non-production environments gets `aloha_server_url` value from `package.json`
 */
export function getCloudServerUrl() {
  // environment variables take priority for backend customisation
  if (process.env.REACT_APP_SERVER_URL) {

    return process.env.REACT_APP_SERVER_URL
  }

  if (process.env.NODE_ENV === 'production') {
    return window.location.hostname.replace(/^app\./, '')
  }

  return aloha_server_url
}

export function getCloudServerPort() {
  // environment variables take priority for backend customisation
  if (process.env.REACT_APP_SERVER_PORT) {
    return process.env.REACT_APP_SERVER_PORT
  }

  if (process.env.NODE_ENV === 'production') {
    return ''
  }

  return aloha_server_port
}

export function getCloudServerSecure() {
  // environment variables take priority for backend customisation
  if (process.env.REACT_APP_SERVER_SECURE) {
    return process.env.REACT_APP_SERVER_SECURE === 'true'
  }

  if (process.env.NODE_ENV === 'production') {
    return true
  }

  return aloha_server_secure
}

export function getCognitoRegion() {
  if (process.env.REACT_APP_COGNITO_REGION) {
    return process.env.REACT_APP_COGNITO_REGION
  } else {
    return  "eu-central-1"; // elk-live-dev
  }
}

export function getCognitoUserPoolID() {
  if (process.env.REACT_APP_COGNITO_USER_POOL_ID) {
    return process.env.REACT_APP_COGNITO_USER_POOL_ID
  } else {
    return  "eu-central-1_zSNzsk0B3"; // elk-live-dev
  }
}

export function getCognitoAppClientID() {
  if (process.env.REACT_APP_COGNITO_CLIENT_ID) {
    return process.env.REACT_APP_COGNITO_CLIENT_ID
  } else {
    return  "oorusbhgguvfbrqkdjbkn7fqg"; // elk-live-dev
  }
}

export function getMixpanelTokenId(){
  return process.env.REACT_APP_MIXPANEL_TOKEN || ''
}

export function getRudderstackKey(){
  return process.env.REACT_APP_RUDDERSTACK_KEY || ''
}

export function getRudderstackUrl(){
  return process.env.REACT_APP_RUDDERSTACK_PLANE_URL || ''
}

export function getElkLiveWebsiteUrl(){
  return process.env.REACT_APP_ELK_LIVE_WEBSITE_URL || 'https://elklive.webflow.io/'
}

export function getCognitoDomain() {
  if (process.env.REACT_APP_COGNITO_DOMAIN) {
    return process.env.REACT_APP_COGNITO_DOMAIN
  } else {
    return  "elk-live-dev.auth.eu-central-1.amazoncognito.com"; // elk-live-dev
  }
}

export function getGoogleAuthClientId() {
  if(process.env.REACt_APP_GOOGLE_AUTH_CLIENT_ID){
    return process.env.REACt_APP_GOOGLE_AUTH_CLIENT_ID
  }
  return '874331978242-s1c9ad8152ie3lv1j2sjt0kc4p6jnhij.apps.googleusercontent.com' //client for elk-live-dev
}

export function getWebsiteUrl() {
  // environment variables take priority for backend customisation
  if (process.env.REACT_APP_WEBSITE_URL) {

    return process.env.REACT_APP_WEBSITE_URL
  }

  if (process.env.NODE_ENV === 'production') {
    return window.location.hostname.replace(/^app\./, '')
  }

  return "https://elk.local"
}

export function getCurrentUrlOrigin(){
  const { origin } = new URL(window.location.href)
  return origin
}

export function getRedirectSignIn(){
  const urlOrigin = getCurrentUrlOrigin()
  if(isBetaRegistrationRoutes())
    return `${urlOrigin}/download`

  return urlOrigin
}

export function getSubscriptionsEnabled() {

  if (process.env.REACT_APP_SUBSCRIPTIONS_ENABLED) {
    return process.env.REACT_APP_SUBSCRIPTIONS_ENABLED
  }

  return true
}

export function isSafari() {
  return navigator.userAgent.match(/safari/i) &&
  !navigator.userAgent.match(/chrome|chromium|crios/i)
}

export function isDesktop() {
  return process.env.REACT_APP_BRIDGE_MODE === 'desktop'
}

export const isRunningAndroid = () => {
  return navigator.userAgent.toLowerCase().includes('android')
}

export const isBetaMode = () => {
  return process.env.REACT_APP_PRODUCT_MODE === 'beta'
}

export const isRunningiOS = () => {
  return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
export function getDevicesApiUrl() {
  if (process.env.REACT_APP_DEVICES_API_URL) {
    return process.env.REACT_APP_DEVICES_API_URL
  }

  return 'devices.elk.live'
}

export function getCurrentVersion(){
  const env = (process.env.REACT_APP_SERVER_URL || aloha_server_url).split('.')[0]
  const versionToDisplay = `${version}-${process.env.REACT_APP_GIT_COMMIT || 'dev'}-${env}`

  return { version: versionToDisplay, env }
}

export function isBetaRegistrationRoutes(){
  const betaRoutes = ['/join','/download', 'privacy']
  return betaRoutes.find((route) => window.location.href.includes(route))
}

export function downloadFileFromBlob(blob) {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', 'Elk LIVE.dmg');
      const href = URL.createObjectURL(blob);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
}

export function getPlatformData() {
  const parser = new UAParser(navigator.userAgent)
  const { browser, device, os } = parser.getResult();

  return {
    platform: { browser, device, os },
    version: getCurrentVersion().version,
    env: getCurrentVersion().env
  }
}

export const promiseWithTimeout = (promise, timeout = 10000) => {
  let timeoutId;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'))
    }, timeout);
  })
  return {
    promiseOrTimeout: Promise.race([promise, timeoutPromise]),
    timeoutId,
  };
};

export const closeAllModals = () => {
  const modals = document.getElementsByClassName('backdrop')
  Array.from(modals).forEach((element) => {
    element.remove()
  })
}

export const NDLToJson = (ndl) => {
  const lines = ndl.split('\n')

  const fixedLines = lines.map(line => {
    if(!line.includes('{')) {
      return JSON.stringify({message: line.toString(), level: 'error'})
    }
    return line
  })

  return JSON.parse(`[${fixedLines.join(',')}]`)
}

export const logsToJson = (logs) => {
  const lines = logs.split('\n')

  const fixedLines = lines.map(line => {
      let level = 'info'
      if(line.includes('INFO')) level = 'info'
      if(line.includes('DEBUG')) level = 'debug'
      if(line.includes('WARNING')) level = 'warning'
      if(line.includes('ERROR')) level = 'error'

      return JSON.stringify({message: line.toString(), level})
  })

  return JSON.parse(`[${fixedLines.join(',')}]`)
}