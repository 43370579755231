import { isDesktop } from '../utils/utils'

const isElectronAPIAvailable = () => Boolean(window.electronAPI)

const getOsHostname = async () => {
  if (!isElectronAPIAvailable() && !isDesktop()) return
  return await window.electronAPI.getOSHostname()
}

const isWirelessConnection = async () => {
  if (!isElectronAPIAvailable()) return false
  return await window.electronAPI.isWirelessConnection()
}

const getDesktopVersion = async () => {
  if (!isElectronAPIAvailable()) return

  return window.electronAPI.getVersion ? await window.electronAPI.getVersion() : null
}

const getElectronLogs = async () => {
  if (!isElectronAPIAvailable()) return

  return window.electronAPI.getLogs ? await window.electronAPI.getLogs() : null
}

const relaunchApp = () => {
  window.electronAPI.relaunchApp()
}

const setConnectionStatusForElectron = (status) => {
  if(!isElectronAPIAvailable()) return
  if(!window.electronAPI.setConnectionStatus) return

  window.electronAPI.setConnectionStatus(status)
}

export {
  getOsHostname, isElectronAPIAvailable, isWirelessConnection, getDesktopVersion, relaunchApp, getElectronLogs, setConnectionStatusForElectron
}
