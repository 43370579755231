/**
 * Aloha Client library.
 * Communicates over JSON-RPC to:
 *   - a backend on the embedded board
 *   - a cloud backend for session management
 *
 * It is mostly a thin wrapper around those backends that report the events
 * back to the outer client (GUI), where most of the logic to parse
 * the received events and chain a sequence of calls should be done.
 *
 * Copyright 2020 Modern Ancient Instruments Networked AB, dba Elk
 */
import pako from 'pako'
import { RPCClient } from './rpc-client'
import { SocketIOClient } from './socketio-client'
import { BoardWebsocketClient } from './board-websocket-client'
import {
  INPUT_OPTION_GUITAR,
  INPUT_OPTION_LINE,
  INPUT_OPTION_MIC,
  INPUT_OPTION_NONE,
  INPUT_OPTION_USB,
} from '../store/input-options'

import { APIError } from '../errors'
import { isDesktop } from '../utils/utils'
import { DESKTOP_IP } from '../store/board'
import { ElkLogger, logger } from '../utils/logging'
import { getElectronLogs } from '../store/electronAPI'

const CLOUD_DEBUG_MODE = process.env.REACT_APP_CLOUD_DEBUG_MODE === 'true' || false // If set to true, mocks up a board connection so that the cloud API can be tested without having the full setup running.

export const DEFAULT_BOARD_BACKEND_PORT = 41041
export const DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT = 41042

const DEFAULT_OSC_PORT = 34034

const translateInputOption = (value) => {
  switch (value) {
    case INPUT_OPTION_NONE:
      return ['none', '']
    case INPUT_OPTION_MIC:
      return ['front_input', 'mic']
    case INPUT_OPTION_LINE:
      return ['front_input', 'line']
    case INPUT_OPTION_GUITAR:
      return ['front_input', 'guitar']
    case INPUT_OPTION_USB:
      return ['usb', '']
    default:
      break;
  }
  throw new Error(`Unknown input mode ${value}`)
}

// Used in cloud-debug mode when it's not possible to query the real board
const DEFAULT_BOARD_CONFIGURATION = {
  base_recv_ports: [35000, 35002],
  external_ip: '127.0.0.1',
  board_system: 'raspberry-pi-4',
  board_audio_hat: 'hifi-berry-pro',
  audio_buffer_size: 32,
  audio_sampling_rate: 48000,
  elk_version: '0.8.0',
  aloha_version: '9.9.9',
}

/*
 * Utility functions
 */

const convertBase64ToBlob = (base64Image) => {
  const parts = base64Image.split(';base64,')
  const contentType = parts[0].split(':')[1]
  // Decode Base64 string
  const decodedData = atob(parts[1])
  const uInt8Array = new Uint8Array(decodedData.length)
  for (let i = 0; i < decodedData.length; i++) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

const postImageToS3 = async (imageBlob, presignedPostData) => {
  const formData = new FormData()
  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.append(key, presignedPostData.fields[key])
  })
  // Actual file has to be appended last
  const fileName = presignedPostData.fields['key']
  formData.append('file', imageBlob, fileName)
  const response = await fetch(presignedPostData.url, {
    method: 'POST',
    body: formData,
  })
  return response
}

const postLogsToS3 = async (logsObject, presignedPostData) => {
  const formData = new FormData()
  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.append(key, presignedPostData.fields[key])
  })

  try {
    const blob = new Blob([JSON.stringify(logsObject)], { type: 'text/plain' });
    const fileName = presignedPostData.fields['key']
    formData.append('file', blob, fileName)
    return await fetch(presignedPostData.url, {
      method: 'POST',
      body: formData,
    })

  } catch (err){
    //TODO: Error handling
    logger.error(err)
  }
}

/**
 * Convert local LAN address (e.g. 192.168.0.17)
 * to URLs in the form used by custom DNS.
 *
 * @param {String} board_ip
 * @returns {String}
 */
export function board_ip_to_url(board_ip) {
  return board_ip === DESKTOP_IP ?
    board_ip :
    `ip-${board_ip.replace(/\./g, '-')}.local.elkpowered.com`
}

/*
 * APIClient
 */
export const APIClient = {

  _board_rpc_client: null,
  _board_socket_client: null,

  // Will be available dependening on hw type
  _desktop_rpc_client: null,

  _cloud_rpc_client: null,
  _cloud_socket_client: null,

  _devices_rpc_client: null,

  _cloud_debug_mode: CLOUD_DEBUG_MODE,
  _ip_data: null,
  _location_data: null,
  _pending_profile_image_upload: null,
  _pending_image_upload_callbacks: [],
  _user_id: null,
  _users_map: {},

  _sender_buffer_size: 1,

  _default_board_backend_port: DEFAULT_BOARD_BACKEND_PORT,

  // events
  on_friends_list_change: null,
  on_cloud_event: null,
  on_users_available_list_change: null,
  on_users_active_list_change: null,

  on_mixer_message: null,
  on_board_notification: null,
  on_statistics_message: null,

  _notify_friends_list_change(friends_list) {
    this.on_friends_list_change?.call(this, {
      friends_list
    })
  },

  _notify_users_available_list_change(users_available_list) {
    this.on_users_available_list_change?.call(this, {
      users_available_list
    })
  },

  _notify_users_active_list_change(users_active_list) {
    this.on_users_active_list_change?.call(this, {
      users_active_list
    })
  },

  init_cloud_rpc_client(url, port = null, secure = true, socketio_client = null) {
    // pass on reference to socketio_client, so that access token can be refreshed when necessary
    this._cloud_rpc_client = RPCClient(
      url,
      port,
      secure,
      socketio_client,
    )
  },

  init_devices_rpc_client(url, port = null, secure = true) {
    // pass on reference to socketio_client, so that access token can be refreshed when necessary
    this._devices_rpc_client = RPCClient(
      url,
      port,
      secure
    )
  },

  init_cloud_socket_client(url, port = null, secure = true) {
    this._cloud_socket_client = new SocketIOClient(
      url,
      port,
      secure,
      (event) => {
        this.on_cloud_event?.call(this, event)
      },
    )
  },
  /**
   * Query the server for global information like latest available app version etc.
   */
  async get_server_startup_data() {
    const { data } = await this._cloud_rpc_client.invoke('get_server_startup_data')
    return await JSON.parse(data)
  },

  get_location_data(user_id) {
    this._cloud_socket_client.invoke('request_location', { user_id })
  },

  async verify_cognito_tokens(cognito_user_session) {
    const idTokenObj = cognito_user_session.getIdToken()

    // get the user id in session db [creates if it doesn't exist but is verified and stored via
    // post cognito confirmation lambda
    const idVerifyResponse = await this._cloud_rpc_client.invoke('verify_id_token', {
      id_token: idTokenObj
    })

    const getMultiplier = (unit) => {
      if (unit === 'seconds') return 1
      if (unit === 'minutes') return 60
      if (unit === 'hours') return 60 * 60
      if (unit === 'days') return 60 * 60 * 24
      throw new Error('Cloud sent invalid response for verify_id_token')
    }

    const accessObj = cognito_user_session.getAccessToken()
    const access_token = accessObj.jwtToken

    const result = {
      id: idVerifyResponse['id'],
      nickname: idTokenObj.payload['custom:displayname'],
      email: idTokenObj.payload['email'],
      access_token: access_token,
    }

    if (idVerifyResponse.refresh_token_validity) {
      result.refresh_token_validity_in_seconds = idVerifyResponse.refresh_token_validity * getMultiplier(idVerifyResponse.refresh_token_validity_units)
    }

    return result
  },

  /**
   * Sends the registration request to the backend.
   * User will receive an email containing a 'confirmation' link which needs to be opened (and the user to login)
   *
   * @returns {string|boolean} Return the response from the server as an error string or `true`
   */
  async register_user_with_backend(params) {
    const response = await this._cloud_rpc_client.invoke('register', params)

    if (!response.access_token) {
      // no access token - probably an error but that should raise an exception instead
      return 'No access_token returned'
    }

    // no need to save access_token (yet) as the user will not have confirmed their account
    return true
  },

  async request_password_reset({ email }) {
    return this._cloud_rpc_client.invoke('request_password_reset', { email })
  },

  async reset_password({ token, password, password2 }) {
    return this._cloud_rpc_client.invoke('password_reset', {
      token,
      password,
      password2,
    })
  },

  /**
   * Register the user as active on the cloud server.
   *
   * @param {string} username Unique nickname for pseudo-login
   * @param {string} email Email address
   * @param {string} advertised_ip_override if not `null`, advertise this IP to the server instead than the one got from ipinfo
   * @returns {number} Unique user id returned by the server
   */
  async register_active_user(username, email, advertised_ip_override = null) {
    const board_cfg = await this.get_board_configuration()

    if (!this._ip_data && !(await this._fetch_public_ip_data())) {
      throw new APIError("Couldn't fetch ip address.")
    }

    const res = await this._cloud_rpc_client.invoke(
      'register_active_user',
      {
        email,
        userdata: {
          name: username,
          last_ip_address: advertised_ip_override || board_cfg.external_ip || '',
          last_aloha_version: board_cfg.aloha_version,
          location: `${this._ipdata.country}:${this._ipdata.city}`,
          base_recv_ports: JSON.stringify(board_cfg.base_recv_ports),
        },
      },
    )

    this._user_id = res.user_id

    const users_response = await this._cloud_rpc_client.invoke(
      'get_active_users',
      {},
    )

    if (users_response !== undefined) {
      await this._update_users(users_response.active_users_list_updated)
    } else {
      logger.warn('users_response was undefined for `get_active_users`')
    }

    return this._user_id
  },

  async get_active_users(){
    const users_response = await this._cloud_rpc_client.invoke(
      'get_active_users',
      {},
    )

    this._notify_users_active_list_change(users_response.active_users_list_updated)
    await this._update_users(users_response.active_users_list_updated)
  },

  async unregister_active_user(user_id) {
    if (typeof user_id === 'undefined') {
      throw new Error("Need to specify user_id")
    }
    logger.info('unregister_active_user invoked deactivate')
    await this._cloud_rpc_client.invoke('deactivate', {
      user_id
    })
  },

  async logout_board() {
    if (!this._cloud_debug_mode && this._board_rpc_client) {
      await this._board_rpc_client.invoke('logout', {})
    }
  },

  async logout(user_id) {
    if (typeof user_id === 'undefined') {
      throw new Error("Need to specify user_id")
    }
    this._cloud_rpc_client.invoke('logout', {
      user_id
    })
    this._user_id = null
  },

  /**
   * Ask the server to initiate a session with another client.
   *
   * This will only send a request to the partner client,
   * you should wait for a 'session_ready_notification' event for knowing
   * when actually to start the internal session with start_internal_session().
   *
   * @param {number[]} partner_ids Partners' user ids
   * @returns {number} Session ticket generated by the server
   */
  async create_session(user_id, partner_ids, session_payload) {
    try {

      const server_response = await this._cloud_rpc_client.invoke(
        'create_session',
        {
          user_id,
          other_user_ids: partner_ids,
          session_payload,
        },
      )
      return server_response.session_id
    } catch (error) {
      logger.exception('Error', error)

      if (error.data?.type === 'UserAlreadyInSessionException') {
        throw new Error('Partner already in a session')
      }
    }
  },

  /**
   * Reply to a session request by another user.
   *
   * @param {boolean} answer `true` if we are accepting the request, `false` otherwise.
   * @returns {boolean} `true` if the server replied correctly
   */
  async reply_to_session_request(user_id, session_id, answer) {
    if (typeof user_id === 'undefined') {
      throw new Error('User id is null')
    }
    if (typeof answer === 'undefined') {
      throw new Error("Need to specify answer")
    }
    if (typeof session_id === 'undefined') {
      throw new Error("Need to specify session_id")
    }
    this._cloud_socket_client.invoke('reply_to_session_request', {
      user_id,
      session_id,
      answer,
    })
    if (answer === false) {
      this._board_socket_client?.send_message('session_update', {
        type: 'session_request_denied'
      })
    }
    return true
  },

  /**
   * Ask the server to explicitly close a running session.
   */
  async leave_session(user_id, trigger_type, session_id) {

    // TODO: collect proper values instead of empty strings for analytics
    const payload = {
      user_id,
      network_status: '',
      performance_analytics: '',
      logs: '',
      session_id,
      trigger_type
    }

    this._cloud_socket_client.invoke('leave_session', payload)
    await this._stop_internal_session()
  },

  /**
   * Add a user to an already existing session.
   *
   * This will only send a request to the partner client,
   * you should wait for a 'add_user_to_session_notification' event for knowing
   * when actually to start the internal session with start_internal_session().
   *
   * @param {number} user_id user id of user adding partner to the session
   * @param {number} partner_id partner user id
   * @param {number} session_id
   * @returns {number} Session ticket generated by the server
   */
  async add_user_to_session(user_id, partner_id, session_id) {
    try {

      const server_response = await this._cloud_rpc_client.invoke(
        'add_user_to_session',
        {
          user_id,
          add_user_id: partner_id,
          session_id,
        },
      )

      return server_response.session_id
    } catch (error) {
      logger.exception('Error', error)

      if (error.data?.type === 'UnauthorizedError') {
        throw new Error('UnauthorizedError')
      }

      if (error.data?.type === 'SessionMaxUsersException') {
        throw new Error('Maximum partners reached')
      }
    }
  },

  /**
   * Send a (global) message to be displayed in the chat window of every active user.
   *
   * @param {string} message Message to send
   * @param {bool} session_private is the session intended for the Public chat room or the private session chat room
   */
  post_message_to_chat_group(user_id, message, session_private = false) {
    this._cloud_socket_client.invoke('post_message_to_chat_group', {
      user_id,
      message,
      session_private,
    })
  },

  /**
   * Sends a message to other users.
   *
   * @param {number[]} user_ids list of user ids to send message to
   * @param {object} message a JSON blob
   */
  post_message_to_users(user_ids, message) {
    this._cloud_rpc_client.invoke('post_message_to_users', {
      user_ids: user_ids,
      message,
    })
  },

  request_join_video_chat(region, session_id) {
    this._cloud_socket_client.invoke('request_join_video_chat', {
      region,
      session_id,
    })
  },

  /**
   * Joins Chime video chat returning the meeting data needed to connect.
   * Throws `UserNotInSessionException` error when user is not in a session.
   *
   * @param {string} region valid aws chime media region id (e.g. 'eu-north-1')
   *
   * @returns {Object} Returns meetingData and attendeeData required to connect to chime meeting
   */
  async join_video_chat(region) {
    return await this._cloud_rpc_client.invoke('join_video_chat', { region })
  },

  /**
   * ------------------
   * Board API wrappers
   * ------------------
   */

  /**
   * Try to connect to the JSON-RPC server on the board doing the following:
   *  1. use the default Avahi/zeroconf address
   *  2. If zeroconf fails, try to listen for the UDP broadcast message sent by the board
   *
   * If the connection is successful,
   * queries the board for configuration and put it into network test mode.
   *
   * @param {string} manual_ipaddr_override If specified, use this IP address to try a connection
   * @param {number} manual_port_override If specified, tell the board to use this as base RTP port
   * @param {number} bypass_port_fwd_test if `true`, always pass the network port-forwarding test
   * @param {boolean} secure if `true`, connect using ssl
   */
  async connect_to_board(
    manual_ipaddr_override,
    hw_init,
    manual_port_override = null,
    bypass_port_fwd_test = false,
    secure = true
  ) {
    if (this._cloud_debug_mode) {
      this._init_board_socket_client('127.0.0.1')
      return true
    }

    let board_ip

    if (!manual_ipaddr_override) {
      throw new Error('No Aloha devices available')
    } else {
      board_ip = manual_ipaddr_override
    }

    if (!board_ip) {
      return false
    }

    if(board_ip !== DESKTOP_IP){
      logger.info('Connecting to non-desktop')
      this._desktop_rpc_client = null
    }

    const boardClient = RPCClient(
      board_ip_to_url(board_ip),
      DEFAULT_BOARD_BACKEND_PORT,
      secure,
    )

    hw_init.input_types = ['', '']
    hw_init.input_modes = ['', '']
    for (const channelIndex of [0, 1]) {
      const [type, mode] = translateInputOption(hw_init.input_options[channelIndex])
       hw_init.input_types[channelIndex] = type
       hw_init.input_modes[channelIndex] = mode
    }

    const result = await boardClient.invoke('client_connected_ack', {
      manual_port_override,
      bypass_port_fwd_test,
      hw_init: hw_init,
    })

    if (!result) {
      throw new APIError("Unable to connect to board.")
    }

    if (result.AlohaConnectionToken) {
      boardClient.set_board_connection_token(result.AlohaConnectionToken)
    }

    /* Implement this when driver API is in place. We need to handle cases
      like when the user have removed a microphone but the hw_init still
      has phantom_power set to true but this cant be set by the board which
      will cause settings_loaded to be false and the new settings need to be
      fetched via get_driver_state.
    if (result.settings_loaded === false)
    {
      // Could not set all settings in hw_init. Query get_driver_state to get active settings.
      const driverState = await this._board_rpc_client.invoke('get_driver_state', {
      })
    }
    */

    this._board_rpc_client = boardClient
    this._init_board_socket_client(board_ip, secure)

    return true
  },

  /**
   * @typedef {Object} BoardConfiguration
   * @property {number[]} base_recv_ports Base port for RTP communication
   * @property {string} external_ip detected external IP from network configuration
   * @property {string} board_system Elk board system
   * @property {string} board_audio_hat Board audio hat
   * @property {number} audio_buffer_size Elk's audio buffer size
   * @property {number} audio_sampling_rate Elk's audio sampling rate
   * @property {string} elk_version Elk Audio OS version
   * @property {string} aloha_version Aloha version
   */

  /**
   * Queries Aloha board parameters.
   *
   * @returns {BoardConfiguration} Board configuration object
   */
  async get_board_configuration() {
    if (this._cloud_debug_mode) {
      return DEFAULT_BOARD_CONFIGURATION
    }
    return await this._board_rpc_client.invoke('get_board_configuration')
  },

  async start_software_update(update_url) {
    await this._board_rpc_client.invoke('update', {
      update_url,
    })
  },

  /**
   * Return the result of previous port-forwarding tests on the board.
   */
  get_port_forwarding_test_result() {
    if (this._cloud_debug_mode) {
      return true
    }
    return this._board_rpc_client.invoke('get_port_forwarding_test_result')
  },

  /**
   * Return the backend logs on the board as a single string
   *
   * @param {String} IP address of board to connect to
   * @returns {string} Backend logs
   */
  async get_board_logs(ip) {
    if (this._cloud_debug_mode) {
      return ''
    }

    try {
      const board_client = RPCClient(
        board_ip_to_url(ip),
        DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT,
        ip !== DESKTOP_IP ,
      )
      const log_data = await board_client.invoke('get_logs')
      return log_data.contents
    } catch {
      return 'Not connected'
    }
  },

  /**
   * Return the backend, aloha and sushi logs separated as a concatenated string
   *
   * @returns {string} logs
   * @param ip {String} IP address of board to connect to
   * @param user_id {int} Current user id
   */
  async get_discrete_logs(ip, user_id) {
    if (this._cloud_debug_mode) {
      return ''
    }

    try {
      const board_client = RPCClient(
        board_ip_to_url(ip),
        DEFAULT_BOARD_BACKEND_PORT,
        ip !== DESKTOP_IP,
      )
      return await board_client.invoke('get_logs_discrete', {
        "user_id": user_id
      })
    } catch {
      return 'Not connected'
    }
  },

  /**
   * Return the IP address (as string) queried from router's uPnP IGD.
   *
   * @returns {string} External IP address
   */
  async get_router_external_ip_address() {
    return await this._board_rpc_client.invoke('get_router_external_ip_address')
  },

  async refresh_board_token(user_id) {
    const result = await this._board_rpc_client.invoke('refresh_token', {
      master_password: 'a6jHTd5rRcy9KhPn',
      user_id,
    })
    if (typeof result === 'string') {
      this._board_rpc_client.set_board_connection_token(result)
    }
  },

  /**
   * Trigger an speedtest in the backend, result is returned via websocket message
   */
  async do_speedtest() {
    if (this._cloud_debug_mode) {
      return true
    }
    return this._board_rpc_client.invoke('do_speedtest')
  },

  /**
   * Attaches a user rating (int between 0 and 5) and a user comment (str) to a session, when it was closed after a preset duration.
   *
   * @param {Object} params
   * @returns {string} Returns the response from the server as a string: 'success' or 'error'
   */
  async attach_user_rating_to_session(params) {
    await this._cloud_rpc_client.invoke(
      'attach_user_rating_to_session',
      params,
    )
  },


  /**
   * Desktop API. TODO: Split into separate file and tie to device?
   */
  init_desktop_rpc_client(ip) {
    this._desktop_rpc_client = RPCClient(
      board_ip_to_url(ip),
      DEFAULT_BOARD_BACKEND_PORT,
      false,
    )
  },

  /**
   * Fetch audio devices info
   * @returns Object with array of input and output devices and default audio devices
   *
   */
  async get_audio_devices_info() {
    logger.debug('[APIClient] getting desktop audio devices')
    const response = await this._desktop_rpc_client.invoke('get_audio_devices')
    if(response?.apple_coreaudio_devices) {
      return response.apple_coreaudio_devices
    }
    return response
  },

  /**
   * Restarts sushi with a selected audio device
   *
   * Must match with the index in the object retrieved with get_audio_devices
   * @returns
   * @param input {int} The index of the audio input
   * @param output {int} The index of the audio output
   * @param init {boolean}
   */
  async set_audio_device(input, output, init = false) {
    logger.debug('[APIClient]Starting sushi for desktop with ', "--audio-input-device="+input, "--audio-output-device="+output)
    return this._desktop_rpc_client.invoke(init ? 'start_sushi' : 'restart_sushi', {
      "extra_sushi_args": ["--audio-input-device-uid="+input, "--audio-output-device-uid="+output]
    })
  },

  /**
   * Stop sushi and ws server (meant to be used for desktop)
   *
   */
  async stop_sushi() {
    return this._desktop_rpc_client.invoke('stop_sushi')
  },

  /**
   * WEBSOCKET API
   *********************************************************************************/

  /**
   * Track controls
   ********************/

  /**
   * Change volume of one of the two channels of a track.
   *
   * @param {string} track_id One of [input', 'own', 'partnerId'] being partnerId a numeric id value
   * @param {number} channel Number 1 or 2 for first or second channel
   * @param {number} value Volume parameter in [0.0, 1.0]
   */
  track_volume(track_id, channel, value) {
    if (
      track_id !== 'input' &&
      track_id !== 'own' &&
      isNaN(track_id)
    ) {
      throw new Error('Invalid track_id for track_volume')
    }
    if (channel !== 1 && channel !== 2) {
      throw new Error('Invalid channel for track_volume')
    }
    if (typeof value !== 'number' || value < 0 || value > 1) {
      throw new Error('Invalid value for track_volume')
    }
    this._board_socket_client?.send_message('mix', {
      type: `ch${channel}_gain`,
      track: track_id,
      value,
    })
  },

  /**
   * Change pan of one of the two channels of a track.
   *
   * @param {string} track_id One of [input', 'own', 'partnerId'] being partnerId a numeric id value
   * @param {number} channel Number 1 or 2 for first or second channel
   * @param {number} value Pan parameter in [0.0, 1.0]
   */
  track_pan(track_id, channel, value) {
    if (
      track_id !== 'input' &&
      track_id !== 'own' &&
      isNaN(track_id)
    ) {
      throw new Error('Invalid track_id for track_pan')
    }
    if (channel !== 1 && channel !== 2) {
      throw new Error('Invalid channel for track_pan')
    }
    if (typeof value !== 'number' || value < 0 || value > 1) {
      throw new Error('Invalid value for track_pan')
    }
    this._board_socket_client?.send_message('mix', {
      type: `ch${channel}_pan`,
      track: track_id,
      value,
    })
  },

  /**
   * Master volume
   *
   * @param {number} value Volume parameter in [0.0, 1.0]
   */
  master_volume(value) {
    if (typeof value !== 'number' || value < 0 || value > 1) {
      throw new Error('Invalid value for master_volume')
    }
    this._board_socket_client?.send_message('mix', {
      type: 'master_gain',
      value,
    })
  },

  /**
   * Sets the compensation delay to adjust the perceived distance between players.
   *
   * @param {number} value The new value
   */
  compensation_delay(value) {
    this._board_socket_client?.send_message('compensation_delay', {
      value: value ?? 0,
    })
  },

  /**
   * Sets the jitter buffer multiplier for a user.
   *
   * @param {number} user_id
   * @param {number} value
   */
  jitter_buffer_multiplier(user_id, value) {
    this._board_socket_client?.send_message('audio_offset', {
      user_id,
      value: value ?? 0,
    })
  },

  /**
   * Hardware controls
   ********************/

  /**
   * Sets the electric gain of BRIDGE’s audio preamps
   *
   * @param {number} channel the preamp number: 0 or 1
   * @param {string} value the new gain value
   */
  preamp_gain(channel, value) {
    this._board_socket_client?.send_message('hw', {
      type: 'preamp_gain',
      channel,
      value,
    })
  },

  toggle_panic_mode() {
    this._board_socket_client?.send_message('hw', {
      type: 'kill_switch'
    })
  },

  /**
   * Sets the input configuration for a track.
   */
  input_config(channel, value) {
    const [type, mode] = translateInputOption(value)
    this._board_socket_client?.send_message('hw', {
      type: 'input_config', channel, interface: type, mode,
    })
  },

  /**
   * Sets the stereo link state of the user track.
   *
   * @param {boolean} value true for a stereo linked track, false for dual mono tracks
   */
  stereo_link(value) {
    this._board_socket_client?.send_message('hw', {
      type: 'stereo_link',
      value,
    })
  },

  /**
   * Sets the stereo link state of a partner track.
   *
   * @param {number} user_id
   * @param {boolean} value true for a stereo linked track, false for dual mono tracks
   */
  partner_stereo_link(user_id, value) {
    this._board_socket_client?.send_message('hw', {
      type: 'partner_stereo_link',
      user_id,
      value,
    })
  },

  /**
   * Headphones volume
   *
   * @param {number} value Volume parameter in [0.0, 1.0]
   */
  headphones_volume(value) {
    if (typeof value !== 'number' || value < 0 || value > 1) {
      throw new Error('Invalid value for headphones_volume')
    }
    this._board_socket_client?.send_message('hw', {
      type: 'hp_gain',
      value,
    })
  },

  /**
   * Session controls
   ********************/

  /**
   * Notify the board that a user has left the session.
   *
   * @param {number} user_id
   * @param {number} session_id
   */
  board_leave_session(user_id, session_id) {
    this._board_socket_client?.send_message('session_update', {
      type: 'leave_session',
      user_id,
      session_id,
    })
  },

  /**
   * Notify the board that a user has replied to the session invitation.
   *
   * @param {number} user_id
   * @param {number} session_id
   * @param {accepted} boolean true if user has accepted the invite, false otherwise
   */
  board_session_reply(user_id, session_id, accepted) {
    this._board_socket_client?.send_message('session_update', {
      type: 'session_reply',
      user_id,
      session_id,
      accepted,
    })
  },

  board_session_request() {
    this._board_socket_client?.send_message('session_update', {
      type: 'session_request',
    })
  },

  board_session_accepted(user_ids, session_id) {
    this._board_socket_client?.send_message('session_update', {
      type: 'session_accepted',
      user_ids,
      session_id
    })
  },

  /**
   * Notify the board that a user has updated their hw_settings
   *
   * @param {number} user_id
   * @param {bool} stereo_link
   */
  board_partner_stereo_link_update(user_id, stereo_link) {
    this._board_socket_client?.send_message('hw', {
      type: 'partner_stereo_link',
      user_id: user_id,
      value: stereo_link,
    })
  },

  /**
   * Enable video sync mode
   * @param {*} enable
   * @param {*} delay
   */
  async video_sync(enable, delay) {
    if (this._cloud_debug_mode) {
      return
    }
    this._board_socket_client?.send_message('video_sync', {
      state: enable,
      delay,
    })
    logger.debug(`[APIClient: video_sync] Will invoke board api to set "video_sync" mode to ${enable} with delay ${delay}`);
  },

  /**
   * END OF WEBSOCKET API
   *********************************************************************************/

  /**
   * Sets the phantom power of a mic
   *
   * @param {number} input_nr: the pream
   * @param {bool} value: the requested state (True = ON, False = OFF)
   */
  async phantom_power(input_nr, value) {
    try {
      const res = await this._board_rpc_client.invoke('set_phantom_power', {
        input_nr: input_nr,
        state: value,
      })
      if (!res) {
        logger.error('Could not set phantom power.')
      }
      return res
    } catch (error) {
      if (error.data) {
        logger.error( error.data.message,
        { type: error.data.type })
      } else {
        logger.error(error)
      }
    }
    return false
  },

  async identify_bridge() {
    await this._board_rpc_client.invoke('identify_bridge')
  },

  /**
   * Turns Aloha streaming ON or OFF
   *
   * @param {number} channel: The channel to activate/deactivate [0 | 1]
   * @param {bool} value: True for ON, False for OFF
   */
  async send(channel, value) {
    await this._board_rpc_client.invoke(
      'set_channel_broadcast_state',
      {
        channel: channel,
        value: value,
      },
    )
  },

  /**
   * -----------
   * RT controls
   * -----------
   * these are forwarded directly to SUSHI through OSC
   */

  /**
   * Change the jitter delay on one of the tracks.
   *
   * @param {string} track_id One of [input', 'own', 'partnerId'] being partnerId a numeric id value
   * @param {number} value Delay parameter
   */
  track_jitter_delay(track_id, value) {
    const osc_path = track_id === 'own'
      ? `/parameter/compensation_delay/delay`
      : `/parameter/aloha_${track_id}/jitter_delay`

    const sushi_jitter_delay = value
    this._board_socket_client?.send_OSC_message(osc_path, sushi_jitter_delay)
  },

  /**
   * Toggle the ECC part of the Aloha plugin on the board for track_id
   *
   * @param {string} track_id One of [input', 'own', 'partnerId'] being partnerId a numeric id value
   * @param {boolean} value Bypass parameter in `true` or `false`
   */
  track_ECC(track_id, value) {
    if (track_id !== 'own') {
      const osc_path = `/parameter/aloha_${track_id}/ecc_active`
      this._board_socket_client?.send_OSC_message(osc_path, value ? 1 : 0)
    }
  },

  /**
   * Trigger a network resynchronization for track_id
   *
   * @param {string} track_id One of [input', 'own', 'partnerId'] being partnerId a numeric id value
   */
  trigger_resync(track_id) {
    if (track_id !== 'own') {
      const osc_path = `/parameter/aloha_${track_id}/trigger_resync`
      this._board_socket_client?.send_OSC_message(osc_path, 1.0)
    }
  },

  /**
   * -----------
   * Session talkback methods
   * -----------
   */

  /**
   * Return the webrtc talkback spd offer. Make sure that the osc message
   * /webrtc/offer_ready has been received before getting to ensure the offer is
   * up to date.
   *
   * @returns {Object} sdp offer
   */
  async get_sdp_offer() {
    return await this._board_rpc_client.invoke('get_sdp_offer')
  },

  /**
   * Set the webrtc talkback sdp answer. Make sure this is set before sending
   * the OSC /parameter/webrtc/read_answer i 1 message to ensure that the answer
   * read is up to date.
   *
   * @param {Object} localSdp local session description (answer)
   */
  async set_sdp_answer(localSdp) {
    return await this._board_rpc_client.invoke('set_sdp_answer', localSdp)
  },

  /**
   * Instructs the sushi client to read the answer SDP provided earlier by
   * the call to set_sdp_answer and conclude the webrtc signaling dance for the
   * session talkback audio transfer.
   */
  async read_answer() {
    return await this._board_socket_client?.send_OSC_message(
      '/parameter/webrtc/read_answer',
      1,
    )
  },

  /**
   * -----------
   * Polls for each backend. Time intervals in their respective setInterval() calls
   * -----------
   */

  /**
   * Polls the board backend for events.
   *
   * Must be called periodically so that the backend knows that the client is still alive.
   *
   * Most of the logic in response to the events should be handled by the caller, this module only:
   *  - periodically polls the board backend
   *  - start the session on the board when `session_ready_notification` is received
   *
   * At each iteration, call the board backend.
   *
   * TODO: add more info here
   * @returns {Object}
   */
  async poll_board_backend() {
    if (this._cloud_debug_mode) {
      return {}
    }
    return await this._board_rpc_client.invoke('periodic_client_poll')
  },

  /**
   * Polls the cloud backend to check access token
   */
  async poll_cloud_backend(user_id, connected_to_bridge) {
    await this._cloud_rpc_client.invoke(
      'check_access_token',
      {
        user_id,
        connected_to_bridge
      }
    )
  },

  /**
   * Set the URL for aloha-core package downloaded by the board at boot.
   *
   * @param {String} IP address of the board to connect to
   * @param {string} url_str String with URL of file to download. No check is done on the format.
   */
  set_aloha_core_override_url(ip, url_str) {
    if (APIClient._cloud_debug_mode) return

    try {
      const essential_services_client = RPCClient(
        board_ip_to_url(ip),
        DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT,
      )
      return essential_services_client.invoke('set_core_override_url', {
        url: url_str,
      })
    } catch (err) {
      logger.exception('Error while trying to set aloha core override', err)
    }
  },

  /**
   * Get the aloha-core-override URL saved on the board. If return value is `null`
   * then the data is unavailable.
   *
   * @param {String} IP address of the board to connect to
   * @returns {string} String with URL of aloha core override, if present on the board.
   */
  async get_aloha_core_override_url(ip) {
    if (APIClient._cloud_debug_mode) return null
    try {
      const essential_services_client = RPCClient(
        board_ip_to_url(ip),
        DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT,
      )
      return essential_services_client.invoke('get_core_override_url')
    } catch (err) {
      logger.exception(
        'Error while trying to get aloha core overrider URL',
        err,
      )
    }
    return null
  },

  /**
   * Reset the aloha-core-override URL saved on the board. If return value is `false`
   * then there was no core-override URL saved on the board
   *
   * @param {String} IP address of the board to connect to
   * @returns {boolean} True if an override file was found and removed, False if no file was found. Null otherwise.
   */
  async reset_aloha_core_override_url(ip) {
    if (APIClient._cloud_debug_mode)
      return null
    try {
      const essential_services_client = RPCClient(board_ip_to_url(ip), DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT)
      return essential_services_client.invoke('reset_core_override_url')
    } catch (err) {
      logger.exception('Error while trying to get aloha core overrider URL', err)
    }
    return null
  },

  /**
   * Checks if board with provided IP address is available
   *
   * @param {String} ip address of the board to check
   * @param {boolean} secure: `true` to do https request calls
   * @returns {boolean} `true` if server replied correctly
   */
  async check_board_availability(ip, secure = true) {
    if (APIClient._cloud_debug_mode) return true
    if(ip === DESKTOP_IP) return true

    logger.info(
      `Invoking is_board_alive for ${board_ip_to_url(
        ip,
      )} ${DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT}`,
    )

    const controller = new AbortController()
    const signal = controller.signal

    setTimeout(() => {
      controller.abort()
    }, 3000)

    const protocol = secure ? 'https' : 'http'

    const url = `${protocol}://${board_ip_to_url(ip)}:${DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: "0",
        jsonrpc: "2.0",
        method: "is_board_alive",
        params: []
      }),
      signal: signal
    })
    const json = await response.json()
    return json.result

  },

  /**
   * Gets logged in state of user
   *
   * @return {Object}
   */
  async get_is_logged_in(user_id) {
    try {
      const result = await this._cloud_rpc_client.invoke('get_is_logged_in', {
        user_id,
      })
      return result.is_logged_in
    } catch (err) {
      logger.exception(err)
      return {}
    }
  },

  /**
   * Logout user in all tabs/browsers/login-sessions
   *
   * @return {Object}
   */
  async logout_everywhere(user_id) {
    try {
      return await this._cloud_rpc_client.invoke('logout_everywhere', {
        user_id,
      })
    } catch (err) {
      logger.exception(err)
      return {}
    }
  },

  /**
   * Gets logged in user settings
   *
   * @return {Object}
   */
  async get_user_settings(user_id) {
    try {
      const data = await this._cloud_rpc_client.invoke('get_settings', {
        user_id,
      })
      return JSON.parse(data.settings)
    } catch (err) {
      logger.exception(err)
      return {}
    }
  },

  /**
   * Gets logged in user settings key
   *
   * @return {Object}
   */
  async get_user_settings_key(user_id, key) {
    try {
      const data = await this._cloud_rpc_client.invoke('get_settings_key', {
        user_id,
        key,
      })
      return data[key]
    } catch (err) {
      logger.exception(err)
      return {}
    }
  },

  /**
   * Saves logged user settings
   *
   * @param {Object} json object to save
   * @returns {boolean} `true` if server replied correctly
   */
  async save_user_settings(value) {
    try {
      await this._cloud_rpc_client.invoke('set_settings', {
        user_id: this._user_id,
        settings: JSON.stringify(value),
      })
      return true
    } catch (error) {
      logger.exception(error)
      return false
    }
  },

  /**
   * Saves logged user settings
   *
   * @param {Object} json object to save
   * @returns {boolean} `true` if server replied correctly
   */
  async save_user_settings_key(user_id, key, value, notify_partners = false) {
    try {
      await this._cloud_rpc_client.invoke('set_settings_key', {
        user_id,
        key,
        value,
        notify_partners
      })
      return true
    } catch (error) {
      logger.exception(error)
      return false
    }
  },

  /**
   * @returns {Array}
   */
  async query_devices_on_network() {
    try {
      const devices_on_network = this._devices_rpc_client.invoke(
        'query_devices_on_network', {}
      )
      if (devices_on_network) return devices_on_network
    } catch (error) {
      if (error.data) {
        logger.error(error.data.type, error.data.message)
      } else {
        logger.error(error)
      }
    }
    return []
  },

  /**
   * Creates support ticket
   */
  async report_an_issue({ subject, description, server, commit, images = [], logs }) {
    const issueReport = {
      subject,
      description,
      server,
      commit,
      logs,
    }
    const backendCall = async (report) => {
      try {
        const result = await this._cloud_rpc_client.invoke('report_an_issue', report)
        return result.success === true
      } catch (err) {
        return false
      }
    }
    if (images.length) {
      const imagesToSend = images.map(image => convertBase64ToBlob(image))
      // Return new promise to keep the issue upload ongoing while waiting for event from server
      return new Promise((resolve, reject) => {
        // Create a new callback to send the report when images have been uploaded
        const mediaConverterCallback = async (eventData) => {
          try {
            const imageUploads = imagesToSend.map((imageData) => postImageToS3(imageData, eventData))
            await Promise.all(imageUploads)
          } catch (err) {
            logger.error(err)
            throw new APIError("Couldn't upload support images, please try again.")
          }

          // TODO: This needs to be update when supporting multiple images
          const urls = [`${eventData.url}${eventData.fields.key}`]

          // Decorate issue report with images
          issueReport.description = [
            '---- Attachments ----',
            ...urls,
            '',
            '---- Description ----',
            issueReport.description
          ]
            .join('\n')
            .trim()

          // Perform the report issue backend call
          const result = await backendCall(issueReport)
          result ? resolve(result) : reject(result)
          return result
        }
        // Push the callback to the event queue
        this._pending_image_upload_callbacks.push(mediaConverterCallback)

        // Call for presigned URLs for image uploading purposes
        this.request_support_upload_presign(imagesToSend[0].type)
      })
    }
    else {
      return backendCall(issueReport)
    }
  },

  /**
   * ----------------------
   * Third-party cloud APIs
   * ----------------------
   */

  /**
   * @returns {string} Public IP address
   */
  async get_public_ip_address() {
    if (!this._ip_data) {
      await this._fetch_public_ip_data()
    }

    return this._ip_data.ip
  },

  /**
   * ---------------
   * Private methods
   * ---------------
   */

  async _fetch_public_ip_data() {
    try {
      const result = await this._cloud_rpc_client.invoke('get_external_ip_address')
      const ipData = result ? JSON.parse(result) : {}

      if (ipData.ip) {
        this._ipdata = {
          ip: ipData.ip,
        }

        return true
      }
    } catch (error) {
      logger.error(error)
    }

    return false
  },

  async _process_cloud_event(event) {
    const event_type = Object.keys(event)[0]
    /**
     * If the event is 'active_users_list_updated' the active users list is
     * updated with the new data.
     */
    if (event_type === 'active_users_list_updated') {
      this._notify_users_active_list_change(event[event_type])
      await this._update_users(event[event_type])
      return
    }

    /**
     * If the event is 'available_users_list_updated' the available users list
     * is updated with the new data.
     */
    if (event_type === 'available_users_list_updated') {
      if (event[event_type]) {
        this._notify_users_available_list_change(event[event_type])
      }
      return
    }

    /**
     * If the event is 'get_location' the location data is stored locally.
     */
    if (event_type === 'get_location') {
      const country_code = event[event_type].country_code
      const city = event[event_type].city
      this._location_data = {
        country: country_code,
        city: city,
      }
      return
    }

    /**
     * If the event is 'connect' we request the location data.
     * This is done late to make sure that the socket is connected.
     */
    if (event_type === 'connect') {
      this.get_location_data(this._user_id)
      if(process.env.REACT_APP_SUBSCRIPTIONS_ENABLED !== 'false'){
        await this.get_subscription_data(this._user_id)
      }
      return
    }

    /**
     * If the event is 'new_message' we forward this nessage
     * to the board.
     */
    if (event_type === 'new_message') {
      const msg = JSON.parse(event[event_type])

      // Do not forward "video-sync-update"-message to the board
      if(msg.message.type === 'video-sync-update') {
        return
      }

      if (msg.message.method !== 'status') {
        logger.info(
          `Received ${msg.message.method} from user ${msg.message.params.from_id}.`,
        )
        this._board_socket_client?.forward_message_to_board(msg.message)
      }
      return
    }

    /**
     * If the event is a change in the friends list we refetch the list of
     * friends.
     */
    if (
      event_type === 'friend_canceled_notification' ||
      event_type === 'friend_reply_notification' ||
      event_type === 'friend_request_notification' ||
      event_type === 'friend_removed_notification'
    ) {
      await this.get_friends(this._user_id)
      return
    }

    /**
     * If the event is 'presign_upload_profile_image' the image data is
     * posted to S3.
     */
    if (event_type === 'presign_upload_profile_image') {
      const msg = JSON.parse(event[event_type])
      try {
        await postImageToS3(this._pending_profile_image_upload, msg.data)
      } catch (err) {
        logger.error(err)
        throw new APIError("Couldn't upload profile picture, please try again.")
      }
      this._pending_profile_image_upload = null
      await this.confirm_profile_image_upload(this._user_id)
      return
    }

    /**
     * If the event is 'presign_upload_support_upload' the image data is
     * posted to S3.
     */
    if (event_type === 'presign_upload_support_upload') {
      const msg = JSON.parse(event[event_type])

      // Get media upload callback
      const mediaUploadCallback = this._pending_image_upload_callbacks.shift()
      return mediaUploadCallback(msg.data)
    }

    /**
     * If the event is 'presign_upload_logfile_upload' the log data is
     * posted to S3.
     */
    if (event_type === 'presign_upload_logfile_upload') {
      const msg = JSON.parse(event[event_type])
      const connectedToDesktop = this._desktop_rpc_client !== null && isDesktop()
      if(this._user_id){
        const backendLogs = await (connectedToDesktop ? this.get_discrete_logs(DESKTOP_IP, this._user_id) : this.get_board_logs(this._board_rpc_client.get_ip())) //TODO: Remove ternary when get_discrete_logs is available for bridge
        const electronLogs = connectedToDesktop ? await getElectronLogs() : undefined
        const jsLogs = { webapp: ElkLogger.getLogs(), electron: electronLogs }
        const allLogs = { [this._user_id]: {...backendLogs[this._user_id], ...jsLogs}}
        const logsToSend = process.env.REACT_APP_ENABLE_LOG_COMPRESSION === 'true' ? pako.deflate(JSON.stringify(allLogs)) : allLogs
        await postLogsToS3(logsToSend, msg.data)
      }
    }
  },

  /**
   * Update the current dict of users and query the server for information for new users.
   *
   * @param {Object} new_list
   */
  async _update_users(all_users) {
    const old_users = Object.keys(this._users_map).map((id) => parseInt(id, 10))
    const effective_new_users = all_users.filter(
      (id) => !old_users.includes(id),
    )

    // TODO: Change this to Promise.all for concurrency?
    for (const uid of effective_new_users) {
      try {
        const query_user_data = await this._cloud_rpc_client.invoke('query_user', {
          user_id: uid,
        })
        this._users_map[uid] = query_user_data.userdata
      } catch (err) {
        logger.exception('Error', err)
      }
    }

    const logged_out_users = old_users.filter((id) => !all_users.includes(id))

    for (const uid of logged_out_users) {
      delete this._users_map[uid]
    }

  },

  /**
   * Return the session partners' id ordered in a ring, taking into consideration own's user id.
   *
   * For example, if this._user_id == 7 and other_partners_id == [3, 9], returns:  [9, 3]
   * which are the next players ordered by modulo and not a sorting of the other partners' id (which is [3, 9]).
   *
   * @param {Array} other_partners_id
   */
  _sort_partners_id(other_partners_id) {
    const all_ids = [this._user_id, ...other_partners_id]

    all_ids.sort()

    const mine_idx = all_ids.indexOf(this._user_id)
    const mod_sorted_ids = []
    const n_partners = other_partners_id.length

    for (const n of [...Array(n_partners).keys()]) {
      const next_idx = (mine_idx + 1 + n) % (n_partners + 1)
      mod_sorted_ids.push(all_ids[next_idx])
    }

    return mod_sorted_ids
  },

  async start_internal_session(payload) {
    // If session id is null this is a private session (i.e. no partners) and
    // we do not need to contact the cloud server
    if (!payload.session_id) {
      return
    }
    try {
      logger.info("Session payload", { payload })
      if (!this._cloud_debug_mode) {
        await this._board_rpc_client.invoke('start_session', payload)
      }
    } catch (error) {
      if (error.data) {
        logger.error(error.data.type, error.data.message)
      } else {
        logger.error(error)
      }
    }
  },

  async _stop_internal_session() {
    if (!this._cloud_debug_mode) {
      await this._board_rpc_client.invoke('stop_session', {})
    }
  },

  _init_board_socket_client(board_addr, secure = true) {
    const notForwardingMethods = ['stats']
    logger.info(`Initializing SUSHI client with: ${board_addr}`)
    this._board_socket_client = new BoardWebsocketClient(
      board_ip_to_url(board_addr),
      DEFAULT_OSC_PORT,
      (dest_ids, message) => {
        if(notForwardingMethods.includes(message.method)) return
        this.post_message_to_users?.call(this, dest_ids, message)
      },
      (notification) => {
        this.on_board_notification?.call(this, notification)
      },
      (params) => {
        this.on_mixer_message?.call(this, params)
      },
      (params) => {
        this.on_statistics_message?.call(this, params)
      },
      { cloud_debug_mode: this._cloud_debug_mode },
      secure
    )
  },

  uninitialize_board_clients() {
    this._board_socket_client.close()
    this._board_socket_client = null
  },

  // Friends

  /**
   * Get all friends for a user by id
   *
   * @param {number} id logged in user id
   * @returns {Array} List of friends
   */
  async get_friends(user_id) {
    if (typeof user_id === 'undefined') {
      throw new Error('user_id is undefined')
    }
    try {
      const server_response = await this._cloud_rpc_client.invoke('get_friends', {
        user_id,
      })
      this._notify_friends_list_change(server_response.friends)
    } catch (error) {
      logger.exception('Error', error)
    }
  },

  /**
   * Ask the server to find friends matching search string.
   *
   * @param {string} email Search string
   * @returns {Object} List of users that match search string
   */
  async find_friends(email) {
    try {
      const server_response = await this._cloud_rpc_client.invoke('find_friends', {
        search: email,
      })

      return server_response
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Send friend request from one user to another
   *
   * @param {number} user_id ID of logged in user
   * @param {number} friend_user_id ID of user to send request to
   * @param {string} lookup_string Search string - Only added if search is for full email
   * @param {string} initial_message Initial invitation message for the friend request
   *
   */
  async send_friend_request(user_id, friend_user_id, lookup_string = '', initial_message = '') {
    try {
      if (lookup_string !== '') {
        return await this._cloud_rpc_client.invoke('add_friend', {
          user_id,
          friend_user_id,
          lookup_string,
          initial_message
        })
      } else {
        return await this._cloud_rpc_client.invoke('add_friend', {
          user_id,
          friend_user_id,
          initial_message
        })
      }
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Get user data for a user
   *
   * @returns {Array} User data
   */
  async get_non_friend_users(user_id, offset = 0, limit = 15) {
    try {
      return await this._cloud_rpc_client.invoke('query_users', {
        user_id,
        offset,
        limit
      })
    } catch (error) {
      logger.error(error)
    }
  },
  /**
   * Cancel friend request from one user to another
   *
   * @param {number} user_id ID of logged in user
   * @param {number} friend_user_id ID of user to cancel request to
   */
  async cancel_friend_request(user_id, friend_user_id) {
    try {
      const server_response = await this._cloud_rpc_client.invoke(
        'cancel_friend_request',
        {
          user_id,
          friend_user_id,
        },
      )

      return server_response
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Reply to friend request from one user to another
   *
   * @param {number} friend_user_id User id of user replying to friend request
   * @param {number} reply_to_user_id User id of friend requester
   * @param {boolean} answer Accept = true, ignore = false
   */
  async reply_to_friend_request(friend_user_id, reply_to_user_id, answer) {
    try {
      const server_response = await this._cloud_rpc_client.invoke(
        'reply_to_friend_request',
        {
          friend_user_id,
          reply_to_user_id,
          answer,
        },
      )

      return server_response
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Remove a friend from the set of friends for a user
   *
   * @param {number} user_id User id of user removing friend (has to match token id)
   * @param {number} friend_user_id User id of friend to be removed
   */
  async remove_friend(user_id, friend_user_id) {
    try {
      const server_response = await this._cloud_rpc_client.invoke('remove_friend', {
        user_id,
        friend_user_id,
      })

      return server_response
    } catch (error) {
      logger.error(error)
    }
  },

  async send_email_to_user(recipient_id, message) {
    try {
      this._cloud_socket_client.invoke(
        'send_email_to_user',
        {
          recipient_id,
          message
        }
      )
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Get user data for a user
   *
   * @param {number} id ID of user to fetch data from
   * @returns {Object} User data
   */
  async get_userdata_by_id(user_id) {
    try {
      const server_response = await this._cloud_rpc_client.invoke('query_user', {
        user_id,
      })

      return server_response.userdata
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Get privacy_status of logged in user
   *
   * @param {number} user_id ID of logged in user
   */
  async get_privacy_status(user_id) {
    try {
      const server_response = await this._cloud_rpc_client.invoke(
        'get_privacy_status',
        {
          user_id,
        },
      )

      return server_response
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Set profile fields for logged in user
   *
   * @param {number} user_id ID of logged in user
   * @param {string} privacy_status "PRIVATE" or "PUBLIC"
   * @param {string} personal_summary
   * @param {string} image a base64 encoded image
   */
  async set_profile_fields(
    user_id,
    privacy,
    personal_summary,
    image = '',
  ) {
    if (image !== '') {
      this._pending_profile_image_upload = convertBase64ToBlob(image)
      // after this invocation the backend
      // will emit a 'presign_upload_profile_image' event
      // sending via socket so same specific sid is used to return
      // the presign form
      this._cloud_socket_client.invoke('set_profile_fields', {
        user_id,
        privacy,
        personal_summary,
        image_update_required: true,
        image_content_type: this._pending_profile_image_upload.type,
      })
    } else {
      await this._cloud_rpc_client.invoke('set_profile_fields', {
        user_id,
        privacy,
        personal_summary,
        image_update_required: false,
      })
    }
  },

  /**
   * Get profile fields for logged in user
   *
   * @param {number} user_id ID of logged in user
   */
  async get_profile_fields(user_id) {
    try {
      return await this._cloud_rpc_client.invoke('get_profile_fields', {
        user_id,
      })
    } catch (error) {
      this._check_for_expired_refresh_token(error)
      logger.error(error)
    }
  },

  /**
   * Set the display name of logged in user
   *
   * @param {number} user_id ID of logged in user
   * @param {string} display_name The new display name
   */
  async set_display_name(user_id, display_name) {
    try {
      return await this._cloud_rpc_client.invoke('set_display_name', {
        user_id,
        display_name,
      })
    } catch (error) {
      this._check_for_expired_refresh_token(error)
      logger.error(error)
    }
  },

  /**
   * Get the display name of a user
   *
   * @param {number} user_id
   */
  async get_display_name(user_id) {
    try {
      return await this._cloud_rpc_client.invoke('get_display_name', {
        user_id,
      })
    } catch (error) {
      this._check_for_expired_refresh_token(error)
      logger.error(error)
    }
  },

  /**
   * Confirm that the image has been correctly uploaded
   * to S3 and should be used as the new profile image
   * for the user.
   */
  async confirm_profile_image_upload(user_id) {
    try {
      const server_response = await this._cloud_rpc_client.invoke(
        'confirm_profile_image_upload',
        {
          user_id,
        },
      )
      return server_response
    } catch (error) {
      logger.error(error)
    }
  },

  /**
   * Request a presigned URL for image uploading
   */
  // TODO: This needs to be updated to support multi file uploading
  async request_support_upload_presign(fileType) {
    try {
      this._cloud_socket_client.invoke(
        'request_support_upload_presign',
        {
          user_id: this._user_id,
          file_type: fileType
        }
      )
    } catch (error) {
      logger.error(error)
    }
  },
  async request_logfile_upload_presign(sessionId) {
    try {
      this._cloud_socket_client.invoke(
        'request_logfile_upload_presign',
        {
          user_id: this._user_id,
          session_id: sessionId,
          file_type: 'log'
        }
      )
    } catch (error) {
      logger.error(error)
    }
  },
  async get_subscription_data(user_id) {
    /**
     * Returns a 'user_subscription_details_notification'  event via socketio
     */
     try {
      this._cloud_socket_client.invoke(
        'request_subscription_details',
        { user_id, }
      )
    } catch (error) {
      logger.error(error)
    }
  },

  async cancel_subscription(user_id, subscription_id) {
    /**
     * Returns a '   '  event via socketio
     */
    try {

      this._cloud_rpc_client.invoke(
        'request_subscription_cancellation',
        { user_id: user_id, fastspring_subscription_id: subscription_id })
    } catch (error) {
      logger.error(error)
    }
  },

  async fastspring_subscription_purchased(user_id, fastspring_order) {
    try {
      this._cloud_socket_client.invoke(
        'fastspring_webhook_subscription_purchased',
        {
          user_id,
          fastspring_order,
        },
      )
    } catch (error) {
      logger.error(error)
    }
  },
}
