import { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  LocalVideo,
  useLocalVideo,
} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import { useStore } from '../../../store'
import { LOCAL_VIDEO_MODE } from '../../../store/session'
import { Box } from '../../ui/primitives'
import { Button } from '../../ui/button'
import { MinimizeIcon, MaximizeIcon } from '../../ui/icons'
import Nameplate from './nameplate'
import { BottomContainer } from './video-overlays'
import VideoPlaceholder from './video-placeholder'

const fluidStyles = `
  height: 100%;
  width: 100%;
`

const minimizedVideo = css`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 32rem;
`

const VIDEO_MODES = Object.values(LOCAL_VIDEO_MODE)
const LocalVideoBox = styled(Box)`
  ${({ $mode }) =>
    $mode !== LOCAL_VIDEO_MODE.DEFAULT &&
    `
    border: 3px solid var(--ELK-White);
  `}

  ${({ $mode }) =>
    $mode === LOCAL_VIDEO_MODE.MINIMIZED &&
    `
    ${minimizedVideo}
    height: 21rem;
  `}

  ${({ $mode }) =>
    $mode === LOCAL_VIDEO_MODE.INFO_BAR &&
    `
    ${minimizedVideo}
    height: 5.4rem;
  `}
`

const VideoModeButton = styled(Button)`
  margin-right: 1.5rem;
`

const LocalParticipant = () => {
  const { isVideoEnabled } = useLocalVideo()
  const store = useStore()
  const [mode, setMode] = useState(0)
  const currentUser = store.users.get(store.currentUserId)

  const currentVideoMode = store.session.localVideoMode

  useEffect(() => {
    if (currentVideoMode !== VIDEO_MODES[mode]) {
      setMode(VIDEO_MODES.findIndex((mode) => mode === currentVideoMode))
    }
  }, [currentVideoMode, mode])

  const toggleVideoMode = useCallback(() => {
    let newMode = mode + 1
    if (newMode >= VIDEO_MODES.length) {
      newMode = 0
    }
    setMode(newMode)
    store.session.localVideoMode = VIDEO_MODES[newMode]
  }, [mode, store.session])

  return (
    <LocalVideoBox $mode={currentVideoMode}>
      <Box relative height="100%">
        {isVideoEnabled && currentVideoMode !== LOCAL_VIDEO_MODE.INFO_BAR ? (
          <LocalVideo css={fluidStyles} />
        ) : (
          <VideoPlaceholder user={currentUser} />
        )}
        <BottomContainer>
          <Box flexBasis="10%">
            <VideoModeButton p="0.5rem" onClick={toggleVideoMode}>
              {currentVideoMode === LOCAL_VIDEO_MODE.INFO_BAR ? (
                <MaximizeIcon />
              ) : (
                <MinimizeIcon />
              )}
            </VideoModeButton>
          </Box>
          <Nameplate text={`${currentUser?.name} (you)`} />
          <Box flexBasis="10%" />
        </BottomContainer>
      </Box>
    </LocalVideoBox>
  )
}

export default LocalParticipant
