import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Container,
  Header,
  Hero,
  HeroText,
  NavContainer,
  Footer,
  FooterDivider,
  BetaBgContainer,
  SocialFooter,
  JoinButton,
  BetaBodyContaienr
} from './style'
import { ElkLiveLogo } from '../../../../ui/icons'
import { getElkLiveWebsiteUrl } from '../../../../../utils/utils'

export const BetaLayout = observer(({ children, setPage }) => {
  const year = new Date().getFullYear()
  const elkLiveWebsite = getElkLiveWebsiteUrl()
  return (
    <Container>
      <Header>
        <NavContainer>
          <a href="https://elk.live"><ElkLiveLogo size={90}/></a>
          <nav>
            <a href={elkLiveWebsite + 'all-you-need-to-know'} target="_blank" rel="noreferrer">
              <h2>all you need to know</h2>
            </a>
            <a href={elkLiveWebsite + 'players'} target="_blank" rel="noreferrer">
              <h2>players</h2>
            </a>

            <a href={elkLiveWebsite + 'the-bridge'} target="_blank" rel="noreferrer">
              <h2>the bridge</h2>
            </a>

            <a href={elkLiveWebsite + 'pricing'} target="_blank" rel="noreferrer">
              <h2>pricing</h2>
            </a>
            <JoinButton onClick={() => setPage('join')}>
              JOIN!
            </JoinButton>
          </nav>
        </NavContainer>
      </Header>
      <BetaBodyContaienr>
        <Hero>
          <HeroText>JOIN IN!</HeroText>
        </Hero>
        <BetaBgContainer>
          {children}
        </BetaBgContainer>
      </BetaBodyContaienr>
      <Footer>
        <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/62b3665d0fe94119590b5496_elk%20logo.svg" loading="lazy" id="w-node-ee81c2bf-94a7-df3b-3bfc-25adc84dcb9d-c84dcb9c" alt="" />
        <FooterDivider />
        <a href="https://elk.audio/" target="_blank" rel="noreferrer">About Elk</a>
        <a href="https://jobs.elk.audio/" target="_blank" rel="noreferrer">Work at Elk</a>
        <a href="https://www.iubenda.com/terms-and-conditions/61520776" target="_blank" rel="noreferrer">Terms & Conditions</a>
        <a href="https://www.iubenda.com/privacy-policy/61520776" target="_blank" rel="noreferrer">Privacy Policy</a>
        <a href="https://www.iubenda.com/privacy-policy/10766175/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
        <FooterDivider />
        <SocialFooter>
          <a href="https://www.instagram.com/elk.live/" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/62b3665d0fe9419ce90b54eb_Vector-1.svg" loading="lazy" alt="instagram" />
          </a>

          <a href="https://www.youtube.com/c/ElkLIVEmusic" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/6311b054d3c67a919a651ef2_youtube19x19.svg" loading="lazy" alt="youtube" />
          </a>

          <a href="https://www.facebook.com/elklivemusic" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/62b3665d0fe9416fc10b54ec_Vector.svg" loading="lazy" alt="facebook" />
          </a>

          <a href="https://twitter.com/elkpowered" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/62b3665d0fe941e23b0b54ea_Vector-2.svg" loading="lazy" alt="twitter" />
          </a>
        </SocialFooter>
        <FooterDivider />
        <a href="https://elk.audio/" target="_blank" rel="noreferrer">© Elk Audio {year}</a>
      </Footer>
    </Container>
  )
})


