import compareVersions from 'compare-versions'
import { createAtom, makeAutoObservable, reaction } from 'mobx'
import { APIClient } from '../api/client'
import { logger } from '../utils/logging'
import { DEFAULT_CORE_OVERRIDE_URL } from './settings'
import uuidToMnemonicName from '../utils/uuid-to-mnemonic-name'

export const NAT_TEST_TIMEOUT = 10
export const SECONDS_BEFORE_SHOWING_FEEDBACK_DIALOG = 120

export const DESKTOP_HW_TYPES = ['desktop-mac']
export const DESKTOP_IP = '127.0.0.1'

export function BoardConnectionError({ code, data, message }) {
  this.code = code
  this.data = data
  this.message = message

  this.toString = function () {
    return `${this.code}: ${this.message}`
  }
}

export class Board {
  ipAddress = null
  type = null

  constructor(store, ipAddress) {
    this.store = store
    this.ipAddress = ipAddress
    makeAutoObservable(this)

    const device = store.devices.find(
      (device) => device.ipAddress === ipAddress,
    )
    this.type = device?.hwType;
  }

  connectionCheckStatus = {
    board: 'waiting',
    network: 'waiting',
    cloud: 'waiting',
  }
  config = {}

  /*
   * Try to verify the required connections:
   *   1. Local board connection
   *   2. Port forwarding test
   *   3. Cloud server connection
   */
  async connect({
    userName,
    email,
    hwInit,
    portOverride = null,
    bypassPortForwardingTest = false,
    advertisedIpOverride = null,
  }) {
    /*
     * 1. Local board connection
     */
    this.connectionCheckStatus.board = 'pending'

    try {
      logger.info(
        `Trying to connect to device at ${this.ipAddress}, port override: ${portOverride}`,
      )
      await APIClient.connect_to_board(
        this.ipAddress,
        hwInit,
        portOverride,
        bypassPortForwardingTest,
        this.ipAddress !== DESKTOP_IP // sending secure=false if there is a connection to the virtual bridge (localhost)
      )
    } catch (err) {
      this.connectionCheckStatus.board = 'error'
      throw err
    }
    this.connectionCheckStatus.board = 'ok'

    /*
     * 2. Port forwarding test
     */
    this.connectionCheckStatus.network = 'pending'
    try {
      let networkTestResult = 'testing'
      let networkTestMessage = ''

      while (networkTestResult === 'testing') {
        const response = await APIClient.get_port_forwarding_test_result()
        networkTestResult = response['network-test']
        networkTestMessage = response['message']
        if (networkTestResult === 'testing') await sleep(500)
      }

      if (networkTestResult === 'failure') {
        // NAT test failed, try to give some info to the user
        let publicIp,
          routerIp = 'not detected'
        try {
          publicIp = await APIClient.get_public_ip_address()
          routerIp = await APIClient.get_router_external_ip_address()
        } catch (err) {}

        throw new BoardConnectionError({
          code: 'NAT_TEST_ERROR',
          message: 'Timed out while waiting for port-forwarding test.',
          data: {
            publicIp,
            routerIp,
            basePorts: this.config.base_recv_ports ?? [],
            networkTestMessage,
          },
        })
      }
    } catch (err) {
      this.connectionCheckStatus.network = 'error'
      logger.exception(err)
      throw err
    }
    this.connectionCheckStatus.network = 'ok'

    /*
     * 3. Cloud server connection
     */
    this.connectionCheckStatus.cloud = 'pending'
    try {
      await APIClient.register_active_user(
        userName,
        email,
        advertisedIpOverride,
      )
    } catch (err) {
      logger.error(err)
      this.connectionCheckStatus.cloud = 'error'
      throw err
    }
    this.connectionCheckStatus.cloud = 'ok'
  }

  get connected() {
    return (
      this.connectionCheckStatus.board === 'ok' &&
      this.connectionCheckStatus.network === 'ok' &&
      this.connectionCheckStatus.cloud === 'ok'
    )
  }

  get phantomPower() {
    return this.store.settings.hw_settings.phantom_power
  }

  async setPhantomPower(index, value) {
    if (index >= 0 && index <= 1) {
      let res = await APIClient.phantom_power(index, value)
      // Setting phantom power will fail if no compatible microphone is plugged in.
      if (res) {
        this.store.settings.hw_settings.phantom_power[index] = value
      } else {
        this.store.settings.hw_settings.phantom_power[index] = false
      }
    }
  }

  get isDesktopDevice() {
    return DESKTOP_HW_TYPES.includes(this.type)
  }
}

export class BoardInfo {
  uuid = null
  name = null
  lastSeen = null
  ipAddress = null
  coreOverrideUrl = null
  status = 'unavailable'
  softwareVersion = null
  hwType = null
  version = null
  _label = ''

  constructor(store, { ipAddress, uuid, name, lastSeen, hwType, version }) {
    this.store = store
    this.ipAddress = ipAddress
    this.lastSeen = lastSeen
    this.uuid = uuid
    this.name = name
    this.hwType = hwType
    this.version = version
    this.isManual = uuid === 'manual'
    this.displayName = uuidToMnemonicName(uuid)

    if (this.isDesktopDevice) {
      this.displayName = name
    }

    this._labelAtom = createAtom('label')
    reaction(
      () => this.store.settingsSnapshot,
      () => {
        this._labelAtom.reportChanged()
      },
    )

    makeAutoObservable(this)
  }

  async checkAvailability() {
    this.status = 'pending'
    try {
      const response = await APIClient.check_board_availability(this.ipAddress, this.ipAddress !== DESKTOP_IP)
      if (!response) {
        this.status = 'unavailable'
        return
      }
      if (response.is_in_session) {
        this.status = 'in-session'
      } else {
        this.status = 'available'
      }
      this.softwareVersion = response.sw_version.trim()
      this.coreOverrideUrl = await this.getAlohaCoreOverrideUrl()
    } catch (err) {
      this.status = 'unavailable'
      throw err
    }
  }

  get label() {
    this._labelAtom.reportObserved()
    return this.store.settings.knownDevices[this.uuid] || ''
  }

  set label(value) {
    this.store.settings.knownDevices[this.uuid] = value
    this._labelAtom.reportChanged()
  }

  async isUpdateAvailable() {
    if(DESKTOP_HW_TYPES.includes(this.hwType))  {
      return false
    }
    const response = await APIClient.check_board_availability(this.ipAddress, this.ipAddress !== DESKTOP_IP)
    this.softwareVersion = response.sw_version?.trim()
    if (this.softwareVersion && this.store.serverStartupData.requiredBridgeVersion) {
      return compareVersions.compare(
        this.store.serverStartupData.requiredBridgeVersion,
        this.softwareVersion,
        '>',
      )
    }
    return false
  }

  get hasCustomCoreOverride() {
    if (!this.coreOverrideUrl) return false
    if (
      this.coreOverrideUrl === DEFAULT_CORE_OVERRIDE_URL ||
      this.coreOverrideUrl.includes('No such file or directory')
    )
      return false
    return true
  }

  get isDesktopDevice() {
    return DESKTOP_HW_TYPES.includes(this.hwType)
  }

  getAlohaCoreOverrideUrl() {
    return APIClient.get_aloha_core_override_url(this.ipAddress)
  }

  setAlohaCoreOverrideUrl(url) {
    return APIClient.set_aloha_core_override_url(this.ipAddress, url)
  }

  resetAlohaCoreOverrideUrl() {
    return APIClient.reset_aloha_core_override_url(this.ipAddress)
  }
}

/* utilities */

export function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms)
  })
}
