import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useStore } from '../../store'
import { H3, Box } from '../ui/primitives'
import SystemSettings from '../features/Settings'
import ApplicationLogos from '../features/ApplicationLogos'
import DeviceStatus from '../features/DeviceStatus'
import {
  CollapseIcon,
  LobbyIcon,
  ChatIcon,
  MixerIcon,
  VideoIcon,
  SettingsGear,
  NewChatMessageIcon,
  LowInternetIcon, ExclamationIcon,
} from '../ui/icons'

import { ACTIVE_VIEW_STATE_LOBBY,
         ACTIVE_VIEW_STATE_MIXER,
         ACTIVE_VIEW_STATE_VIDEO } from '../../store/active-view-states'
import { isDesktop } from '../../utils/utils'
import { showWifiWarningDialog } from '../dialogs/wifi'
import { showLowInternetDialog } from '../dialogs/low-internet'
import { ELKBackgroundGrey } from '../../variables'

const HeaderContainer = styled(Box)`
  flex-shrink: 0;
`;

const ChatMenuWrapper = styled.div`
  width: ${props => props.active ? '30rem' : '5rem'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: width 0.225s;
  overflow: hidden;
  margin-right: 1rem;
  cursor: pointer;
  user-select: none;
`

const MenuOption = styled.button`
  background: ${props => props.active ? 'var(--ELK-High-Prio-Yellow-Enabled)' : 'var(--ELK-Black)'};
  color: ${props => props.active ? 'var(--ELK-Black)' : 'var(--ELK-White)'};
  padding: 0 2rem;
  display: flex;
  transition: 0.225s;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  user-select: none;
`

const StyledH3 = styled(H3)`
  white-space: nowrap;
  font-size: 1.4rem;
`
const pulse = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.9;
  }
`

const PulseContainer = styled.div`
  &.pulsing {
    animation: ${pulse} 0.5s linear alternate infinite;
  }
`

const StyledHeader = styled.div`
  height: 6rem;
  width: 100%;
  background: var(--ELK-Black);
  color: var(--ELK-White);
  display: flex;
  justify-content: space-between;
`


const SettingsWrapper = styled.div`
  width: 390px;
  height: calc(100vh - 6rem - 10rem - 6px);
  position: absolute;
  right: calc(73px + 0.6rem - 3px);
  background-color: var(--ELK-Black);
  padding: 0 0 2rem 0;
  top: calc(6.6rem - 3px);
  overflow: auto;
  z-index: 1000;
  border-top: 3px solid ${ELKBackgroundGrey};
  border-bottom: 3px solid ${ELKBackgroundGrey};
`

const NetworkWarning = styled(Box)`
   width: 28px;
   height: 28px;
   display:flex;
   align-items: center;
   justify-content: center;
   margin-right: 10px;
   cursor: pointer;
   color: #BCBCBC;
   font-weight: 700;
   font-size: 1.6rem;
   position: relative;
`

const LowInternetWarning = styled(Box)`
    margin-right: 25px;
    cursor: pointer;
    position: relative;
    bottom: 2px;
`

const WarningIcon = styled.div`
    position: absolute;
    top: -8px;
    right: -17px;
`

const BetaTag = styled.div`
    background: #7a7a7a;
    font-weight: 800;
    padding: 1px 4px;
    font-size: 18px;
    color: #000;
    transform: rotate(-70.72deg);
    position: relative;
    bottom: 7px;
    width: 58px;
    left: 2px;
`

const toggleChat = (store, open) => {
  if (open) {
    store.showChatView = true
    store.chatMessageCounter = 0
  } else {
    store.showChatView = false
  }
}

export const Header = observer(() => {

  const store = useStore()

  const settingsRef = useRef(null)
  const buttonRef = useRef(null)
  const [lowInternetPulsating, setLowInternetPulsating] = useState(true)

  useEffect(() => {
    const hideMenu = (e) => {
      if(store.activeViewState === ACTIVE_VIEW_STATE_MIXER) return
      if (settingsRef.current?.contains(e.target)) return
      if (buttonRef.current?.contains(e.target)) return
      if(e.target.getAttribute('data-hidemenu') === "false") return
      store.showSettingsView = false
    }
    document.addEventListener('click', hideMenu)
    return () => {
      document.removeEventListener('click', hideMenu)
    }
  }, [settingsRef, buttonRef, store])

  useEffect(() => {
    setLowInternetPulsating(true)
  }, [store.speedTestResults?.upload])

  return (
    <HeaderContainer>
      <StyledHeader>
        <Box flex row alignItems="center" color="var(--ELK-White)" width="300px">
          <ApplicationLogos onClick={() => (store.activeViewState = ACTIVE_VIEW_STATE_LOBBY)} />
          <BetaTag>BETA</BetaTag>
          <DeviceStatus />
        </Box>
        <Box flex row>
          <MenuOption
            active={store.activeViewState.includes(ACTIVE_VIEW_STATE_LOBBY)}
            onClick={() =>{
                store.activeViewState = store.activeViewState === ACTIVE_VIEW_STATE_LOBBY
                  ? `${ACTIVE_VIEW_STATE_LOBBY}/friends`
                  : ACTIVE_VIEW_STATE_LOBBY
                store.refreshNonFriends()
            }}
          >
            <LobbyIcon
              size={25}
              active={store.activeViewState.includes(ACTIVE_VIEW_STATE_LOBBY)}
            />
            <StyledH3>Backstage</StyledH3>
          </MenuOption>
          <MenuOption
            active={store.activeViewState === ACTIVE_VIEW_STATE_VIDEO}
            onClick={() => (store.activeViewState = ACTIVE_VIEW_STATE_VIDEO)}
          >
            <VideoIcon
              size={25}
            />
            <StyledH3>Video</StyledH3>
          </MenuOption>
          <MenuOption
            active={store.activeViewState === ACTIVE_VIEW_STATE_MIXER}
            onClick={() => (store.activeViewState = ACTIVE_VIEW_STATE_MIXER)}
          >
            <MixerIcon
              size={25}
              active={store.activeViewState === ACTIVE_VIEW_STATE_MIXER}
            />
            <StyledH3>Mixer</StyledH3>
          </MenuOption>
        </Box>
        <Box flex row justifyContent="flex-end" alignItems="center" width="300px">
          {
            isDesktop() && (
              <>
                {store.speedTestResults?.upload < 10 &&
                  <LowInternetWarning onClick={() => {
                    setLowInternetPulsating(false)
                    showLowInternetDialog({ store })
                  }}>
                    <LowInternetIcon />
                  </LowInternetWarning>
                }
                {store.isWirelessConnection &&

                    <NetworkWarning flex onClick={() => {showWifiWarningDialog({ store })}}>
                        <WarningIcon>
                          <ExclamationIcon />
                        </WarningIcon>

                      {store.speedTestResults?.upload < 10 && (
                        <WarningIcon style={{ right: '38px' }}>
                          <PulseContainer className={lowInternetPulsating ? 'pulsing' : ''}>
                            <ExclamationIcon />
                          </PulseContainer>
                        </WarningIcon>
                      )}
                      WiFi
                    </NetworkWarning>
                }
              </>
            )
          }
          <ChatMenuWrapper
            active={store.showChatView}
            onClick={() => toggleChat(store, !store.showChatView)}
          >
            <MenuOption
            >
              {store.chatMessageCounter > 0 ? (
                <NewChatMessageIcon
                  size={30}
                  text={store.chatMessageCounter}
                />
              ) : (
                <ChatIcon size={30} />
              )}
              <StyledH3>Chat</StyledH3>
            </MenuOption>
            <CollapseIcon size={30} />
          </ChatMenuWrapper>
          <MenuOption
              ref={buttonRef}
              onClick={() => {
                store.showSettingsView = !store.showSettingsView
              }}
            >
            <Box
              color={'var(--ELK-White)'}
              bgColor="var(--ELK-Black)"
            >
              <SettingsGear size={30} />
            </Box>
          </MenuOption>
        </Box>
      </StyledHeader>
      {store.showSettingsView && (
        <SettingsWrapper ref={settingsRef}>
          <SystemSettings />
        </SettingsWrapper>
      )}
    </HeaderContainer>
  )
})
