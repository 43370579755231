import { useState, useEffect, useReducer, useRef } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { Box, Text } from '../../../ui/primitives'
import { ResetIcon } from '../../../ui/icons'
import { Button } from '../../../ui/button'
import { Tooltip } from '../../../ui/tooltip'
import InlineEditableInput from './inline-editable-input'

export const Title = styled(Text)`
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
`

const StyledBox = styled(Box)`
  font-weight: 700;
  font-size: 2.2rem;
  text-align: center;
  color: var(--ELK-Standard-Enabled);
  padding: 0 0.5rem;
`

export function Body({ className, ...rest }) {
  return (
    <StyledBox className={className}>
      <InlineEditableInput {...rest} />
    </StyledBox>
  )
}

const StyledIconReset = styled(ResetIcon)`
  margin: auto;
`

const ResetButton = styled(Button)`
  width: 100%;
  color: var(--ELK-Olympic-White);

  &:hover {
    color: var(--ELK-Black);
  }
`

export function Reset({ onClick }) {
  return (
    <ResetButton onClick={onClick}>
      <StyledIconReset />
    </ResetButton>
  )
}

const StyledTooltip = styled(Tooltip)`
  width: 100%;
  padding: 6px 0;
  margin-bottom: 26px;
`
export const STATE_ACTIONS = {
  FORCE_TOOLTIP_STATE: 'forceTooltipState',
  TRIGGER_DELAYED_TOOLTIP: 'triggerDelayedTooltip',
}

function mixerTooltipReducer(state, { action, payload }) {
  function newState(stateUpdate) {
    return {
      ...state,
      ...stateUpdate,
    }
  }
  switch (action) {
    case STATE_ACTIONS.FORCE_TOOLTIP_STATE:
      return newState({ tooltipIsOpen: payload, tooltipShouldOpen: payload })
    case STATE_ACTIONS.TRIGGER_DELAYED_TOOLTIP:
      return newState({ tooltipShouldOpen: payload, tooltipIsOpen: undefined })
    default:
      throw new Error('Unsupported action submitted')
  }
}

export const useMixerTooltipState = (
  initialState = {
    tooltipShouldOpen: false,
    tooltipIsOpen: false,
  },
) => useReducer(mixerTooltipReducer, initialState)

const OPEN_DELAY = 300
const CLOSE_DELAY = 300
export const MixerTooltip = observer(function MixerTooltip({
  shouldOpen,
  isOpen,
  disabled,
  ...rest
}) {
  const [open, setOpen] = useState(shouldOpen)

  const openTimerId = useRef(null)
  const closeTimerId = useRef(null)

  useEffect(() => {
    if (isOpen !== undefined) {
      clearTimeout(openTimerId.current)
      clearTimeout(closeTimerId.current)
      setOpen(isOpen)
    }
  }, [isOpen])

  useEffect(() => {
    if (shouldOpen && !open) {
      clearTimeout(closeTimerId.current)
      const timerId = setTimeout(() => {
        setOpen(true)
      }, OPEN_DELAY)
      openTimerId.current = timerId
      return
    }

    if (!shouldOpen && open) {
      clearTimeout(openTimerId.current)
      const timerId = setTimeout(() => {
        setOpen(false)
      }, CLOSE_DELAY)
      closeTimerId.current = timerId
      return
    }
  }, [shouldOpen, open])

  return (
    <Box
      {...rest}
    >
      <StyledTooltip isOpen={!disabled ? open : false} {...rest} />
    </Box>
  )
})

export default MixerTooltip
