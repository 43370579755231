import styled from "styled-components";
import { ELKBackgroundGrey } from '../../../variables'

const SettingsWrapper = styled.div`
  width: 390px;
  position: relative;
  flex-shrink: 0;
  background-color: var(--ELK-Dark-Green);
  padding: 0 0 2rem 0;
  overflow: auto;
  margin-right: 2px;
  border-top: 3px solid ${ELKBackgroundGrey};
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
`

const Container = styled.div`
  background: var(--Elk-Background-Grey);
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: stretch;
  justify-content: space-between;
`

const MixerWrapper = styled.div`
  display: flex;
  transform: translateX(-392px);
  transition: transform 0.225s;
  &.expanded {
    transform: translateX(0px);
  }
`

const TrackWrapper = styled.div`
  display: flex;
  border-top: 3px solid ${ELKBackgroundGrey};
`

export {
  SettingsWrapper, CloseButton, Container, MixerWrapper, TrackWrapper
}