import React from 'react'
import { Box } from '../../../ui/primitives'
import { ActionButton } from '../styles'
import {
  ELKHighPrioRedEnabled,
  ELKHighPrioYellowEnabled,
  ELKStandardEnabled
} from '../../../../variables'
import { useStore } from '../../../../store'
import { showSetupSessionDialog } from '../../setup-session'
import { showSendMessageDialog } from '../../SendMessageDialog'

export const ActionButtons = ({ user, isOwnUser, status, onConnect, close }) => {
  const store = useStore()
  return (
    <Box>
    {!isOwnUser && (
    <>

      {status === 'Requested' && (
        <ActionButton
          onClick={async () => {
            await store.cancelFriendRequest(user.id)
          }}
          bgColor={ELKHighPrioRedEnabled}>
          Cancel request
        </ActionButton>
      )}

      {status === 'Pending' && (
        <Box flex justifyContent="space-between">
          <ActionButton
            maxWidth="130px"
            onClick={async () => {
              await store.replyToFriendRequest(user.id, true)
            }}
            >
            Accept request
          </ActionButton>
          <ActionButton
            maxWidth="130px"
            onClick={async () => {
              await store.replyToFriendRequest(user.id, false)
            }}
            bgColor={ELKHighPrioRedEnabled}>
            Decline request
          </ActionButton>
        </Box>
      )}

      {status === 'Confirmed' && (
        <Box flex justifyContent="space-between">
          <ActionButton
            bgColor={ELKStandardEnabled}
            maxWidth="130px"
            onClick={async () => {
              close()
              showSendMessageDialog({store, user})
            }}
          >
            Send a message
          </ActionButton>
          <ActionButton
            bgColor={ELKHighPrioYellowEnabled}
            maxWidth="130px"
            onClick={async () => {
              close()
              showSetupSessionDialog({ store, partnerIds: [user.id] })
            }}
            >
            Start a session
          </ActionButton>
        </Box>
      )}

      {!status && (
        <ActionButton onClick={() => {
          onConnect()
        }}>Connect
        </ActionButton>
      )}

    </>
  )}
</Box>
  )
}
