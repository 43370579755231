import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { APIClient } from './api/client'
import { configure } from 'mobx'
import { App } from './App'
import { StoreProvider } from './store'
import { Store } from './store/store'
import { VideoChatProvider } from './components/video-chat'
import { Variables } from './variables'

import 'modern-normalize/modern-normalize.css'
import './styles/preflight.css'
import './styles/global.css'
import './styles/modals.css'
import './styles/animations.css'
import 'intro.js/introjs.css';
// import 'intro.js/themes/introjs-modern.css';
import { initSentry } from './sentry'
import { logger } from './utils/logging'

initSentry()

configure({
  enforceActions: 'never',
})

const store = new Store()
logger.initLogger(store)

if (process.env.REACT_APP_DEBUG_MODE === 'true') {
  window.store = store
  window.alohaClient = APIClient
}

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
})

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={store}>
      <VideoChatProvider>
        <Variables />
        <App />
      </VideoChatProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)