import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from 'styled-components' //'styled-components' -> /macro for debug purposes
import { Box, H3 } from '../../../ui/primitives'
import { observer } from 'mobx-react-lite'

import {
  MixerTooltip,
  Title as TooltipTitle,
  Body as TooltipBody,
  Reset as TooltipReset,
  STATE_ACTIONS,
  useMixerTooltipState,
} from './mixer-tooltip'
const { FORCE_TOOLTIP_STATE, TRIGGER_DELAYED_TOOLTIP } = STATE_ACTIONS

const KnobHolder = styled.div`
  width: 68px;
  height: 68px;
  touch-action: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const TopIndicator = styled.div`
  background-color: ${({ color }) => color};
  width: 3px;
  height: 10px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 2px;
`

const Label = styled.h2`
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  width: 20px;
  display: inline-block;
  position: absolute;
  font-size: 18px;
  user-select: none;
  font-weight: 700;
`

const TooltipContainer = styled(Box)`
  position: absolute;
  bottom: 100%;
  z-index: 1;
`

const KnobTooltip = styled(MixerTooltip)`
  width: 80px;
`

const KnobTooltipBody = styled(TooltipBody)`
  color: var(--ELK-Sea-Foam-Green);
`

const KnobLabel = styled(H3)`
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  user-select: none;
  transition: 0.3s;
  width: 100%;
  text-align: center;
  color: ${({ $disabled }) =>
    $disabled ? 'var(--ELK-Dark-Green-Disabled)' : 'var(--ELK-White)'};
`

const LineAlongCircle = ({
  color = 'white',
  width = 90,
  radius = 100,
  startTurn = 0,
  endTurn = 0.5,
  largeArc = false,
  clockwise = false,
}) => {
  const start = {
    x: Math.cos(startTurn * Math.PI * 2) * radius,
    y: Math.sin(startTurn * Math.PI * 2) * radius,
  }
  const end = {
    x: Math.cos(endTurn * Math.PI * 2) * radius,
    y: Math.sin(endTurn * Math.PI * 2) * radius,
  }
  return (
    <path
      fill="transparent"
      stroke={color}
      strokeWidth={width}
      d={`M ${start.x} ${-start.y} A ${radius} ${radius} 0 ${
        largeArc ? 1 : 0
      } ${clockwise ? 1 : 0} ${end.x} ${-end.y}`}
    />
  )
}

export const KnobControl = observer(
  ({
    value,
    onChange,
    onChanging,
    titleMin,
    panMode,
    stereoMode,
    titleMax,
    label,
    disabled = true,
    fgColor = 'var(--ELK-Dark-Grey)',
    disabledColor = 'var(--ELK-UI-Disabled)',
    defaultValue = null,
    tooltipTitle = 'pan',
    ...props
  }) => {
    const max = 1.0
    const min = 0.0
    const [dragging, setDragging] = useState(false)
    const [tooltipHover, setTooltipHover] = useState(false)
    const [{ tooltipShouldOpen, tooltipIsOpen }, dispatch] =
      useMixerTooltipState()
    const dragStartY = useRef(0)

    const dragMove = useCallback(
      (ev) => {
        if (dragging) {
          const { pageY, touches } = ev
          const pY = touches ? touches[0].pageY : pageY

          const interval = max - min
          const dragDelta = dragStartY.current - pY
          dragStartY.current = pY
          const newValue = Math.min(
            max,
            Math.max(min, value + (dragDelta / 100) * interval),
          )
          onChanging(newValue)
        }
      },
      [onChanging, dragging, value],
    )

    const dragEnd = useCallback(() => {
      if (dragging) {
        if(!tooltipHover) {
          dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: false })
        }
        setDragging(false)
      }
      onChange?.(value)
    }, [onChange, value, dispatch, dragging, tooltipHover])

    const dragStart = useCallback(
      (ev) => {
        ev.preventDefault()
        if (disabled) return

        dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })

        const { pageY, touches } = ev
        dragStartY.current = touches ? touches[0].pageY : pageY
        setDragging(true)
      },
      [disabled, dispatch],
    )

    useEffect(() => {
      document.addEventListener('touchmove', dragMove)
      document.addEventListener('mousemove', dragMove)
      document.addEventListener('touchend', dragEnd)
      document.addEventListener('mouseup', dragEnd)
      return () => {
        document.removeEventListener('touchmove', dragMove)
        document.removeEventListener('mousemove', dragMove)
        document.removeEventListener('touchend', dragEnd)
        document.removeEventListener('mouseup', dragEnd)
      }
    }, [dragging, dragMove, dragEnd])

    const resetDefaultValue = () => {
      if (typeof defaultValue === 'number') {
        onChanging?.(defaultValue)
        onChange?.(defaultValue)
      }
    }

    let tooltipValue = Math.floor(value * 100)
    if (panMode) {
      const currentValue = Number.parseFloat(value)
      const leftPan = currentValue < 0.5
      const rightPan = currentValue > 0.5
      const numberValue = Number.parseFloat(currentValue)
      let prefix = ''
      const displayValue = Math.abs((numberValue - 0.5) * 2 * 100)
      if (leftPan) {
        prefix = 'L'
      }
      if (rightPan) {
        prefix = 'R'
      }
      tooltipValue = `${prefix}${Number.parseFloat(displayValue).toFixed(0)}`
    }
    return (
      <Box relative flex justifyContent="center" {...props}>
        <TooltipContainer absolute bottom="100%">
          <KnobTooltip
            disabled={disabled}
            isOpen={tooltipIsOpen}
            shouldOpen={tooltipShouldOpen}
            onMouseEnter={() => {
              setTooltipHover(true)
              dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })
            }}
            onMouseLeave={() => {
              setTooltipHover(false)
              if (!dragging) {
                dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: false })
              }
            }}
          >
            <TooltipTitle>{tooltipTitle}</TooltipTitle>
            <KnobTooltipBody
              readFormatter={(currentValue) => {
                if (panMode) {
                  return currentValue
                }
                return `${currentValue}%`
              }}
              value={tooltipValue}
              onSave={(currentValue) => {
                if (panMode) {
                  let newValue = currentValue.toUpperCase()
                  const leftPan =
                    newValue.includes('L') || newValue.includes('-')
                  newValue = newValue.replace(/\D/g, '')
                  newValue = Number.parseInt(newValue, 10)
                  if (Number.isNaN(newValue)) {
                    return false
                  }
                  newValue = Math.max(0, Math.min(100, newValue))
                  if (leftPan) {
                    newValue = Math.abs(newValue / 100 / 2 - 0.5)
                  } else {
                    newValue = Math.min(1, newValue / 100 / 2 + 0.5)
                  }
                  onChanging(newValue)
                  return
                }

                const numberValue = Number.parseFloat(currentValue)
                if (Number.isNaN(numberValue)) {
                  return false
                }
                const newValue = Math.max(0, Math.min(100, numberValue))
                onChanging(newValue / 100)
              }}
            />
            <TooltipReset onClick={resetDefaultValue} />
          </KnobTooltip>
        </TooltipContainer>
        <Box flex column width="100%" alignItems="center">
          {panMode && <TopIndicator color={disabled ? fgColor : 'white'} />}
          <Box relative width="100px" flex justifyContent="center">
            <Label
              color={disabled ? fgColor : 'white'}
              textAlign="right"
              top={panMode ? '20px' : '50px'}
              left={panMode ? '-10px' : '0px'}
            >
              {titleMin}
            </Label>
            <Label
              color={disabled ? fgColor : 'white'}
              textAlign="left"
              top={panMode ? '20px' : '50px'}
              right={panMode ? '-10px' : '0px'}
            >
              {titleMax}
            </Label>
            <KnobHolder
              onTouchStart={dragStart}
              onMouseDown={dragStart}
              onMouseEnter={() =>
                dispatch({
                  action: TRIGGER_DELAYED_TOOLTIP,
                  payload: true,
                })
              }
              onMouseLeave={() => {
                if (!dragging) {
                  dispatch({
                    action: TRIGGER_DELAYED_TOOLTIP,
                    payload: false,
                  })
                }
              }}
              onClick={() =>
                dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })
              }
              onDoubleClick={resetDefaultValue}
              disabled={disabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-150 -150 300 300"
              >
                {panMode ? (
                  <>
                    <LineAlongCircle
                      startTurn={0}
                      endTurn={0.5}
                      color={fgColor}
                    />
                    <LineAlongCircle
                      startTurn={0.25}
                      endTurn={0.25 - (value - 0.5) / 2}
                      clockwise={value > 0.5}
                      color="var(--ELK-Sea-Foam-Green)"
                    />
                    <circle
                      style={{ transition: '1.0s' }}
                      r={100}
                      cx="0"
                      cy="0"
                      fill={disabled ? disabledColor : 'white'}
                    />
                    <g transform={`rotate(${((value - 0.5) / 2) * 360} 0 0)`}>
                      <line
                        x1="0"
                        y1="-40"
                        x2="0"
                        y2="-80"
                        stroke={disabled ? disabledColor : 'black'}
                        strokeWidth="13"
                        strokeLinecap="round"
                      />
                    </g>
                  </>
                ) : (
                  <>
                    <LineAlongCircle
                      startTurn={0.6}
                      endTurn={-0.1}
                      clockwise={true}
                      largeArc={true}
                      color={fgColor}
                    />
                    <LineAlongCircle
                      startTurn={0.6}
                      endTurn={0.6 - value * 0.7}
                      clockwise={true}
                      largeArc={value * 0.7 > 0.5}
                      color="var(--ELK-Sea-Foam-Green)"
                    />
                    <circle
                      style={{ transition: '0.3s' }}
                      r={100}
                      cx="0"
                      cy="0"
                      fill={disabled ? disabledColor : 'white'}
                    />
                    <g transform={`rotate(${(value - 0.5) * 360 * 0.7} 0 0)`}>
                      <line
                        x1="0"
                        y1="-40"
                        x2="0"
                        y2="-80"
                        stroke={disabled ? disabledColor : 'black'}
                        strokeWidth="13"
                        strokeLinecap="round"
                      />
                    </g>
                  </>
                )}
              </svg>
            </KnobHolder>
          </Box>
          <KnobLabel $disabled={disabled}>{label}</KnobLabel>
        </Box>
      </Box>
    )
  },
)
