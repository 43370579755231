import { observer } from 'mobx-react-lite'
import { Box } from '../../../ui/primitives'
import { useStore } from '../../../../store'
import { ELKDarkGreen, ELKDarkGreenDisabled } from '../../../../variables'
import { InputWidgetsWrapper } from './index'
import { PeakMeter } from '../../../pages/mixer/components/peak-meter'

function DesktopInputWidgets({ channelIndex, stereoLinking }) {
  const store = useStore()

  if (stereoLinking) {
    return (
      <InputWidgetsWrapper>
        <Box
          minHeight="110px"
          height="17vh"
          maxHeight="225px"
          flex
          row
          columnGap="6px"
        >
          {[0, 1].map((idx) => (
            <Box
              key={idx}
              width="17px"
              minHeight="110px"
              height="17vh"
              maxHeight="225px"
            >
              <PeakMeter
                bgColor={ELKDarkGreen}
                fgColor={ELKDarkGreenDisabled}
                getValue={() =>
                  store.mixer.preampMeters.get(`own/level_${idx}`)
                }
                getUpdatedAt={() =>
                  store.mixer.preampMeterUpdatedAt.get(`own/level_${idx}`)
                }
                disabled={false}
              />
            </Box>
          ))}
        </Box>
        <Box height="100px" />
      </InputWidgetsWrapper>
    )
  }

  return (
    <InputWidgetsWrapper>
      <Box width="17px" minHeight="110px" height="17vh" maxHeight="225px">
        <PeakMeter
          bgColor={ELKDarkGreen}
          fgColor={ELKDarkGreenDisabled}
          getValue={() =>
            store.mixer.preampMeters.get(`own/level_${channelIndex}`)
          }
          getUpdatedAt={() =>
            store.mixer.preampMeterUpdatedAt.get(`own/level_${channelIndex}`)
          }
          disabled={false}
        />
      </Box>
      <Box height="100px" />
    </InputWidgetsWrapper>
  )
}

export default observer(DesktopInputWidgets)
