import {
  ConsoleLogger,
  DefaultDeviceController
} from 'amazon-chime-sdk-js'
import { Box } from '../../ui/primitives'
import React, { useEffect, useRef } from 'react'
import Nameplate from './nameplate'
import { BottomContainer } from './video-overlays'
import VideoPlaceholder from './video-placeholder'
import { useStore } from '../../../store'
import { logger } from '../../../utils/logging'

const LocalVideo = () => {

  const store = useStore()
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return
    const domNode = ref.current
    const deviceLogger = new ConsoleLogger('VideoChatDeviceController')
    const deviceController = new DefaultDeviceController(deviceLogger)
    async function init() {
      try {
        const deviceList = await deviceController.listVideoInputDevices()
        if (deviceList.length === 0) return
        let selectedIndex = deviceList.findIndex((device) => {
          return device.deviceId === store.settings.videoChat.selectedVideoInputDeviceId
        })
        if (selectedIndex === -1) {
          selectedIndex = 0
        }
        await deviceController.startVideoInput(deviceList[selectedIndex].deviceId)
        deviceController.startVideoPreviewForVideoInput(domNode)
      } catch (e) {
        logger.error(e.message)
      }
    }
    init()
    return async () => {
      try {
        await deviceController.stopVideoInput()
        deviceController.stopVideoPreviewForVideoInput(domNode)
        deviceController.destroy()
      } catch (e) {
        logger.error(e.message)
      }
    }
  }, [ref, store.settings.videoChat.selectedVideoInputDeviceId])

  return (
    <video ref={ref} style={{width: '100%', height: '100%'}}/>
  )
}

export const VideoPreview = ({ enabled, user }) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', background: 'var(--Elk-Background-Grey)' }}>
      {enabled ? (
        <LocalVideo />
      ) : (
        <VideoPlaceholder user={user} />
      )}
      <BottomContainer>
        <Box />
        <Nameplate text={`${user?.name} (you)`} />
        <Box />
      </BottomContainer>
    </div>
  )
}
