import styled from 'styled-components'

const MessageBubble = styled.div`
  height: auto;
  padding: 10px;
  margin: 15px;
  border: black solid 2px;
  position: relative;
  border-radius: 5px;

  &:after {
    content: '';
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: #fff;
    bottom: -7px;
    left: 4px;
    border-top: black solid 2px;
    border-left: black solid 2px;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
}

  p {
    margin-bottom: 0;
    font-weight: 300;
    word-break: break-all;
      strong {
        font-weight: 700;
      }
  }
`

export { MessageBubble }