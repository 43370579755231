import styled, { css } from 'styled-components'
import { Box } from '../ui/primitives'
import { isDesktop, isSafari } from '../../utils/utils'
import { ELKBackgroundGrey } from '../../variables'

const Page = styled(Box)`
  background-color: var(--ELK-Background-Grey);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  overflow-x: hidden;
`

const MainContent = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`

const MasterFaderWrapper = styled.div`
  width: 76px;
  border-top: 3px solid ${ELKBackgroundGrey};
  border-bottom: 3px solid ${ELKBackgroundGrey};
  z-index: 1;
`

const videoSyncCss = css`
  border: 4px solid var(--ELK-Raspberry-Pink);
`

const Container = styled.div`
  display: flex;
  position: relative;
  flex-grow: ${(props) => (props.grow ? 1 : 0)};

  ${({ $videoSyncActive }) => $videoSyncActive && videoSyncCss};
`


const Section = styled(Box)`
  height: 100%;
  width: 100%;
  transform: translateX(${(props) => (props.expanded ? '0' : '105%')});
  ${({ $noScroll }) => !$noScroll && 'overflow: auto'};
  transition: ${isSafari() || isDesktop() ? 'unset' : 'transform 0.225s'};
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: ${(props) => (props.borderless ? 'unset' : `3px solid ${ELKBackgroundGrey}`)};
`

const ChatSection = styled.div`
  overflow: hidden;
  width: ${(props) => (props.expanded ? '30rem' : '0')};
  padding-left: ${(props) => (props.expanded ? '3px' : '0')};
  transition: width 0.225s;
`

export { Page, MainContent, MasterFaderWrapper, Container, Section, ChatSection }