import React from 'react'
import { observer } from 'mobx-react-lite'
import { CloseButton, Dialog, ModalButton } from './base'
import { openModal } from './overlays'
import { Box, Text, H1 } from '../ui/primitives'
import { withStore } from '../../store'
import { StyledLink } from '../pages/user-configuration/pages/betaJoin/style'

const ICEFailureDialog = observer(({ close }) => {
  return (
    <Dialog
      width="60rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)"/>

      <H1 fontSize="3.2rem" color="var(--ELK-Black)" textAlign="center" mb="2.5rem">Solutions</H1>
      <Text textAlign="center" mb="3rem" mt="3rem" color="var(--ELK-Black)" fontSize="1.8rem">Failed to set up an automatic connection.</Text>
      <Text textAlign="center" mb="3rem" mt="3rem" color="var(--ELK-Black)" fontSize="1.8rem">
        Please give it another try or&nbsp;
        <StyledLink
          href="https://help.elk.live/en/articles/5904260-start-new-session-with-manual-connection"
          target="_blank"
          rel="noreferrer"
        >
          <Text
            color="var(--ELK-Black)" fontSize="1.8rem"
          >
            set up a manual connection
          </Text>

        </StyledLink>.
      </Text>

      <Box mt="2rem" maxWidth="32rem" mx="auto" flex column rowGap="2rem">
        <ModalButton primary height="3.8rem" width="100%" fontSize="1.8rem" color="#1c1c1c" py="10px" onClick={() => {close()}}>Ok, thanks for letting me know!</ModalButton>
      </Box>
    </Dialog>
  )
})

export function showIceFailureSolutionsDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <ICEFailureDialog close={close}/>), {
      autoDismissable: true,
    }
  )
}
