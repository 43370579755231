import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../store'
import { Box } from '../ui/primitives'
import { Footer } from '../layout/footer'
import { Header } from '../layout/header'
import { Mixer } from './mixer'
import { TrackControlSlim } from './mixer/components/track-control-slim'
import { ErrorBoundary } from '../../utils/error-boundaries'
import { LobbyView } from './lobby-view'
import { VideoOutput } from './video/video-output'
import { Chat } from '../features/chat'
import VideoSyncBanner from './video/video-sync-banner'
import { useLocalVideo } from 'amazon-chime-sdk-component-library-react'
import { VideoPreview } from '../video-chat/components/video-preview'

import {
  ACTIVE_VIEW_STATE_MIXER,
  ACTIVE_VIEW_STATE_VIDEO,
} from '../../store/active-view-states'
import { Page, MainContent, MasterFaderWrapper, Container, Section, ChatSection } from './styles'

export const PageContainer = observer(() => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo()
  const [previewVideoEnabled, setPreviewVideoEnabled] = useState(false)

  const store = useStore()

  useEffect(() => {
    store.startEventLoop()
    return () => {
      store.endEventLoop()
    }
  }, [store])

  const keyHandler = (e) => {
    if (e.ctrlKey && e.altKey && e.which === 89) {
      // Ctrl + Alt + y
      store.isDeveloperMode = !store.isDeveloperMode
    }
    if (e.ctrlKey && e.altKey && e.which === 76) {
      store.AWSLogout()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  })

  const lobbyOpen = true
  const videoOpen =
    store.activeViewState === ACTIVE_VIEW_STATE_VIDEO ||
    store.activeViewState === ACTIVE_VIEW_STATE_MIXER
  const mixerOpen = store.activeViewState === ACTIVE_VIEW_STATE_MIXER

  return (
    <Page>
      <Header />
      <MainContent>
        <Container
          grow
          $videoSyncActive={store.session?.videoSync.active}
        >
          <Box>
            <VideoSyncBanner />
            <Section expanded={lobbyOpen} $noScroll>
              <LobbyView />
            </Section>
            <Section expanded={videoOpen}>
              {store.sessionMode ? (
                <ErrorBoundary
                  error_message={'Something went wrong with the video client.'}
                >
                  <VideoOutput className="video-output" />
                </ErrorBoundary>
              ) : (
                <VideoPreview
                  enabled={previewVideoEnabled}
                  user={store.users.get(store.currentUserId)}
                />
              )}
            </Section>
            <Section expanded={mixerOpen}>
              <Mixer />
            </Section>
          </Box>
        </Container>
        <Container grow={false}>
          <ChatSection expanded={store.showChatView}>
            <Chat messages={store.sessionChatMessages} sessionPrivate={true} />
          </ChatSection>
        </Container>
        <MasterFaderWrapper>
          {store.mixer && (
            <TrackControlSlim
              disabled={!store.board}
              volume={store.mixer.master.volume}
              onVolumeChange={(value) => {
                store.mixer.master.volume = value
              }}
              onVolumeChangeStop={() => {}}
              muted={store.mixer.panicMode || store.mixer.master.muted}
              onMutedChange={(value) => {
                if (store.mixer.panicMode) {
                  store.mixer.panicModeShouldFlash = true
                  return
                }
                store.mixer.master.muted = value
              }}
              getPeakMeterValues={() => [
                store.mixer.peakMeters.get('master/level_0') ?? 0,
                store.mixer.peakMeters.get('master/level_1') ?? 0,
              ]}
              getPeakMeterUpdatedAt={() => [
                store.mixer.peakMeterUpdatedAt.get('master/level_0') ?? 0,
                store.mixer.peakMeterUpdatedAt.get('master/level_1') ?? 0,
              ]}
            >
            </TrackControlSlim>
          )}
        </MasterFaderWrapper>
      </MainContent>
      <Footer
        videoEnabled={store.sessionMode ? isVideoEnabled : previewVideoEnabled}
        onToggleVideo={
          store.sessionMode
            ? toggleVideo
            : () => {
                setPreviewVideoEnabled(!previewVideoEnabled)
              }
        }
      />
    </Page>
  )
})
