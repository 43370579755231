import { createGlobalStyle } from 'styled-components'
import { generateMedia } from 'styled-media-query'

export const ELKBlack                 = '#1C1C1C'
export const ELKCandyTangerine        = '#E6410D'
export const ELKDarkGreen             = '#2F4242'
export const ELKDarkGreenDisabled     = '#212E2E'
export const ELKDarkGrey              = '#4E4E4E'
export const ELKBackgroundGrey        = '#3F3F3F'
export const ELKMidnightGrey          = '#363636'
export const ELKHighContrastRed       = '#E6410D'
export const ELKHighContrastYellow    = '#F2C700'
export const ELKHighPrioGreenEnabled  = '#00EB96'
export const ELKHighPrioRedEnabled    = '#E6410D'
export const ELKHighPrioYellowEnabled = '#F2C700'
export const ELKSeaFoamGreen          = '#00EB96'
export const ELKStandardEnabled       = '#6FB6B6'
export const ELKWhite                 = '#FFFFFF'
export const ELKUIDisabled            = '#666666'
export const ELKRaspberryPink         = '#E25098'
export const ELKOlympicWhite          = '#E5E1D2'
export const ELKDaphneBlue            = '#75B7B2'
export const ELKBetaGrey              = '#383838'
export const ELKIntensePink           = '#FF0099'
export const ElkDisabledGrey          = '#8B8B8B'
export const ElkOfflineGrey            = '#C8C8C8'
export const FacebookBlue             = '#4267B2'


export const Variables = createGlobalStyle`
  :root {
    --color-grey-1: #181818;
    --color-grey-2: #2a2a2a;
    --color-grey-3: #454545;
    --color-light-grey: #8f8f8f;
    --color-blue: #0034f5;
    --color-red: #e33f1b;
    --color-green: #9CF954;
    --color-yellow: #F5BB00;

    /* New UI colors */

    /* Button standard */
    --ELK-Standard-Enabled: ${ELKStandardEnabled};
    --ELK-Standard-Hover: #689592;
    --ELK-Standard-Pressed: #315C5A;
    --ELK-Standard-Inactive: ${ELKOlympicWhite};
    --ELK-Text-Standard-Enabled: #FFFFFF;
    --ELK-Text-Standard-Hover: #FFFFFF;
    --ELK-Text-Standard-Pressed: #FFFFFF;
    --ELK-Text-Standard-Inactive: #8F8C80;
    --ELK-Footer-Disabled: #706D63;

    /* Button yellow */
    --ELK-High-Prio-Yellow-Enabled: ${ELKHighPrioYellowEnabled};
    --ELK-High-Prio-Yellow-Hover: #C19F00;
    --ELK-High-Prio-Yellow-Pressed: #907700;
    --ELK-Text-High-Prio-Yellow-Enabled: #FFFFFF;
    --ELK-Text-High-Prio-Yellow-Hover: #FFFFFF;
    --ELK-Text-High-Prio-Yellow-Pressed: #FFFFFF;

    /* Button red */
    --ELK-High-Prio-Red-Enabled: ${ELKHighPrioRedEnabled};
    --ELK-High-Prio-Red-Hover: #B72F05;
    --ELK-High-Prio-Red-Pressed: #872304;
    --ELK-Text-High-Prio-Red-Enabled: #FFFFFF;
    --ELK-Text-High-Prio-Red-Hover: #FFFFFF;
    --ELK-Text-High-Prio-Red-Selected: #FFFFFF;

    /* Button green */
    --ELK-High-Prio-Green-Enabled: ${ELKHighPrioGreenEnabled};
    --ELK-High-Prio-Green-Hover: #00bd78;
    --ELK-High-Prio-Green-Pressed: #008f5b;
    --ELK-Text-High-Prio-Red-Enabled: #FFFFFF;
    --ELK-Text-High-Prio-Red-Hover: #FFFFFF;
    --ELK-Text-High-Prio-Red-Selected: #FFFFFF;

    /* List */
    --ELK-List-White: #F8F8F8;
    --ELK-List-Grey: #EEEEEE;

    /* Base */
    --ELK-Black: ${ELKBlack};
    --ELK-Border: #C4C4C4;
    --ELK-Dark-Green-Disabled: ${ELKDarkGreenDisabled};
    --ELK-Bridge-Border: #CCC9BD;
    --ELK-Candy-Tangerine: ${ELKCandyTangerine};
    --ELK-Daphne-Blue: #75B7B2;
    --ELK-Dark-Green: ${ELKDarkGreen};
    --ELK-Dark-Grey: ${ELKDarkGrey};
    --ELK-Dark-Background-Grey: ${ELKBackgroundGrey};
    --ELK-Midnight-Grey: ${ELKMidnightGrey};
    --ELK-UI-Disabled: ${ELKUIDisabled};
    --ELK-High-Contrast-Blue: #00C2FF;
    --ELK-High-Contrast-Red: ${ELKHighContrastRed};
    --ELK-High-Contrast-Yellow: ${ELKHighContrastYellow};
    --ELK-Inactive-Olympic-White: #E6E1D2;
    --ELK-Light-Grey: #9E9E9E;
    --ELK-Link-Active: #09C4FF;
    --ELK-Placeholder: #848484;
    --ELK-Sea-Foam-Green: ${ELKSeaFoamGreen};
    --ELK-Status-Green: #00C88C;
    --ELK-Text-Header-Main: #1C1C1C;
    --ELK-Text-Header-Standard: #98C6C3;
    --ELK-Text-Inactive: #9E9E9E;
    --ELK-White: ${ELKWhite};
    --ELK-Olympic-White: ${ELKOlympicWhite};
    --ELK-Raspberry-Pink: ${ELKRaspberryPink};
    --ELK-Daphne-Blue: ${ELKDaphneBlue};
    --Elk-Background-Grey: ${ELKBackgroundGrey};
    
    --ELK-Beta-Grey: ${ELKBetaGrey};
    --ELK-Intense-Pink: ${ELKIntensePink};
    --ELK-Offline-Grey: ${ElkOfflineGrey};
    --ELK-Disabled-Grey: ${ElkDisabledGrey};
    --Facebook-Blue: ${FacebookBlue};
  }
`

export const mediaQueries = generateMedia({
    xl: '1440px',
    large: '1054px',
    medium: '768px',
    small: '568px',
    xs: '320px'
  }
);