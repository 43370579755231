import React from 'react'
import { IconWrapper } from '../../../cards/card-components'
import { ArrowIcon, CloseIcon, NonFriendIcon } from '../../../ui/icons'
import { Text } from '../../../ui/primitives'
import { StatusHeader, StatusInfoContainer, CloseContainer } from '../styles'

export const StatusInfoHeader = ({ status, close }) => {
  if(status === 'Requested' || status === 'Pending') {
    const outgoing = status === 'Requested'
    const incoming = status === 'Pending'
    return (
      <StatusHeader
        bgColor="var(--ELK-Intense-Pink)"
      >
        <StatusInfoContainer>
          <IconWrapper
            style={incoming ? {transform: 'rotate(180deg)'} : {}}
          >
            <ArrowIcon width={24} />
          </IconWrapper>
          <Text>{outgoing ? 'Outgoing' : 'Incoming'} request!</Text>
        </StatusInfoContainer>
        <CloseIcon size={12} style={{cursor: 'pointer'}} onClick={() => close()}/>
      </StatusHeader>
    )
  }

  if(status === 'Confirmed'){
    return (
      <CloseContainer>
        <CloseIcon size={12} style={{cursor: 'pointer'}} onClick={() => close()}/>
      </CloseContainer>
    )
  }

  return (
    <StatusHeader>
      <StatusInfoContainer>
        <NonFriendIcon width={20} />
        <Text>Not your friend yet</Text>
      </StatusInfoContainer>
      <CloseIcon size={12} style={{cursor: 'pointer'}} onClick={() => close()}/>
    </StatusHeader>
  )
}