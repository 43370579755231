import { createLogger, format, transports } from 'winston'
import ArrayTransport from 'winston-array-transport';
import { getCurrentVersion, isDesktop } from './utils'
import UAParser from 'ua-parser-js'

const logs = [];
const arrayTransport  = new ArrayTransport({ array: logs, json: true, level: 'info' });

const parser = new UAParser(navigator.userAgent)
const { browser, device, os } = parser.getResult();

const winstonLogger = createLogger({
  level: 'info',
  format: format.combine(
    format.timestamp({
      format: 'YYYY-MM-DD HH:mm:ss'
    }),
    format.errors({ stack: true }),
    format.splat(),
    format.json()
  ),
  defaultMeta: {
    service: 'elk-live-webapp',
    version: getCurrentVersion().version,
    env: getCurrentVersion().env,
    desktop: isDesktop(),
    user_agent: {
      browser, device, os
    }
  },
  transports: [
    new transports.Console({
      format: format.combine(
        format.colorize(),
        format.simple()
      )
    }), arrayTransport]
})

const getArrayLogs = () => {
  return logs
}

export { winstonLogger, getArrayLogs }