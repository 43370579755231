
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'
import { CloseIcon } from './icons'
import { Box } from './primitives'

const TooltipText = styled(Box)`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.4;
`;

export const TooltipTitle = styled(TooltipText)`
  font-size: 2rem;
`;

export const TooltipDescription = styled(TooltipText)`
  font-size: 1.3rem;
`;

const Container = styled.div`
  background-color: ${({ $bgColor }) => $bgColor ?? 'var(--ELK-White)'};
  color: ${({ $color }) => $color ?? 'var(--ELK-Black)'};
  position: relative;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);

  ${({ $direction }) => $direction === 'up' && css`
    bottom: 100%;

    &:after {
      position: absolute;
      content: '';
      left: calc(50% - 16px);
      top: 100%;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid ${({ $bgColor }) => $bgColor ?? 'var(--ELK-White)'};
    }
  `}

  ${({ $direction }) => $direction === 'down' && css`
    top: 100%;

    &:before {
      position: absolute;
      content: '';
      left: calc(50% - 16px);
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid ${({ $bgColor }) => $bgColor ?? 'var(--ELK-White)'};
    }
  `}
`

const CloseButton = styled.button`
  float: right;
`

export const TooltipBoundary = observer(({
  children,
  tooltip,
}) => {
  const ref = useRef()

  let tooltipXPos;
  if(ref?.current) {
    tooltipXPos = ref.current.getBoundingClientRect().x + (ref.current.getBoundingClientRect().width / 2)
  }

  return (
    <Box ref={ref}>
      <Box position="absolute" style={{ bottom: '10.2rem', left: tooltipXPos, transform: 'translate(-50%)' }}>
        {tooltip}
      </Box>
      {children}
    </Box>
  )
})

export const Tooltip = React.forwardRef(({
  bgColor,
  color,
  isOpen,
  toggle,
  direction = 'up',
  children,
  ...rest
}, ref) => {


  if (!isOpen) {
    return null
  }

  return (
    <Container
      $bgColor={bgColor}
      $color={color}
      $direction={direction}
      ref={ref}
      {...rest}
    >
      {toggle && (
        <CloseButton onClick={toggle}>
          <CloseIcon size={15} />
        </CloseButton>
      )}
      {children}
    </Container>
  )
})

export default Tooltip;
