import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'

import { useStore } from '../../store'
import { Box } from '../ui/primitives'
import { APIClient } from '../../api/client'
import { showHelpDialog } from '../dialogs/help'
import { logger } from '../../utils/logging'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../store/board-connection-states'
import { PeakMeter } from '../pages/mixer/components/peak-meter'
import { TooltipBoundary, TooltipTitle, TooltipDescription } from '../ui/tooltip'

import {
  StopIcon,
  VideoOffIcon,
  VideoSyncIcon,
  QuestionIcon,
  AddPartnerIcon, BetaSupportIcon, VideoOnIcon
} from '../ui/icons'
import { showSetupSessionDialog } from '../dialogs/setup-session'
import { SESSION_PARTNER_STATE_WAITING } from '../../store/session-partner-states'
import { LOCAL_VIDEO_MODE } from '../../store/session'
import { showAddPartnerToSessionDialog } from '../dialogs/AddPartnerToSession'

import {
  CenterSection,
  RightSection,
  FooterElement,
  LeftSection,
  QuestionMarkButton,
  SquareButton,
  FooterButton,
  DurationInfo,
  Timer,
  FooterTooltip,
  VideoTooltip,
  NewSessionButton,
  MuteAllButton,
  PeakMeterWrapper,
  VideoSyncButton,
  HelpContainer,
  BorderlessFooterButton
} from './styles'
import { isBetaMode } from '../../utils/utils'
import { showTalkbackDialog } from '../dialogs/TalkbackDialog'

const toTimer = (duration) => {
  const seconds = Math.round(duration)
  const pad = (num) => (num < 10 ? `0${num}` : `${num}`)
  const ss = pad(seconds % 60)
  const mm = pad(Math.floor(seconds / 60) % 60)
  const hh = pad(Math.floor(seconds / 60 / 60))
  return { hh, mm, ss }
}

export const Footer = observer(function Footer ({ videoEnabled, onToggleVideo }) {
  const audioVideo = useAudioVideo()
  const store = useStore()
  const boardIsConnected = store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED
  const [muted, setMuted] = useState(audioVideo?.realtimeIsLocalAudioMuted() ?? true)
  const [duration, setDuration] = useState(store.session?.duration)
  const [showKillSwitchTooltip, setShowKillSwitchTooltip] = useState(false)
  const [showMicTooltip, setShowMicTooltip] = useState(false)
  const [showVideoTooltip, setShowVideoTooltip] = useState(false)
  const [addPartnerModalOpen, setAddPartnerModalOpen] = useState(false)
  const [talkbackInputRefresh, setTalkbackInputRefresh] = useState(false)
  const [showTalkbackTooltip, setShowTalkbackTooltip] = useState(false)
  const localVideoMode = store.session?.localVideoMode

  useEffect(() => {
    const showTooltip = videoEnabled && localVideoMode === LOCAL_VIDEO_MODE.INFO_BAR
    setShowVideoTooltip(showTooltip)
  }, [localVideoMode, videoEnabled])

  useEffect(() => {
    if(!store.sessionMode){
      setTalkbackInputRefresh(false)
    }
  }, [store.sessionMode])

  useEffect(() => {
    const id = setInterval(() => {
      setDuration(Math.floor((Date.now() - store.session?.startTime) / 1000))
    }, 1000)
    return () => {
      clearInterval(id)
    }
  })

  useEffect(() => {
    if (!audioVideo) return
    async function init() {
      store.session.talkback.deviceList = await audioVideo.listAudioInputDevices()
    }

    const callback = (mutedValue) => {
     setMuted(mutedValue)
    }
    audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback)
    init()
    return () => {
      audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback)
    }
  // eslint-disable-next-line
  }, [audioVideo])

  useEffect(() => {
    if (store.mixer?.panicModeShouldFlash) {
      setTimeout(() => {
        store.mixer.panicModeShouldFlash = false
      }, 2200)
    }
  }, [store.mixer, store.mixer?.panicModeShouldFlash])

  const recoverTalkbackSourceIfAvailable = async () => {
    if(talkbackInputRefresh) return

    if (store.session.talkback.deviceList.length < 1) { return }

    let selectedAudioDevice = store.session.talkback.deviceList.find((device) =>
      device.deviceId === store.settings.videoChat.selectedAudioInputDeviceId)

    if (!selectedAudioDevice) {
      showTalkbackDialog({ store });
      return
    }

    await audioVideo.startAudioInput(selectedAudioDevice.deviceId)
    setTalkbackInputRefresh(true)
  }

  const timer = toTimer(duration)

  return (
    <FooterElement>
      <LeftSection>
        <HelpContainer id="step-4-support">
          {!isBetaMode() && (
            <QuestionMarkButton onClick={() => showHelpDialog({ store })}>
              <QuestionIcon size={18} />
            </QuestionMarkButton>
          )}
          {isBetaMode() && (
            <BorderlessFooterButton
              onClick={() => { showHelpDialog({ store })} }
            >
              <BetaSupportIcon size={44} />
              HELP!
            </BorderlessFooterButton>
          )}
        </HelpContainer>
        {store.videoSync.enabled && (
          <VideoSyncButton
            width='8rem'
            disabled={!store.sessionMode || store.session?.partnerIds.some((partnerId) => store.session?.partnerState.get(partnerId) === SESSION_PARTNER_STATE_WAITING)}
            $videoSyncActive={store.session?.videoSync.active}
            onClick={() => {
              store.session.videoSync.active = !store.session.videoSync.active
              store.session?.setVideoSyncMode(store.session.videoSync.active, store.session.videoSync.delay)
            }}
          >
            <VideoSyncIcon />
          </VideoSyncButton>
        )}
      </LeftSection>
      <CenterSection>
        <FooterButton
          disabled={!store.sessionMode || addPartnerModalOpen}
          width='52px'
          onClick={() => {
            setAddPartnerModalOpen(true)
            showAddPartnerToSessionDialog({
              store,
              partnerIds: store.session.partnerIds,
              afterClose: () => {
                setAddPartnerModalOpen(false)
              } })
          }}
        >
          <AddPartnerIcon />
        </FooterButton>
        <TooltipBoundary
          tooltip={
            <FooterTooltip
              bgColor='var(--ELK-High-Prio-Red-Enabled)'
              color='var(--ELK-White)'
              toggle={() => { setShowKillSwitchTooltip(false) }}
              isOpen={showKillSwitchTooltip}
            >
              <TooltipTitle>the sound of silence.</TooltipTitle>
              <TooltipDescription>
                You have now muted EVERYTHING!<br />
                No audio is sent, no audio is received.<br />
                Click again to unmute.<br />
              </TooltipDescription>
            </FooterTooltip>
            }
        >
          <MuteAllButton
            className={`${store.mixer?.panicMode ? 'on' : 'off'} ${store.mixer?.panicModeShouldFlash ? 'flashing' : ''} ${boardIsConnected ? '' : 'disabled'}`}
            onClick={() => {
              if (!boardIsConnected) {
                return
              }
              if (!store.mixer.panicMode) {
                setShowKillSwitchTooltip(true)
              } else {
                setShowKillSwitchTooltip(false)
              }
              APIClient.toggle_panic_mode()
            }}
          >
            <span />
          </MuteAllButton>
        </TooltipBoundary>
        <TooltipBoundary
          tooltip={(
            <FooterTooltip
              isOpen={muted && showMicTooltip && store.sessionMode}
            >
              <TooltipDescription>
                Keep pressed to use<br />
                computer mic to talk<br />
                <Box mt='1rem'>Shift+click to latch</Box>
              </TooltipDescription>
            </FooterTooltip>
            )}
        >
          <TooltipBoundary
            tooltip={(
              <FooterTooltip
                isOpen={showTalkbackTooltip && !store.sessionMode && boardIsConnected}
              >
                <TooltipDescription>
                 Only available during a session
                </TooltipDescription>
              </FooterTooltip>
            )}
          >
          <FooterButton
            onMouseOver={() => {
              if (boardIsConnected) {
                setShowMicTooltip(true)
              }
              setShowTalkbackTooltip(true)
            }}
            onMouseOut={() => {
              setShowMicTooltip(false)
              setShowTalkbackTooltip(false)
            }}
            width='12rem'
            className={`${muted ? 'off' : 'on'} ${store.sessionMode ? '' : 'disabled'}`}
            onMouseDown={async (e) => {
              if(store.settings.videoChat.selectedAudioInputDeviceId === null && store.sessionMode) {
                showTalkbackDialog({ store });
                return
              }
              if (audioVideo) {
                await recoverTalkbackSourceIfAvailable()
                audioVideo.realtimeUnmuteLocalAudio()
              }
              const onMouseUp = () => {
                if (audioVideo) {
                  audioVideo.realtimeMuteLocalAudio()
                }
                window.removeEventListener('mouseup', onMouseUp)
                window.removeEventListener('pointerup', onMouseUp)
                window.removeEventListener('touchend', onMouseUp)
              }
              if (!e.shiftKey) {
                window.addEventListener('mouseup', onMouseUp)
                window.addEventListener('pointerup', onMouseUp)
                window.addEventListener('touchend', onMouseUp)
              }
            }}
          >
            talkback
          </FooterButton>
          </TooltipBoundary>
        </TooltipBoundary>
        <TooltipBoundary
          tooltip={
            <VideoTooltip
              bgColor='var(--ELK-Sea-Foam-Green)'
              color='var(--ELK-Black)'
              isOpen={videoEnabled && showVideoTooltip}
              toggle={() => { setShowVideoTooltip(false) }}
            >
              <TooltipDescription>
                You're still sending your video to the others
              </TooltipDescription>
            </VideoTooltip>
            }
        >
          <SquareButton
            className={`${videoEnabled ? 'on' : 'off'} ${boardIsConnected ? '' : 'disabled'}`}
            onClick={() => {
              if (!boardIsConnected) return
              onToggleVideo()
            }}
          >
            {videoEnabled ? <VideoOnIcon size={30} /> : <VideoOffIcon size={35} />}
          </SquareButton>
        </TooltipBoundary>
        <Box width='18.5rem'>
          {!store.sessionMode && (
            <NewSessionButton
              id="step-3-session"
              className={`${boardIsConnected ? '' : 'disabled'}`}
              onClick={() => {
                if (boardIsConnected) {
                  showSetupSessionDialog({ store, partnerIds: [] })
                }
              }}
            >
              start session!
            </NewSessionButton>
          )}
          {store.sessionMode && (
            <Box display='flex' columnGap='1rem'>
              <SquareButton
                className='stop'
                tooltip='leave session'
                onClick={async () => {
                  logger.info('User invoked store.leaveSession()')
                  try {
                    await store.leaveSession()
                  } catch (err) {
                    store.showSnackBar({
                      heading: 'Could not leave the session',
                      content: 'We are experiencing server issues. Please contact support',
                      level: 'error',
                      duration: 5000
                    })
                  }
                }}
              >
                <StopIcon />
              </SquareButton>
              <DurationInfo>
                <Timer>
                  {timer.hh}:{timer.mm}:{timer.ss}
                </Timer>
              </DurationInfo>
            </Box>
          )}
        </Box>
      </CenterSection>
      <RightSection>
        <FooterButton
          id="step-1-soundcheck"
          width='14rem'
          className={`${store.soundcheckMode ? 'on' : 'off'} ${boardIsConnected ? '' : 'disabled'}`}
          onClick={() => {
            if (!boardIsConnected) {
              return
            }
            store.toggleSoundcheck()
          }}
        >
          soundcheck
        </FooterButton>
        <PeakMeterWrapper>
          {[0, 1].map((idx) => (
            <Box key={idx} width='2.1rem' height='5.2rem'>
              <PeakMeter
                getValue={() => store.mixer?.preampMeters.get(`own/level_${idx}`)}
                markerHeight={3}
                markerSpacing={3}
                getUpdatedAt={() =>
                  store.mixer?.preampMeterUpdatedAt.get(`own/level_${idx}`)}
                errorLimit={Infinity}
                disabled={false}
              />
            </Box>
          ))}
        </PeakMeterWrapper>
      </RightSection>
    </FooterElement>
  )
}
)
