import React from 'react'
import { Box, H3 } from '../ui/primitives'
import { observer } from 'mobx-react-lite'
import { ProfilePicture } from '../ui/profile-picture'
import {
  CardList,
  CardListItem, ClickableCardContent,
} from './card-components'
import { showUserProfileModal } from '../dialogs/PeopleProfile'
import { useStore } from '../../store'

export const NonFriendCard = observer(({ user }) => {
  const store = useStore()
  return (
    <>
      <ClickableCardContent onClick={() => showUserProfileModal({ store, user })}>
        <ProfilePicture user={user} nonFriend />
      </ClickableCardContent>
      <CardList>
        <CardListItem px="2.7rem">
          <H3 color="#fff">{user?.display_name}</H3>
            <Box />
        </CardListItem>
      </CardList>
    </>
  )
})