import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Box, H3 } from '../../../ui/primitives'
import { useStore } from '../../../../store'
import { ELKDarkGreen, ELKDarkGreenDisabled } from '../../../../variables'
import { InputWidgetsWrapper } from './index'
import { PeakMeter } from '../../../pages/mixer/components/peak-meter'
import { KnobControl } from '../../../pages/mixer/components/knob-control'

const LabelSmall = styled(H3)`
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  user-select: none;
  transition: 0.3s;
  width: 100%;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? 'var(--ELK-Dark-Green-Disabled)' : 'var(--ELK-White)'};
`

const BridgeInputWidgets = observer(function BridgeInputWidgets({
  channelIndex,
  stereoLinking,
}) {
  const store = useStore()

  if (stereoLinking) {
    return (
      <InputWidgetsWrapper>
        <Box
          minHeight="110px"
          height="17vh"
          maxHeight="225px"
          flex
          row
          columnGap="6px"
        >
          {[0, 1].map((idx) => (
            <Box
              key={idx}
              width="17px"
              minHeight="110px"
              height="17vh"
              maxHeight="225px"
            >
              <PeakMeter
                bgColor={ELKDarkGreen}
                fgColor={ELKDarkGreenDisabled}
                getValue={() =>
                  store.mixer.preampMeters.get(`own/level_${idx}`)
                }
                getUpdatedAt={() =>
                  store.mixer.preampMeterUpdatedAt.get(`own/level_${idx}`)
                }
                disabled={false}
              />
            </Box>
          ))}
        </Box>
        <Box>
          <KnobControl
            defaultValue={store.mixer.getDefaultGain(
              store.mixer.inputOptions[0],
            )}
            titleMin="0"
            titleMax="11"
            mt="18px"
            fgColor={'var(--ELK-Dark-Green-Disabled)'}
            disabledColor={'var(--ELK-Dark-Green-Disabled)'}
            onChanging={(value) => {
              store.mixer.inputGains[0] = value
              store.mixer.inputGains[1] = value
            }}
            tooltipTitle="gain"
            onChange={() => {
              store.settings.save()
            }}
            value={store.mixer.inputGains[0]}
            disabled={!store.mixer.hasGainControls(store.mixer.inputOptions[0])}
          />
          <LabelSmall
            disabled={!store.mixer.hasGainControls(store.mixer.inputOptions[0])}
          >
            gain
          </LabelSmall>
        </Box>
      </InputWidgetsWrapper>
    )
  }

  return (
    <InputWidgetsWrapper>
      <Box width="17px" minHeight="110px" height="17vh" maxHeight="225px">
        <PeakMeter
          bgColor={ELKDarkGreen}
          fgColor={ELKDarkGreenDisabled}
          getValue={() =>
            store.mixer.preampMeters.get(`own/level_${channelIndex}`)
          }
          getUpdatedAt={() =>
            store.mixer.preampMeterUpdatedAt.get(`own/level_${channelIndex}`)
          }
          disabled={false}
        />
      </Box>
      <Box>
        <KnobControl
          defaultValue={store.mixer.getDefaultGain(
            store.mixer.inputOptions[channelIndex],
          )}
          titleMin="0"
          titleMax="11"
          mt="18px"
          fgColor={'var(--ELK-Dark-Green-Disabled)'}
          disabledColor={'var(--ELK-Dark-Green-Disabled)'}
          tooltipTitle="gain"
          onChanging={(value) => {
            store.mixer.inputGains[channelIndex] = value
          }}
          onChange={() => {}}
          value={store.mixer.inputGains[channelIndex]}
          disabled={
            !store.mixer.hasGainControls(store.mixer.inputOptions[channelIndex])
          }
        />
        <LabelSmall
          disabled={
            !store.mixer.hasGainControls(store.mixer.inputOptions[channelIndex])
          }
        >
          gain
        </LabelSmall>
      </Box>
    </InputWidgetsWrapper>
  )
})

export default BridgeInputWidgets
