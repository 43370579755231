import { useObserver } from 'mobx-react-lite'
import { useStore } from '../store'

const useIsPrivatestudio = () => {
    const store = useStore()
    const env = store.env

    return useObserver(() => {
        const productMode = env.REACT_APP_PRODUCT_MODE
        const appUrl = store.websiteUrl || ''
        const isPrivatestudioMode = productMode === 'privatestudio';
        const usingPrivatestudioUrl = appUrl.includes('uat.elk.live');
        return isPrivatestudioMode || usingPrivatestudioUrl || false;
    })
}

export default useIsPrivatestudio;
