import React from 'react'
import styled from 'styled-components'
import { H2 } from '../../ui/primitives'

const StyledText = styled(H2)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
`

const Nameplate = ({ text }) => {
  return <StyledText>{text}</StyledText>
}

export default Nameplate
