import React, { useRef, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { useStore } from '../../../../store'
import { ACTIVE_VIEW_STATE_MIXER } from '../../../../store/active-view-states'
import {
  MixerTooltip,
  Title as TooltipTitle,
  Body as TooltipBody,
  STATE_ACTIONS,
  useMixerTooltipState,
} from './mixer-tooltip'
import Portal from './portal'
import { Box } from '../../../ui/primitives'

import { ELKBlack, ELKStandardEnabled, ELKDarkGrey } from '../../../../variables'

const { FORCE_TOOLTIP_STATE, TRIGGER_DELAYED_TOOLTIP } = STATE_ACTIONS

const TOOLTIP_HEIGHT = 90;
const Handle = styled(Box)`
  width: 100%;
  height: 30px;
  background-color: ${({ $disabled }) =>
    $disabled ? 'var(--ELK-UI-Disabled)' : 'var(--ELK-White)'};
`

const FaderGraphic = observer(({ value, disabled }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    let height = 0
    let width = 0

    /* This line is important since we base the canvas height and width on the parent element
     * If we don't have this line the parent element height and width will depend on the canvas
     * and vice versa, leading to potential bugs */
    canvas.style.position = 'absolute'
    const context = canvas.getContext('2d')
    const drawFrame = (v) => {
      const barWidth = Math.round(width * 0.2)
      const barOffset = Math.round((width - barWidth) / 2)
      const valueHeight = Math.round(height * (1 - (disabled ? 0 : value)))
      // fill background
      context.fillStyle = ELKBlack
      context.fillRect(0, 0, width, height)

      // Bar (below handle)
      context.fillStyle = ELKStandardEnabled
      context.fillRect(barOffset, 0, barWidth, height)

      // Bar (above handle)
      context.fillStyle = ELKDarkGrey
      context.fillRect(barOffset, 0, barWidth, valueHeight)
    }
    const updateWindowSize = () => {
      /* Wrapped in requestAnimationFrame to get correct values for rect */
      requestAnimationFrame(() => {
        const rect = context.canvas.parentElement.getBoundingClientRect()
        context.canvas.width = rect.width
        context.canvas.height = rect.height
        height = context.canvas.height
        width = context.canvas.width
        drawFrame(value)
      })
    }
    updateWindowSize()
    window.addEventListener('resize', updateWindowSize)
    return () => {
      window.removeEventListener('resize', updateWindowSize)
    }
  }, [canvasRef, value, disabled])

  return <canvas ref={canvasRef} />
})

export const Fader = observer(function Fader({
  value,
  onChange,
  disabled = true,
}) {
  const [tooltipCoords, setTooltipCoords] = useState({ top: -100, left: -100 })
  const [dragging, setDragging] = useState()
  const [handleBottomOffset, setHandleBottomOffset] = useState(0)
  const [{ tooltipShouldOpen, tooltipIsOpen }, dispatch] =
    useMixerTooltipState()
  const store = useStore()
  const wrapperRef = useRef()
  const handleRef = useRef()
  const tooltipRef = useRef()

  useEffect(() => {
    dispatch({ action: FORCE_TOOLTIP_STATE, payload: false })
  }, [store.showBridgeSettingsView, dispatch])

  const updateTooltipCoords = useCallback(() => {
    const handleRect = handleRef?.current?.getBoundingClientRect()
    const handleXPos = handleRect?.x
    const handleYPos = handleRect?.y
    setTooltipCoords({
      left: handleXPos,
      top: handleYPos - TOOLTIP_HEIGHT,
    })
  }, [])

  // Should not be needed if this is unmounted with routing
  useEffect(() => {
    if (store.activeViewState !== ACTIVE_VIEW_STATE_MIXER) {
      dispatch({ action: FORCE_TOOLTIP_STATE, payload: false })
    }
  }, [store.activeViewState, dispatch])

  const updateValue = useCallback(
    (ev) => {
      const rect = wrapperRef.current.getBoundingClientRect()
      const { pageY, touches } = ev
      const mouseY = touches ? touches[0].pageY : pageY
      onChange(1 - Math.max(0, Math.min(1, (mouseY - rect.top) / rect.height)))
    },
    [onChange],
  )

  const dragMove = useCallback(
    (ev) => {
      ev.preventDefault()
      updateValue(ev)
      updateTooltipCoords()
    },
    [updateTooltipCoords, updateValue],
  )

  const dragEnd = useCallback(
    (ev) => {
      ev.preventDefault()
      dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: false })
      setDragging(false)
      document.removeEventListener('mouseup', dragEnd)
      document.removeEventListener('mousemove', dragMove)
      document.removeEventListener('touchend', dragEnd)
      document.removeEventListener('touchmove', dragMove)
    },
    [dispatch, dragMove],
  )

  const dragStart = useCallback(
    (ev) => {
      ev.preventDefault()
      updateValue(ev)
      dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: true })
      setDragging(true)
      document.addEventListener('mouseup', dragEnd)
      document.addEventListener('mousemove', dragMove)
      document.addEventListener('touchend', dragEnd)
      document.addEventListener('touchmove', dragMove)
    },
    [dispatch, updateValue, dragMove, dragEnd],
  )

  useEffect(() => {
    function recalculatePositions(ev) {
      // Get the correct values for height and width
      requestAnimationFrame(() => {
        const wrapperHeight = wrapperRef?.current?.offsetHeight ?? 0
        const handleHeight = handleRef?.current?.offsetHeight ?? 0
        setHandleBottomOffset(
          Math.max(0, value * wrapperHeight - value * handleHeight),
        )
        if (tooltipShouldOpen || tooltipIsOpen) {
          updateTooltipCoords()
        }
      })
    }

    recalculatePositions()

    window.addEventListener('resize', recalculatePositions)
    return () => {
      window.removeEventListener('resize', recalculatePositions)
    }
  }, [value, updateTooltipCoords, tooltipShouldOpen, tooltipIsOpen])
  return (
    <Box
      relative
      ref={wrapperRef}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      height="100%"
      width="100%"
      onClick={updateValue}
    >
      <Portal>
        <Box
          absolute
          style={{
            ...tooltipCoords,
            width: handleRef?.current?.offsetWidth,
          }}
          height={`${TOOLTIP_HEIGHT}px`}
          ref={tooltipRef}
        >
          <MixerTooltip
            shouldOpen={tooltipShouldOpen}
            isOpen={tooltipIsOpen}
            disabled={disabled}
            onClick={(ev) => ev.stopPropagation()}
            onMouseEnter={() => {
              dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })
            }}
            onMouseLeave={() => {
              if (!dragging) {
                dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: false })
              }
            }}
          >
            <TooltipTitle>volume</TooltipTitle>
            <TooltipBody
              value={Math.floor(value * 100)}
              readFormatter={(tooltipValue) => `${tooltipValue}%`}
              onSave={(currentValue) => {
                const numberValue = Number.parseInt(currentValue, 10)
                if (Number.isNaN(numberValue)) {
                  return false
                }
                const newValue = Math.max(0, Math.min(100, numberValue))
                onChange(newValue / 100)
              }}
            />
          </MixerTooltip>
        </Box>
      </Portal>
      <Box
        absolute
        width="100%"
        zIndex="1"
        style={{ bottom: disabled ? 0 : handleBottomOffset }}
      >
        <Handle
          $disabled={disabled}
          ref={handleRef}
          onTouchStart={dragStart}
          onMouseDown={dragStart}
          onMouseEnter={() => {
            updateTooltipCoords()
            dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: true })
          }}
          onMouseLeave={() => {
            if (!dragging) {
              dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: false })
            }
          }}
          onClick={() =>
            dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })
          }
        />
      </Box>
      <FaderGraphic value={value} disabled={disabled} />
    </Box>
  )
})
