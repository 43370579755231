import React from 'react'
import styled from 'styled-components'
import { ProfilePicture } from '../../../ui/profile-picture'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'

const StyledPictureGrid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  ${(props) => (props.amount === 2) && `
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
  `}

  ${(props) => (props.amount === 3) && `
    grid-template-columns: repeat(2, 1fr);

    > div:nth-child(2) {
      grid-row-start: span 2;
    }
  `}

  ${(props) => (props.amount === 4) && `
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  `}

  ${(props) => (props.amount === 5) && `
    grid-template-columns: repeat(12, 1fr);

    > div:nth-child(1),
    > div:nth-child(2) {
      grid-column: span 6;
    }
    
    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
      grid-column: span 4;
    }
  `}

  ${(props) => (props.amount === 6) && `
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  `}

  ${(props) => (props.amount > 6) && `
    grid-template-columns: repeat(3, 1fr);
  `}
`

export const PictureGrid = observer(({ partnerIds }) => {

  const store = useStore()

  return (
    <StyledPictureGrid amount={partnerIds.length + 1}>
      <ProfilePicture user={store.users.get(store.currentUserId)} />
      {partnerIds.map((id) => (
        <ProfilePicture key={id} user={store.users.get(id)}/>
      ))}
    </StyledPictureGrid>
  )
})
