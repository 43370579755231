import { ShareSocial } from 'react-share-social'
import styled from 'styled-components'
import { getElkLiveWebsiteUrl } from '../../../../utils/utils'
import { ELKBlack, ELKHighPrioYellowEnabled, ELKWhite } from '../../../../variables'

const style = {
  root: {
    background: ELKWhite,
    position: 'absolute',
    border: 0,
    color: ELKBlack,
    bottom: '64px',
    padding: '20px 15px',
    minWidth: 0,
    width: '280px',
    left: '14px',
    zIndex: 1
  },
  copyUrl: {
    fontSize: '14px'
  },
  copyIcon: {
    color: 'white'
  },
  title: {
    color: ELKBlack,
    fontStyle: 'italic'
  }
}

const InviteHeader = styled.div`
    background: ${ELKHighPrioYellowEnabled};
    color: ${ELKBlack};
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 32px;
    font-weight: 700;
    font-family: Inter;
    text-align: center
`

const InviteShare = ({ onShare }) => {
  return (
    <ShareSocial
      title={(
        <InviteHeader>
          Invite everyone you want to play with to Elk LIVE!
        </InviteHeader>
      )}
      url = {getElkLiveWebsiteUrl() + 'join-me'}
      socialTypes= {['facebook', 'whatsapp', 'linkedin']}
      onSocialButtonClicked={ () => {
        onShare()}
    }
      style={style}
    />
  )
}

export default InviteShare

