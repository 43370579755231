import styled from 'styled-components'
import { Box, H2, Text } from '../../ui/primitives'
import { StyledButton } from '../../pages/user-configuration/pages/styles'
import { TextArea } from '../base'

const StatusHeader = styled.div`
  background: ${(props) => props.bgColor || 'var(--Elk-Black)'};
  color: var(--Elk-White);
  font-weight: 700;
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
`

const StatusInfoContainer = styled(Box)`
  display:flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 1rem;
  }
`

const NameSection = styled.div`
  background: var(--Elk-Black);
  color: var(--Elk-White);
  font-weight: 700;
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  padding: 6px 12px;
`

const UserInfoSection = styled.div`
  background: #fff;
  color: #1C1C1C;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`

const UserInfoScrollable = styled.div`
  max-height: 300px;
  overflow-y: auto;
`

const StyledTextArea = styled(TextArea)`
  font-size: 14px;
  font-style: normal;
  height: 90px;
`

const Title = styled(H2)`
  font-size: 18px;
  text-align: center;
  margin-bottom: 8px;
  font-weight: 700;
`

const ActionButton = styled(StyledButton)`
  background: ${(props) => props?.bgColor || '#FF0099'};
  margin-top: 10px; 
  font-size: 1.4rem;
`

const CloseContainer = styled.div`
    width: 22px;
    position: absolute;
    background: var(--ELK-Black);
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 21px;
    right: 5px;
    top: 5px;
}
`

const PersonalSummaryText = styled(Text)`
  word-break: break-word;
`

export {
  StatusHeader,
  NameSection,
  UserInfoSection,
  StyledTextArea,
  Title,
  ActionButton,
  StatusInfoContainer,
  CloseContainer,
  PersonalSummaryText,
  UserInfoScrollable
}
