import React, { useEffect } from 'react'
import { BetaLayout } from './layout'
import {
  AppDownloadContainer,
  DownloadBoxLink,
  Paragraph,
  Subtitle,
  Title,
  InlineStyledLink,
  DownloadIconsContainer
} from './style'
import { Box, Text } from '../../../../ui/primitives'
import { AppleIcon, WindowsIcon } from '../../../../ui/icons'
import { useStore } from '../../../../../store'
import { observer } from 'mobx-react-lite'
import { getElkLiveWebsiteUrl } from '../../../../../utils/utils'

export const Download = observer(({ setPage }) => {
  const store = useStore()
  const pendingToConfirm = store.userConfirmed === false
  const elkLiveWebsite = getElkLiveWebsiteUrl()

  const getTitle = () => {
    if(store.userConfirmed)
      return 'Thanks for verifying.'
    if(pendingToConfirm)
      return (<>Confirmation email sent! <br /> Verify your account before first login.</>)

    return 'Time to download.'
  }

  const getSubtitle = () => {
    if((store.userConfirmed) || pendingToConfirm)
      return 'Time to download.'
    if(pendingToConfirm)
      return 'Confirmation email sent!\n' +
        'Verify your account before first login.'

    return '...and start jamming'
  }

  useEffect(() => {
    async function fetchData() {
      await store.initializeAPIClientConnections()
      await store.checkServerStartupData()
    }
    store.analytics.track('Page Viewed', { $email: store.currentUserEmail })
    fetchData()
  // eslint-disable-next-line
  }, [])

  return (
    <BetaLayout setPage={setPage}>
      <Title>{getTitle()}</Title>
      <Subtitle>{getSubtitle()}</Subtitle>
      {store.userConfirmed && (
        <Box fontSize='1.6rem'  mt="3rem" textAlign="center">
          <Paragraph>
            Rather download at a later time? <br />
            No worries, there is a link in your confirmation email.
          </Paragraph>
        </Box>
      )}

      <DownloadIconsContainer>
        <AppDownloadContainer>
          <DownloadBoxLink
            onClick={() => {
              store.analytics.track('Download button clicked', { $email: store.currentUserEmail })
            }}
            href={store.serverStartupData?.elkDesktopDownloadLink}
            target="_blank" rel="noreferrer">
            <AppleIcon size={80} />
            <span>Download for Mac</span>
            <Text fontSize="1.4rem">(MacOS 11 or higher)</Text>
          </DownloadBoxLink>
        </AppDownloadContainer>

        <AppDownloadContainer disabled>
          <WindowsIcon size={80} />
          <span>Windows coming soon!</span>
          <InlineStyledLink
            href="https://pages.elk.live/windows-waiting-list"
            target="_blank"
            rel="noreferrer">Click here to be notified</InlineStyledLink>
        </AppDownloadContainer>
      </DownloadIconsContainer>
      <Box display="flex" mt="2.5rem">
        <Box textAlign="center" fontSize="1.4rem">
          <strong>Do you have an older Mac or Windows?</strong><br />
          With <InlineStyledLink
          href={elkLiveWebsite + 'the-bridge'} target="_blank" rel="noreferrer">the Elk Bridge</InlineStyledLink>
          , you can use any computer together with the <InlineStyledLink
          href="https://studio.elk.live" target="_blank" rel="noreferrer">browser-based Elk LIVE Studio</InlineStyledLink>
        </Box>
      </Box>
    </BetaLayout>
  )
})


