import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import InputWidgets from './InputWidgets'
import PhantomSettings from './PhantomSettings'
import InputSourceSelector from './InputSourceSelector'
import SendControls from './SendControls'
import {
  StereoLinkIcon,
} from '../../ui/icons'
import { Box, H1 } from '../../ui/primitives'

const StereoLinkButton = styled.button`
  user-select: none;
  & svg {
    pointer-events: none;
    margin-bottom: 5px;
  }
  font-size: 1.4rem;
  font-weight: 600;
  &.disabled {
    color: var(--ELK-Dark-Green-Disabled);
    cursor: default;
  }
`

const PreampH1 = styled(H1)`
  font-size: 2.4rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: var(--ELK-White);
`

const BridgeSettings = observer(() => {
  const store = useStore()
  const board = store.board
  const ownTrack = store.mixer.tracks.own

  if (!board) return <Box />

  return (
    <>
      <PreampH1 mt="2rem" ml="2rem">
        inputs
      </PreampH1>
      <Box
        flex
        alignItems="start"
        width="100%"
        justifyContent="center"
        mt="20px"
      >
        <InputSourceSelector board={board} channelIndex={0} title="channel 1" />
        <InputSourceSelector board={board} channelIndex={1} title="channel 2" />
      </Box>
      <Box flex alignItems="center" justifyContent="center" width="100%"></Box>
      <Box flex mt="3rem" mx="15px">
        <Box width="33%" justifyContent="center" flex>
          <PhantomSettings board={board} channelIndex={0} />
        </Box>
        <Box width="33%" justifyContent="center" flex>
          <StereoLinkButton
            className={`${!store.mixer.isStereoLinkAvailable() ? 'disabled' : ''}`}
            isOn={ownTrack.stereoLinked}
            onClick={() => {
              if (!store.mixer.isStereoLinkAvailable()) {
                return
              }
              ownTrack.stereoLinked = !ownTrack.stereoLinked
            }}
          >
            {ownTrack.stereoLinked ? (
              <>
                <StereoLinkIcon linked={true} />
                <Box>unlink</Box>
              </>
            ) : (
              <>
                <StereoLinkIcon linked={false} />
                <Box>stereo link</Box>
              </>
            )}
          </StereoLinkButton>
        </Box>
        <Box width="33%" justifyContent="center" flex>
          <PhantomSettings board={board} channelIndex={1} />
        </Box>
      </Box>
      <Box flex column width="100%">
        <Box flex row width="100%" alignItems="flex-start">
          {ownTrack.stereoLinked ? (
            <InputWidgets board={board} stereoLinking={true} />
          ) : (
            <>
              <InputWidgets
                board={board}
                channelIndex={0}
                stereoLinking={false}
              />
              <InputWidgets
                board={board}
                channelIndex={1}
                stereoLinking={false}
              />
            </>
          )}
        </Box>
        <Box flex row width="100%" justifyContent="space-around">
          {ownTrack.stereoLinked ? (
            <SendControls board={board} stereoLinking={true} />
          ) : (
            <>
              <SendControls
                board={board}
                channelIndex={0}
                stereoLinking={false}
              />
              <SendControls
                board={board}
                channelIndex={1}
                stereoLinking={false}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  )
})

export default BridgeSettings
