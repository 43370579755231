import styled from 'styled-components';
import { observer } from 'mobx-react-lite'
import PrivatestudioLogoSrc from '../../assets/privatestudio-logo.svg'
import { Text, Box } from '../ui/primitives'
import { CloseIcon } from '../ui/icons'
import { useStore } from '../../store'

const Container = styled(Box)`
  background: var(--ELK-Black);
`

const PrivatestudioLogo = styled.img`
  width: 100%;
  margin-bottom: 0.3rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: var(--ELK-High-Contrast-Yellow);
  width: 12px;
  height: 12px;
  float: right;
  margin-bottom: 0.3rem;
  cursor: pointer;
`

const InfoText = styled(Text)`
  color: var(--ELK-High-Contrast-Yellow);
  font-weight: 500;
`

const PrivatestudioBanner = observer(({ dismissable = true, infoTextSize = '1rem', ...rest }) => {
  const store = useStore()

  if (store.privatestudioBannerDismissed && dismissable) return null
  return (
    <Container p="0.8rem" {...rest}>
      {dismissable && <StyledCloseIcon onClick={() => { store.privatestudioBannerDismissed = true }} />}
      <PrivatestudioLogo src={PrivatestudioLogoSrc} alt="Logo of Privatestudio" />
      <InfoText fontSize={infoTextSize}>The invite only version of Elk LIVE</InfoText>
    </Container>
  )
})

export default PrivatestudioBanner;
