import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { openModal } from './overlays'
import { Bold, Box, H1, Text } from '../ui/primitives'
import { Dialog } from './base'
import { withStore } from '../../store'
import { ExclamationIcon } from '../ui/icons'
import Button from '../ui/button'
import { relaunchApp } from '../../store/electronAPI'
import { closeAllModals } from '../../utils/utils'

const Paragraph = styled(Text)`
  margin: 10px 0;
  strong {
    font-weight: 700;
  }
`

const SampleRateErrorDialog = observer(() => {
  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      color="var(--ELK-Black)"
      width="55rem"
      alignItems="center"
      textAlign="center"
      fontSize="1.6rem"
    >
      <ExclamationIcon width={80} height={80} />
      <H1 mb="1rem" color="var(--ELK-Black)">Sample rate conflict!</H1>
      <Box>
        <Paragraph>
          Elk LIVE only runs at <Bold>48 kHz</Bold>. <br />
          If you need to use another audio application at the same time, please make sure it also runs at 48 kHz.
        </Paragraph>
        <Box mt="2.2rem" mb="1.2rem" width="100%">
          <Button primary onClick={() => {
              relaunchApp()
          }} width="100%">Reboot</Button>
        </Box>
      </Box>
    </Dialog>
  )
})

export function showSampleRateError({ store }) {
  closeAllModals()
  return openModal(
    ({ close }) => withStore(store, <SampleRateErrorDialog close={close} />),
    {
      autoDismissable: false,
      alwaysOnTop: true,
    },
  )
}
