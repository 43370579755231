import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../store'
import {
  INPUT_OPTION_NONE,
  INPUT_OPTION_LINE,
} from '../../../../../store/input-options'
import Dropdown from '../../../../ui/form/dropdown'

const menuItems = [
  { title: 'on', option: INPUT_OPTION_LINE },
  { title: 'none', option: INPUT_OPTION_NONE },
]

function DesktopInputDropdown({ channelIndex }) {
  const store = useStore()
  const selectedItem =
    menuItems.find(
      (item) => item.option === store.mixer.inputOptions[channelIndex],
    ) ?? menuItems[menuItems.length - 1]

  return (
    <Dropdown
      enabled
      title={selectedItem.title}
      textAlign="center"
      menuItems={menuItems}
      selectedItem={selectedItem}
      onChange={(index) => {
        store.mixer.inputOptions[channelIndex] = menuItems[index].option
      }}
    />
  )
}

export default observer(DesktopInputDropdown)
