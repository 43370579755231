import { useStore } from '../../../../store'
import { observer } from 'mobx-react-lite'
import { Box } from '../../../ui/primitives'
import styled from 'styled-components'
import { showIceFailureSolutionsDialog } from '../../../dialogs/ice-failure-solutions'
import useVideoPausedState from '../../../video-chat/hooks/use-video-paused-state'
import { APIClient } from '../../../../api/client'
import {
  SESSION_PARTNER_STATE_WAITING,
  SESSION_PARTNER_STATE_LEFT,
  SESSION_PARTNER_STATE_DECLINED,
} from '../../../../store/session-partner-states'

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SolutionLink = styled.button`
  text-decoration: underline;
`

export const SessionStatus = observer(({ userId, tileId, shorten = false }) => {
  const videoPaused = useVideoPausedState(tileId)

  const store = useStore()
  const session = store.session
  const user = store.users.get(userId)
  const status = session?.partnerICEStatus.get(userId)
  const state = session?.partnerState.get(userId)

  if (!shorten && videoPaused) {
    return (
      <Container bgColor="var(--ELK-Candy-Tangerine)" color="var(--ELK-White)">
        video has been paused because of poor network. it will start again when possible
      </Container>
    )
  }
  if (state === SESSION_PARTNER_STATE_LEFT) {
    return (
      <Container bgColor="var(--ELK-Midnight-Grey)" color="var(--ELK-White)">
        user has left the session
      </Container>
    )
  }
  if (state === SESSION_PARTNER_STATE_DECLINED) {
    return (
      <Container bgColor="var(--ELK-Midnight-Grey)" color="var(--ELK-White)">
        user declined the session invitation
      </Container>
    )
  }
  if (state === SESSION_PARTNER_STATE_WAITING) {
    return (
      <Container bgColor="var(--ELK-High-Prio-Yellow-Enabled)" color="var(--ELK-Black)">
        waiting for user to join...
      </Container>
    )
  }
  if (status === 'connected' || APIClient._cloud_debug_mode) {
    return <div/>
  }
  if (status === 'idle' || status === 'connecting' || status === 'reverse') {
    return (
      <Container bgColor="var(--ELK-High-Prio-Green-Enabled)" color="var(--ELK-Black)">
        {shorten ? <>bridges<br/>connecting...</> : `your and ${user?.name}'s bridges are connecting...`}
      </Container>
    )
  }
  if (status === 'fail') {
    return (
      <Container bgColor="var(--ELK-Candy-Tangerine)">
        failed to connect!&nbsp;
        <SolutionLink onClick={() => showIceFailureSolutionsDialog({store})}>click for solutions</SolutionLink>
      </Container>
    )
  }
  return (
    <Container bgColor="var(--ELK-Midnight-Grey)">
      {shorten ? 'setting up' : `your and ${user?.name}'s bridges are setting up`}
    </Container>
  )
})
