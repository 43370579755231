import React, { useState, useEffect, useMemo } from 'react'
import { ConsoleLogger, DefaultDeviceController } from 'amazon-chime-sdk-js'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import debounce from 'lodash/debounce'

import { Box, I } from '../../ui/primitives'
import { useStore } from '../../../store'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../store/board-connection-states'
import {
  QuestionMarkIcon
} from '../../ui/icons'
import { DEFAULT_LOCAL_COMPENSATION_DELAY, DEFAULT_JITTER_BUFFER_MULTIPLIER } from '../../../store/settings'

import { logger } from '../../../utils/logging'
import { StyledH1, StyledH2 } from './styles'
import AudioSection from './AudioSection'
import TalkbackSection from './TalkbackSection'
import Dropdown from '../../ui/form/dropdown'
import Checkbox from '../../ui/form/checkbox'
import Input from '../../ui/form/input'
import SpeedTest from './SpeedTest'

const HR = styled.hr`
  border: 0;
  border-top: 1px solid var(--ELK-Dark-Grey);
  margin: 2rem 0;
`

const VideoQualitySettings = observer(() => {
  const store = useStore()

  const qualitySettings = [
    { title: 'High (1Mbps)', value: 1000 },
    { title: 'Maximum (2.5Mbps)', value: 2500 }
  ]

  let selectedIndex = qualitySettings.findIndex((setting) => {
    return setting.value === store.settings.videoChat.uplinkMaxBandwidth
  })
  if (selectedIndex === -1) {
    selectedIndex = 0
  }

  return (
    <>
      <StyledH2>Send Quality</StyledH2>
      <Dropdown
        width='100%'
        title={qualitySettings[selectedIndex].title}
        menuItems={qualitySettings}
        selectedIndex={selectedIndex}
        onChange={(primarySelection) => {
          store.settings.videoChat.uplinkMaxBandwidth =
            qualitySettings[primarySelection].value
          store.settings.save()
        }}
      />
    </>
  )
})

const VideoSourceSettings = observer(() => {
  const store = useStore()

  const [sourceSettings, setSourceSettings] = useState([])

  let selectedIndex = sourceSettings.findIndex((setting) => {
    return setting.value === store.settings.videoChat.selectedVideoInputDeviceId
  })
  if (selectedIndex === -1) {
    selectedIndex = 0
  }

  useEffect(() => {
    const deviceLogger = new ConsoleLogger('VideoChatDeviceController')
    const deviceController = new DefaultDeviceController(deviceLogger)
    async function init () {
      try {
        const deviceList = await deviceController.listVideoInputDevices()
        setSourceSettings(
          deviceList.map((device, index) => {
            return {
              title: device.label || `Device ${index + 1}`,
              value: device.deviceId
            }
          })
        )
      } catch (e) {
        logger.error(e.message)
      }
    }
    init()
    return () => {
      try {
        deviceController.destroy()
      } catch (e) {
        logger.error(e.message)
      }
    }
  }, [])

  return (
    <>
      <StyledH2 mt='15px'>Video Source</StyledH2>
      <Dropdown
        title={
          sourceSettings.length === 0
            ? 'No devices found'
            : sourceSettings[selectedIndex]?.title
        }
        width='100%'
        menuItems={sourceSettings}
        selectedIndex={selectedIndex}
        onChange={(primarySelection) => {
          store.settings.videoChat.selectedVideoInputDeviceId =
            sourceSettings[primarySelection].value
        }}
      />
    </>
  )
})

const VideoSettings = observer(() => {
  return (
    <>
      <StyledH1>Video</StyledH1>
      <VideoQualitySettings />
      <VideoSourceSettings />
    </>
  )
})

const SimulatedDistanceSettings = observer(({ board }) => {
  const store = useStore()

  return (
    <>
      <StyledH2>Compensation <a target='_blank' rel='noreferrer' href='https://intercom.help/elk-audio/en/articles/5807074-how-to-use-compensation'><QuestionMarkIcon /></a></StyledH2>
      <Box flex column rowGap='20px'>
        <>
          <Checkbox
            disabled={!board}
            label='Enter Manual Value'
            checked={store.enableManualCompensation}
            onChange={(value) => {
              if (value === false) {
                store.mixer.compensationDelay = DEFAULT_LOCAL_COMPENSATION_DELAY
              }
              store.enableManualCompensation = value
            }}
          />
          {store.enableManualCompensation && (
            <Box>
              <I>This setting reverts to default when you log out.</I>
              <Box mt='15px' flex row alignItems='center' columnGap='8px'>
                <Input
                  type='number'
                  min='0'
                  max='30'
                  value={store.mixer.compensationDelay}
                  onChange={(e) => {
                    let value = parseInt(e.target.value)
                    if (value > 30) {
                      value = 30
                    }
                    if (isNaN(value)) {
                      value = null
                    }
                    store.mixer.compensationDelay = value
                  }}
                />
              </Box>
            </Box>
          )}
        </>
      </Box>
    </>
  )
})

function SystemSettings () {
  const store = useStore()
  const board = store.board

  const setVideoSyncMode = useMemo(() => debounce((active, delay) => {
    store.session?.setVideoSyncMode(active, delay)
  }, 300), [store.session])

  return (
    <Box px='20px' py='26px'>
      <AudioSection />
      <HR />
      <VideoSettings />
      <HR />
      <TalkbackSection />
      <HR />
      {store.speedTestResults?.upload && (
        <>
          <SpeedTest />
          <HR />
        </>
      )}
      <StyledH2 mt='15px'>Ring Tone</StyledH2>
      <Checkbox
        label='Incoming Session Request'
        checked={store.settings.ui.playRingtone}
        onChange={(value) => {
          store.settings.ui.playRingtone = value
          store.settings.save()
        }}
      />
      <HR />
      <StyledH1 mt='2rem'>Advanced Settings</StyledH1>
      <Checkbox
        label='Show advanced settings'
        checked={store.settings.ui.showAdvancedSettings}
        onChange={(value) => {
          store.settings.ui.showAdvancedSettings = value
          store.settings.save()
        }}
      />
      {store.settings.ui.showAdvancedSettings && (
        <>
          <HR />
          <StyledH1 mt='2rem'>Video Advanced</StyledH1>
          <StyledH2>Video sync <a target='_blank' rel='noreferrer' href='https://help.elk.live/en/articles/6168988-video-sync'><QuestionMarkIcon /></a></StyledH2>
          <Box flex column rowGap='20px'>
            <>
              <Checkbox
                label={<span>Enable audio and video sync.<br /> Affects everyone in the session.</span>}
                checked={store.videoSync.enabled}
                onChange={(value) => {
                  store.videoSync.enabled = value
                }}
              />
              {store.videoSync.enabled && (
                <Box>
                  <Box mt='15px' flex row alignItems='center' columnGap='8px'>
                    <Input
                      type='number'
                      step='10'
                      min='0'
                      disabled={!store.sessionMode}
                      value={store.session.videoSync.delay}
                      onChange={(e) => {
                        let value = parseInt(e.target.value)
                        if (isNaN(value)) {
                          value = ''
                        }
                        store.session.videoSync.delay = value

                        if (store.sessionMode && store.session.videoSync.active && value !== '') {
                          setVideoSyncMode(store.session.videoSync.active, value)
                        }
                      }}
                    />
                  </Box>
                </Box>
              )}
            </>
          </Box>
          <HR />
          <StyledH1 mt='2rem'>Audio Advanced</StyledH1>
          <SimulatedDistanceSettings
            board={board}
            disabled={
              store.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED
            }
          />
          <HR />
          <StyledH2>Individual buffer <a target='_blank' rel='noreferrer' href='https://intercom.help/elk-audio/en/articles/5807079-how-to-use-individual-buffer'><QuestionMarkIcon /></a></StyledH2>
          <Box flex column rowGap='20px'>
            <Checkbox
              label='Enable manual buffer for each receiving track'
              checked={store.settings.ui.showJitterDelaySettings}
              disabled={store.session?.videoSync.active}
              onChange={(value) => {
                /* If setting is disabled we set jitter delay to 0, so the auto-jitter will be on,
                 otherwise we set it to default value for tracks of all partners */

                store.session?.partnerIds.forEach((id) => {
                  store.session.setPartnerJitterBufferMultiplier(id, value ? DEFAULT_JITTER_BUFFER_MULTIPLIER : 0)
                })

                store.settings.ui.showJitterDelaySettings = value
                store.settings.save()
              }}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

export default observer(SystemSettings)
