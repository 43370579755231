import styled from "styled-components";
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'

const Indicator = styled.div`
  width: ${(props) => props.size ? props.size : '1rem'};
  height: ${(props) => props.size ? props.size : '1rem'};
  background-color: ${(props) => props.active ? 'var(--ELK-Sea-Foam-Green)' : 'var(--ELK-Inactive-Olympic-White)'};
  border-radius: 50%;
`

export const ActiveStatus = observer(({ user }) => {
  const store = useStore()
  const friendIsActive = store.userActive.get(user?.id)
  return (
    <Indicator active={friendIsActive} />
  )
})
