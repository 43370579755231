import styled from 'styled-components'
import { Box, property } from './primitives'

export const IconButton = styled(Box).attrs((props) => ({
  as: props.as || 'button',
  type: props.type || 'button',
}))`
  ${(props) =>
    (props.primary || props.secondary || props.danger) &&
    `
    width: 6.5rem;
    height: 6.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    background: ${
      props.secondary
        ? 'var(--ELK-High-Prio-Yellow-Enabled)'
        : props.danger
        ? 'var(--ELK-High-Prio-Red-Enabled)'
        : 'var(--ELK-Standard-Enabled)'
    };
    border: none;
    transition: background .2s ease, color .2s ease;
    ${property(
      props,
      'px',
      (px) => `padding-left: ${px}; padding-right: ${px};`,
    )}
    ${property(props, 'width', 'width')}

    &:hover {
      background: ${
        props.secondary
          ? 'var(--ELK-High-Prio-Yellow-Hover)'
          : props.danger
          ? 'var(--ELK-High-Prio-Red-Hover)'
          : 'var(--ELK-Standard-Hover)'
      };
    }

    &:focus {
      background: ${
        props.secondary
          ? 'var(--ELK-High-Prio-Yellow-Pressed)'
          : props.danger
          ? 'var(--ELK-High-Prio-Red-Pressed)'
          : 'var(--ELK-Standard-Pressed)'
      };
    }

    &:disabled {
      cursor: default;
      background: var(--ELK-Standard-Inactive);
      color: var(--ELK-Text-Standard-Inactive);
    }
  `}
`
