import React from 'react'
import {observer, useLocalObservable} from 'mobx-react-lite'

import {TextField} from '../../../ui/text-field'
import {Box, H3} from '../../../ui/primitives'
import {Auth} from "aws-amplify";
import {Spinner} from "../../../ui/spinner";
import styled from "styled-components";
import {
  StyledH1,
  StyledButton,
  ErrorMessage } from './styles';
import { LoginLayout } from './layout'

const ResetPasswordForm = styled.form`
  width: 40.8rem;
  height: 50.7rem;
  background-color: var(--ELK-High-Contrast-Yellow);
  padding: 2.6rem 3.4rem;
`

const StyledH3 = styled(H3)`
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: normal;
`

const StyledLink = styled.a`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  text-decoration: underline;
  margin-bottom: 1.8rem;
`

export const ResetPassword = observer(({setPage}) => {
  const state = useLocalObservable(() => {
    const json = localStorage.getItem('user')
    const storedUser = json ? JSON.parse(json) : null
    return {
      email: storedUser?.email ?? '',
      busy: false,
      hasResetPassword: false,
    }
  })

  const field = (name) => ({
    value: state[name],
    onChange: (e) => {
      (state[name] = e.target.value)
      state.dirty = false
    },
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    state.busy = true
    state.passwordsMatch = state.password === state.confirm_password
    if (!state.hasResetPassword && state.passwordsMatch) {
      try {
        await Auth.forgotPassword(state.email)
        state.hasResetPassword = true
      } catch (error) {
        console.error(error)
        state.dirty = true
      }
    }
    state.busy = false
  }

  return (
    <LoginLayout>
        <ResetPasswordForm onSubmit={handleSubmit}>
          {!state.hasResetPassword ? (
            <>
              <StyledH1>forgot your password?</StyledH1>
              <StyledH3>
                Don't worry! Just enter the email associated with your account and we'll send an email with instructions
                to reset your password.</StyledH3>
              <TextField
                placeholder="email"
                type="email"
                {...field('email')}
                m="1.5rem 0 8.5rem 0 "
                required
              />
              <StyledButton
                type="submit"
                disabled={state.busy || !state.email}
              >
                {state.busy ? <Spinner size="24"/> : <>send reset link by email</>}
              </StyledButton>
              <ErrorMessage>
                {state.dirty && 'invalid email'}
              </ErrorMessage>
            </>
          ) : (
            <>
              <StyledH1>forgot your password?</StyledH1>
              <StyledH3>
                Check your email and use the reset password link we sent to
                your email address.
                <br/><br/>
              </StyledH3>
            </>
          )}
          <Box fontSize="1.4rem"> Remembered after all? &nbsp;
            <StyledLink onClick={() => {
              window.history.replaceState({}, document.title, '/');
              setPage('login')
            }}>
              login
            </StyledLink>
          </Box>
        </ResetPasswordForm>
    </LoginLayout>
  )
})
