import React from 'react'
import {observer, useLocalObservable} from 'mobx-react-lite'
import { Box, H3 } from '../../../ui/primitives'
import {useStore} from '../../../../store'
import {Auth} from 'aws-amplify'
import comingSoon from '../../../../assets/coming-soon.png'
import styled from 'styled-components'
import {TextField} from '../../../ui/text-field'
import {Spinner} from '../../../ui/spinner'
import useIsPrivatestudio from '../../../../hooks/use-privatestudio'
import PrivatestudioBanner from '../../../features/privatestudio-banner'
import {
    StyledH1,
    StyledButton,
    ErrorMessage } from './styles'
import { getCurrentUrlOrigin, isRunningAndroid, isRunningiOS } from '../../../../utils/utils'
import { logger } from '../../../../utils/logging'
import { LoginLayout } from './layout'

const LoginForm = styled.form`
  width: 30.8rem;
  background-color: var(--ELK-High-Contrast-Yellow);
`

const LoginFormContent = styled.div`
  padding: 2.6rem 3.4rem;
`

const StyledH3 = styled(H3)`
  font-size: 1.4rem;
  font-weight: normal;
`

const StyledLink = styled.a`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  text-decoration: underline;
  margin-bottom: 1.8rem;
`

const InlineStyledLink = styled.a`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  text-decoration: underline;
`

const FloatExclaim = styled.span`
  float:left;
  font-weight: 800;
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  align-content: center;
  background-color: var(--ELK-Black);
  color: var(--ELK-High-Prio-Yellow-Enabled);
  padding: 0.2rem 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  vertical-align: center;
`

const URLIncludesStringMobile = () => {
  return window.location.href.includes('mobile')
}

export const AwsLogin = observer(({setPage}) => {
  const isPrivatestudio = useIsPrivatestudio()
  const store = useStore()
  const url = new URL(window.location.href)
  const resetPasswordCode = url.searchParams.get('reset-password-code')
  const resetPasswordEmail = url.searchParams.get('email')
  if (resetPasswordCode && resetPasswordEmail) {
    setPage('reset-password-token')
  }

  const state = useLocalObservable(() => {
    const json = localStorage.getItem('user')
    const storedUser = json ? JSON.parse(json) : null
    return {
      email: storedUser?.email ?? '',
      password: '',
      busy: false,
      dirty: false,
      passwordError: '',
      errorCode: '',
      sendingConfirmationCode: false
    }
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    state.busy = true

    try {
      await Auth.signIn({
        username: state.email,
        password: state.password,
      })

      const currentSession = await Auth.currentSession()
      const email = currentSession.getIdToken().payload['email']

      localStorage.setItem('user', JSON.stringify({email, timestamp: Date.now()}))
      store.analytics.track('User Logged In')
      await store.AWSLogin(currentSession)

    } catch (error) {
      logger.error('Error while trying to sign in', error)
      state.dirty = true
      state.errorCode = error.code
      if(error.code === 'NotAuthorizedException'){
        state.passwordError = 'var(--ELK-Candy-Tangerine)'
      }
    }
    state.busy = false
  }

  const sendConfirmationCode = async () => {
    state.busy = true
    await Auth.resendSignUp(state.email)
    state.sendingConfirmationCode = true
    state.busy = false
  }

  const field = (name) => ({
    value: state[name],
    onChange: (e) => {
      state[name] = e.target.value
      state.dirty = false
      state.passwordError = ''
    },
  })

  if ((isRunningiOS() || isRunningAndroid()) && !URLIncludesStringMobile()) {
    return <img alt="Coming soon" src={comingSoon} width="100%"/>
  }

  return (
    <LoginLayout>
        <LoginForm onSubmit={handleSubmit}>
          <>
            {isPrivatestudio && <PrivatestudioBanner dismissable={false} px="3.4rem" py="1.5rem" infoTextSize="1.4rem" />}
            <LoginFormContent>
              <StyledH1>login.</StyledH1>
              {/*<div>*/}
              {/*  <SocialButton onClick={async () => {*/}
              {/*    await store.AWSGoogleSignIn()*/}
              {/*  }}>*/}
              {/*    <GoogleIcon size={20} />*/}
              {/*    Sign in with Google*/}
              {/*  </SocialButton>*/}
              {/*</div>*/}
              {/*<Box textAlign="center">*/}
              {/*  or*/}
              {/*</Box>*/}
              <TextField
                placeholder="email"
                type="email"
                {...field('email')}
                m="1.5rem 0"
                required
              />
              <TextField
                placeholder="password"
                type="password"
                {...field('password')}
                m="1.5rem 0"
                required
                bg={state.passwordError}
              />
              <ErrorMessage>
                {state.dirty && <Box display="flex" flexDirection="row">
                  <FloatExclaim>!</FloatExclaim>
                  {state.errorCode === 'NotAuthorizedException' && 'invalid email or password'}
                  {state.errorCode === 'UserNotConfirmedException' && (
                    <Box lineHeight="20px" mb="10px">
                      {state.sendingConfirmationCode ? (<>confirmation code sent!</>) : (
                        <>
                          you need to verify your email first.
                          <InlineStyledLink onClick={async () => { await sendConfirmationCode() }}>
                            &nbsp;click here
                          </InlineStyledLink>  if you  want to receive a new confirmation email
                        </>
                      )}
                    </Box>
                  )}
                </Box>}
              </ErrorMessage>
              <StyledButton type="submit" disabled={state.busy || !state.email || !state.password}>
                {state.busy ? <Spinner size="24"/> : <>Login</>}
              </StyledButton>
              <StyledLink onClick={() => setPage('reset-password')}>
                forgot password?
              </StyledLink>
              <StyledH3>
                New to Elk?{' '}
                <StyledLink href={`${getCurrentUrlOrigin()}/join`} target="_blank" rel="noreferrer">
                  register
                </StyledLink>
              </StyledH3>
            </LoginFormContent>
          </>
        </LoginForm>
    </LoginLayout>
  )
})
