import React, { useRef, useEffect, useState, useCallback } from 'react'
import styled, { keyframes } from 'styled-components' //'styled-components' -> /macro for debug purposes
import { Box } from '../../../ui/primitives'
import { observer } from 'mobx-react-lite'
import { BigPeakMeter } from './big-peak-meter'
import { MuteButton } from './mute-button'
import {
  MixerTooltip,
  Title as TooltipTitle,
  Body as TooltipBody,
  STATE_ACTIONS,
  useMixerTooltipState,
} from './mixer-tooltip'
const { FORCE_TOOLTIP_STATE, TRIGGER_DELAYED_TOOLTIP } = STATE_ACTIONS

const InnerContainer = styled.div`
  position: relative;
  touch-action: none;
  user-select: none;
  height: 100%;
  width: 24px;
  display: flex;
`

const pulse = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.9;
  }
`

const Handle = styled.div`
  touch-action: none;
  background-color: var(--ELK-White);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ELK_White);
  cursor: pointer;
  height: 35px;
  opacity: 0.2;
  z-index: 2;
  &.disabled {
    display: none;
  }
  &.pulsing {
    animation: ${pulse} 0.5s linear alternate infinite;
  }
`

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  &:hover ${Handle} {
    opacity: 1 !important;
    animation: none !important;
  }
`

const ToolTipContainer = styled(Box)`
  width: 73px;
  height: calc(100% - 170px);
  position: fixed;
`;

const DRAG_DELTA = 10
export const TrackControlSlim = observer(function TrackControlSlim({
  volume,
  onVolumeChange,
  onVolumeChangeStop,
  muted,
  onMutedChange,
  disabled = true,
  getPeakMeterValues,
  getPeakMeterUpdatedAt,
  children,
}) {
  const [dragging, setDragging] = useState(0)
  const [handlePosY, setHandlePosY] = useState(0)
  const [{ tooltipShouldOpen, tooltipIsOpen }, dispatch] =
    useMixerTooltipState()

  const sliderRef = useRef()
  const containerRef = useRef()
  const handleRef = useRef()
  const dragStartY = useRef(0)

  const handleShouldPulse = volume === 0 && !muted && !disabled

  useEffect(() => {
    if (handleShouldPulse) {
      dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })
    }
  }, [handleShouldPulse, dispatch])

  useEffect(() => {
    const handle = handleRef.current
    const slider = sliderRef.current
    if (!handle || !slider) {
      return
    }
    const updateHandlePos = () => {
      const sliderHeight = slider.offsetHeight
      const value = disabled ? 0 : volume
      setHandlePosY(Math.floor(value * sliderHeight))
    }
    updateHandlePos()
    window.addEventListener('resize', updateHandlePos)
    return () => {
      window.removeEventListener('resize', updateHandlePos)
    }
  }, [handleRef, volume, disabled])

  const onClick = (ev) => {
    if (disabled) return
    const { clientY, touches } = ev
    const y = touches ? touches[0].clientY : clientY
    const rect = sliderRef.current.getBoundingClientRect()
    const val = Math.max(0, Math.min(1, 1 - (y - rect.top) / rect.height))
    onVolumeChange(val)
  }

  const dragMove = useCallback(
    (ev) => {
      if (dragging) {
        const diffY = Math.abs(ev.pageY - dragStartY.current)

        if (diffY < DRAG_DELTA) {
          return // Click!
        }
        const { clientY, touches } = ev
        const y = touches ? touches[0].clientY : clientY
        const rect = sliderRef.current.getBoundingClientRect()
        const val = Math.max(0, Math.min(1, 1 - (y - rect.top) / rect.height))
        onVolumeChange(val)
      }
    },
    [onVolumeChange, dragging],
  )

  const dragEnd = useCallback(() => {
    setDragging(false)
    onVolumeChangeStop()
    return () => {
      document.removeEventListener('mouseup', dragEnd)
    }
  }, [onVolumeChangeStop])

  const dragStart = useCallback(
    (ev) => {
      ev.preventDefault()
      if (disabled) return
      setDragging(true)
      dragStartY.current = ev.pageY
      document.addEventListener('mouseup', dragEnd)
    },
    [disabled, dragEnd],
  )

  useEffect(() => {
    if (!handleShouldPulse && !dragging) {
      dispatch({
        action: TRIGGER_DELAYED_TOOLTIP,
        payload: false,
      })
    }
  }, [dragging, handleShouldPulse, dispatch])

  const handleHeight = handleRef?.current?.offsetHeight ?? 0
  const handlePos = Math.max(0, handlePosY - handleHeight)
  return (
    <Box
      flex
      column
      bgColor="var(--ELK-Black)"
      width="73px"
      height="100%"
      alignItems="center"
      justifyContent="center"
      ref={containerRef}
    >
      <ToolTipContainer
        flex
        column
        alignItems="center"
        rowGap="15px"
        pt="10px"
        pb="10px"
        onTouchMove={dragMove}
        onMouseMove={dragMove}
      >
        {children}
        <OuterContainer
          onTouchStart={onClick}
          onClick={onClick}
          disabled={disabled}
          ref={sliderRef}
        >
          <InnerContainer>
            <BigPeakMeter
              disabled={disabled}
              getValues={getPeakMeterValues}
              getUpdatedAt={getPeakMeterUpdatedAt}
            />
          </InnerContainer>

          <Box
            absolute
            flex
            column
            width="100%"
            style={{ bottom: handlePos }}
            onMouseEnter={() => {
              dispatch({ action: TRIGGER_DELAYED_TOOLTIP, payload: true })
            }}
            onMouseLeave={() => {
              if (!handleShouldPulse && !dragging) {
                dispatch({
                  action: TRIGGER_DELAYED_TOOLTIP,
                  payload: false,
                })
              }
            }}
          >
            <MixerTooltip
              shouldOpen={tooltipShouldOpen}
              isOpen={tooltipIsOpen}
              onClick={(ev) => ev.stopPropagation()}
            >
              <TooltipTitle>volume</TooltipTitle>
              <TooltipBody
                readFormatter={(value) => `${value}%`}
                value={Math.floor(volume * 100)}
                onSave={(currentValue) => {
                  const numberValue = Number.parseInt(currentValue, 10)
                  if (Number.isNaN(numberValue)) {
                    return false
                  }
                  const newValue = Math.max(0, Math.min(100, numberValue))
                  onVolumeChange(newValue / 100)
                }}
              />
            </MixerTooltip>
            <Handle
              className={`${dragging ? 'active' : ''} ${
                disabled ? 'disabled' : ''
              } ${handleShouldPulse ? 'pulsing' : ''}`}
              ref={handleRef}
              onTouchStart={dragStart}
              onMouseDown={dragStart}
              onClick={(ev) => {
                ev.stopPropagation()
                dispatch({ action: FORCE_TOOLTIP_STATE, payload: true })
              }}
            />
          </Box>
        </OuterContainer>
        <MuteButton
          disabled={disabled}
          muted={muted}
          onChange={onMutedChange}
        />
        <Box flex column alignItems="center">
          <Box fontSize="12px" fontWeight="700">
            master
          </Box>
        </Box>
      </ToolTipContainer>
    </Box>
  )
})
