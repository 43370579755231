import React, { useRef, useEffect } from 'react'
import { ELKBlack, ELKHighPrioYellowEnabled, ELKHighPrioRedEnabled, ELKHighPrioGreenEnabled, ELKDarkGrey } from '../../../../variables'

const easeIn = (k) => {
  return Math.pow(k, 1.675) * 0.000005
}

const levelColor = (value, level, warningLimit, errorLimit, fgColor) => {
  if (level > value) return fgColor;
  if (level >= errorLimit) return ELKHighPrioRedEnabled;
  if (level >= warningLimit) return ELKHighPrioYellowEnabled;
  return ELKHighPrioGreenEnabled;
}

export const PeakMeter = ({ getValue, getUpdatedAt, warningLimit = 0.7, errorLimit = 1.0, markerHeight = 9, markerSpacing = 9, bgColor = ELKBlack, fgColor = ELKDarkGrey, disabled = true }) => {

  const canvasRef = useRef(null)

  useEffect(() => {
    let requestId
    const canvas = canvasRef.current
    if (!canvas) return
    /* This line is important since we base the canvas height and width on the parent element
     * If we don't have this line the parent element height and width will depend on the canvas
     * and vice versa, leading to potential bugs */
    canvas.style.position = 'absolute'
    const context = canvas.getContext('2d')
    let height = 0
    let width = 0
    const updateWindowSize = () => {
      /* Wrapped in requestAnimationFrame to get correct values for rect */
      requestAnimationFrame(() => {
        const rect = context.canvas.parentElement.getBoundingClientRect()
        context.canvas.width = rect.width
        context.canvas.height = rect.height
        height = context.canvas.height
        width = context.canvas.width
        drawFrame()
      })
    }
    updateWindowSize()
    window.addEventListener('resize', updateWindowSize)
    const drawFrame = () => {
      const currentTime = Date.now()
      const timeDiff = currentTime - getUpdatedAt()
      const valueAfterInterpolation = Math.max(getValue() - easeIn(timeDiff), 0)
      const numMarkers = 1 + Math.floor((height - markerHeight) / (markerHeight + markerSpacing))
      context.fillStyle = bgColor
      context.fillRect(0, 0, width, height)
      for (let i = 0; i < numMarkers; i++) {
        const index = numMarkers - 1 - i
        if (disabled) {
          context.fillStyle = fgColor
        } else {
          context.fillStyle = levelColor(valueAfterInterpolation, (index + 1) / (numMarkers), warningLimit, errorLimit, fgColor)
        }
        context.fillRect(0, i * (markerHeight + markerSpacing), width, markerHeight)
      }
    }
    const render = (loop) => {
      drawFrame()
      requestId = requestAnimationFrame(render)
    }
    render()
    return () => {
      window.removeEventListener('resize', updateWindowSize)
      cancelAnimationFrame(requestId)
    }
  }, [getUpdatedAt, getValue, errorLimit, warningLimit, markerHeight, markerSpacing, disabled, fgColor, bgColor])

  return (
    <canvas ref={canvasRef} />
  )

}
