import introJs from 'intro.js'
import { ACTIVE_VIEW_STATE_LOBBY } from './active-view-states'

const stepConfiguration = () => {
  const intro = `<img 
                    src="https://elk-live-email-media-assets.s3.eu-north-1.amazonaws.com/mail-header.jpg"
                  /> 
                  <p>Great to see you!</p>`
  return [
    {
      title: intro,
      intro: 'Just follow these simple steps to get started!'
    },
    {
      element: document.querySelector('#step-1-soundcheck'),
      title: '<p>Soundcheck</p>',
      intro: 'Make sure your audio interface is connected and do a quick soundcheck.'
    },
    {
      title: '<p>Add friends!</p>',
      element: document.querySelector('#step-2-friends'),
      intro: 'Use their email to find them.\n' +
        'They don\'t have an Elk LIVE account yet? Even better! Let\'s invite them.\n'
    },
    {
      title: '<p>Start a session!</p>',
      intro: 'Hit that start session button and start your first jam!',
      element: document.querySelector('#step-3-session')
    },
    {
      title: '<p>Got a problem?</p>',
      intro: 'Contact the support if you need help in any way.\n' +
        '<br /> <br /> Next step is to put some color on your profile.',
      element: document.querySelector('#step-4-support')
    }
  ]
}

export const startTour = (store, force = false) => {
  if(localStorage.getItem('onboardingComplete') !== 'true' || force){
    introJs()
      .oncomplete(() => {
        store.redirectTo = 'profile-settings'
        return true
      })
      . onchange(() => {
        if(store.activeViewState !== ACTIVE_VIEW_STATE_LOBBY){
          store.activeViewState = ACTIVE_VIEW_STATE_LOBBY
        }
      })
      .setOptions({
        tooltipClass: 'customTooltip',
        doneLabel: 'Go to profile',
        steps: stepConfiguration(),
        showProgress: true,
        showBullets: false,
        exitOnOverlayClick: false
      })
      .start()
      localStorage.setItem('onboardingComplete', 'true')
  }
}