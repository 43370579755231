import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'

import BridgeAudioSection from './default'
import DesktopAudioSection from './desktop'
import { StyledH1 } from '../styles'

function AudioSection(props) {
  const store = useStore();
  let audioSection = <BridgeAudioSection {...props} />
  if (store.board?.isDesktopDevice) {
    audioSection = <DesktopAudioSection {...props} />
  }

  return (
    <>
      <StyledH1>Audio</StyledH1>
      {audioSection}
    </>
  )
}

export default observer(AudioSection)
