import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  padding: ${props => props.p ? props.p : '1rem'};
  margin: ${props => props.m ? props.m : 0};
  display: block;
  background: ${props => props.bg ? props.bg : 'var(--ELK-White)'};
  color: var(--Elk-Black);
  border: 0;
  font-size: 1.4rem;
  transition: .2s ease;
  &::placeholder {
    color: var(--ELK-Light-Grey);
  }
  &:disabled {
    cursor: default;
    background: var(--ELK-Dark-Grey);
  }
`

/* eslint-disable-next-line */
export const TextField = forwardRef((props, ref) => <Input {...props} ref={ref} />)
