/* eslint-disable no-useless-computed-key */

import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { APIClient } from '../../api/client'
import { Button } from '../ui/button'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text, H1, Center } from '../ui/primitives'
import { showThanksDialog } from './thanks'
import { logger } from '../../utils/logging'

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 26rem;
  padding: 1rem 1.2rem;
  border: 1px solid var(--ELK-Border);
  font-style: italic;
  font-size: 2.4rem;
`

const Label = styled.label`
  width: 4rem;
  height: 4rem;
  margin: 3px;
  position: relative;
`

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  appearance: none;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`

const Stars = styled.div`
  display: flex;
  &:hover label div {
    background: var(--ELK-High-Prio-Yellow-Enabled);
  }
  label:hover ~ label div {
    background: var(--ELK-Standard-Inactive);
  }
`

const Star = styled.div`
  background: var(--ELK-Standard-Inactive);
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  width: 100%; height: 100%;
  ${({ filled }) => filled && `background: var(--color-yellow);`}
`

const FeedbackDialog = observer(({ store, close, userId, sessionId }) => {
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const submit = e => {
    e.preventDefault()
    try {
      APIClient.attach_user_rating_to_session({
        rating: rating,
        comment: comment,
        user_id: userId,
        session_id: sessionId,
      })

      store.analytics.track('Feedback Given', {
        ['Feedback Result']: rating,
        ['Elk Session ID']: sessionId,
        ['Comment'] : comment
      })

      close()
      showThanksDialog({ text: 'Your feedback is very valuable to us!' })
    } catch (err) {
      logger.error('Error trying to send feedback', err)
      store.showSnackBar({
        heading: 'Could not send feedback',
        content: 'Please contact support',
        level: 'error',
        duration: 12000,
      })
    }
  }

  return (
    <Dialog width="82rem" py="3rem" px="3rem"
      bgColor="var(--ELK-White)">
      <CloseButton color="var(--ELK-Black)" onClick={() => close()}/>

      <H1 textAlign="center" mb="1.6rem" color="var(--ELK-Black)">Help us improve!</H1>
      <Text textAlign="center" mb="1.4rem" mt="2.5rem" color="var(--ELK-Standard-Enabled)" fontSize="2.4rem">
        Please share any info on challenges<br/>encountered during your session.<br/>Thank you!
      </Text>
      <Center mb="2.5rem" mt="4rem">
        <Stars>
          {[...Array(5).keys()]
            .map(i => i + 1)
            .map(i => (
              <Label key={i}>
                <Input type="radio" value={i} name="rating" onChange={() => setRating(i) } />
                <Star filled={rating >= i} />
              </Label>
            ))}
        </Stars>
      </Center>
      <Box flex justifyContent="center" alignItems="center">
        <TextArea name="comment" placeholder="Type something..." value={comment} onChange={e => setComment(e.target.value) } />
      </Box>
      <Box mt="2rem" maxWidth="26rem" mx="auto">
        <Button primary width="100%" disabled={rating === 0 && comment === ''} onClick={(e) => submit(e)}>
          Send Feedback
        </Button>
      </Box>
    </Dialog>
  )
})

export function showFeedbackDialog({ store, userId, sessionId }) {
  return openModal(
    ({ close }) => <FeedbackDialog store={store} close={close} userId={userId} sessionId={sessionId}/>, {
      autoDismissable: true,
    }
  )
}
