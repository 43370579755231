import React from 'react'
import styled from 'styled-components'
import {
  ElkLiveLogo,
} from '../../ui/icons'

const LogoButton = styled.button`
  height: 80%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;

  *:only-child {
    margin-right: -0.5rem;
  }
`

const ApplicationLogos = ({...props}) => (
  <LogoButton {...props}>
    <ElkLiveLogo size={58} />
  </LogoButton>
)

export default ApplicationLogos
