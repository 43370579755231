import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getCurrentVersion } from './utils/utils'

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    release: getCurrentVersion().version,
    environment: getCurrentVersion().env,
    tracesSampleRate: 1.0,
  });
}

