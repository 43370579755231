import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { openModal } from '../overlays'
import Button from '../../ui/button'
import { Bold, Box, H1, Text } from '../../ui/primitives'
import { Dialog } from '../base'
import { withStore } from '../../../store'
import { relaunchApp } from '../../../store/electronAPI'
import { ExclamationIcon } from '../../ui/icons'
import { showReportIssueDialog } from '../report-issue'
import { closeAllModals } from '../../../utils/utils'

const Link = styled.a`
  text-decoration: underline;
`

const Paragraph = styled(Text)`
  margin: 10px 0;
  strong {
    font-weight: 700;
  }
`

const DesktopGenericError = observer(({ close, store, reload = false }) => {
  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      color="var(--ELK-Black)"
      width="55rem"
      alignItems="center"
      textAlign="center"
      fontSize="1.6rem"
    >
      <ExclamationIcon width={80} height={80} />
      <H1 mb="1rem" color="var(--ELK-Black)">Something happened!</H1>
      <Box>
        <Paragraph>
          Sometimes a fresh reboot sort things out, try it out!
        </Paragraph>

      </Box>
      <Box mt="1.2rem" mb="1.2rem" width="100%">
      <Button primary onClick={() => {
        if(reload) {
          window.location.reload()
        } else {
          relaunchApp()
        }
      }} width="100%">{reload ? 'Reload' : 'Reboot'}</Button>
    </Box>
      <Box>
        <Paragraph>
          Elk LIVE only runs at <Bold>48 kHz</Bold>. <br />
          If you need to use another audio application at the same time, please make sure it also runs at 48 kHz.
        </Paragraph>
      </Box>
        <Box mt="1rem" width="50rem">
         Still doesn't work?&nbsp;
          <Link onClick={() => {
            close()
            showReportIssueDialog({ store })
          }}>Report an issue</Link>.
        </Box>
    </Dialog>
  )
})

export function showDesktopGenericError({ store, reload = false }) {
  closeAllModals()
  return openModal(
    ({ close }) => withStore(store, <DesktopGenericError close={close} reload={reload} store={store}  />),
    {
      autoDismissable: false,
      alwaysOnTop: true,
    },
  )
}
