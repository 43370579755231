import React from 'react'
import { Box, H3 } from '../ui/primitives'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'
import { ProfilePicture } from '../ui/profile-picture'
import {
  CardContent,
  CardList,
  CardMenu,
  CardMenuItem,
  CardListItem,
  FriendStatusItem,
  IconWrapper,
} from './card-components'
import { showSetupSessionDialog } from '../dialogs/setup-session'
import { showRemoveFriendDialog } from '../dialogs/remove-friend'
import {
  ArrowIcon,
  SessionEqualizer,
} from '../ui/icons'
import { showUserProfileModal } from '../dialogs/PeopleProfile'

export const FriendCard = observer(({ user, actionsOpen, handleToggleActions }) => {
  const store = useStore()
  const sortedFriends = store.getSortedFriends()

  //getting fallback data in case get_user call fails
  const fallbackData = sortedFriends.find(({ id }) => user.id === id)
  const fallbackProfileImage = { ...user, profile_image_url:  user.profile_image_url || fallbackData.profileImageUrl }
  const fallbackDisplayName = user.display_name || user.name || fallbackData.displayName

  const friendIsActive = store.userActive.get(user?.id)
  const friendIsAvailable = store.userAvailable.get(user?.id)
  const friendInSession = friendIsActive && !friendIsAvailable

  const status = store.friendsList.find((friend) => friend?.id === user?.id)?.status

  if (status === 'Requested' || status === 'Pending') {
    const outgoing = status === 'Requested'
    const incoming = status === 'Pending'
    return (
      <>
        <CardContent>
          <FriendStatusItem
            bgColor="var(--ELK-Intense-Pink)"
            color="var(--ELK-White)"
          >
            <IconWrapper
              style={incoming ? {transform: 'rotate(180deg)'} : {}}
            >
              <ArrowIcon width={24} />
            </IconWrapper>
            <H3 color="var(--ELK-White)">{outgoing ? 'Outgoing' : 'Incoming'} request!</H3>
            <Box />
          </FriendStatusItem>
          {actionsOpen && (
            <CardMenu>
              <CardMenuItem
                onClick={() => {
                  showUserProfileModal({ store, user })
                }}
              >
                <H3>View profile</H3>
              </CardMenuItem>
              {
                outgoing ? (
                  <CardMenuItem
                    onClick={() =>
                      store.cancelFriendRequest(user.id)
                    }
                  >
                    <H3>Cancel Request</H3>
                  </CardMenuItem>
                )
                : (
                  <>
                    <CardMenuItem
                      onClick={() =>
                        store.replyToFriendRequest(user.id, true, () => { handleToggleActions(false)})
                      }
                    >
                      <H3>Accept Request</H3>
                    </CardMenuItem>
                    <CardMenuItem
                      onClick={() =>
                        store.replyToFriendRequest(user.id, false, () => { handleToggleActions(false)})
                      }
                    >
                      <H3>Decline Request</H3>
                    </CardMenuItem>
                  </>
                  )
              }
            </CardMenu>
          )}
          <ProfilePicture user={fallbackProfileImage} />
        </CardContent>
        <CardList>
          <CardListItem bgColor="var(--ELK-Black)" >
            <H3 color="var(--ELK-White)">{fallbackDisplayName}</H3>
          </CardListItem>
        </CardList>
      </>
    )
  }

  return (
    <>
      <CardContent>
        <ProfilePicture user={fallbackProfileImage} />
        {actionsOpen && (
          <CardMenu>
            <CardMenuItem
              onClick={() => {
                showUserProfileModal({ store, user })
              }}
            >
              <H3>View profile</H3>
            </CardMenuItem>
            <CardMenuItem
              className={`${(!friendIsActive || !friendIsAvailable || store.sessionMode) ? 'disabled' : ''}`}
              onClick={() => {
                if (!friendIsActive || !friendIsAvailable || store.sessionMode) {
                  return
                }
                showSetupSessionDialog({ store, partnerIds: [user.id] })
              }}
            >
              <H3>Start New Session</H3>
            </CardMenuItem>
            <CardMenuItem
              onClick={() =>
                showRemoveFriendDialog({ store, friend: user })
              }
            >
              <H3>Remove Friend</H3>
            </CardMenuItem>
          </CardMenu>
        )}
      </CardContent>
      <CardList>
        <CardListItem px="2.7rem" actionsOpen={actionsOpen}
                      bgColor={friendIsActive ?
                        'var(--ELK-Sea-Foam-Green)' :
                        'var(--ELK-Offline-Grey)'}
        >
          <H3>{fallbackDisplayName}</H3>
          {friendInSession ? (
            <IconWrapper>
              <SessionEqualizer size={15} color="var(--ELK-Black)" />
            </IconWrapper>
          ) : (
            <Box />
          )}
        </CardListItem>
      </CardList>
    </>
  )
})
