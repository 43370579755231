import React, { useEffect, useState } from  'react'
import { observer } from 'mobx-react-lite'
import { useStore, withStore } from '../../../../store'
import { openModal } from '../../../dialogs/overlays'
import { Dialog } from '../../../dialogs/base'
import { Box } from '../../../ui/primitives'
import { Spinner } from '../../../ui/spinner'

const loadingMessages = [
  'turning it up to 11',
  'adding more cowbell',
  'tuning the tambourines',
  'denying stairway',
  'spray painting the VU meters',
  'sorting out rock vs synth',
  'adding scaramouch and fandango',
  'adding smoke to the water',
  'losing some guitar picks',
  'choosing do re or mi fa',
  'turning the haze to purple',
  'making UI harder, better, faster and stronger',
  'thinking of epic band names',
  'taking it to the bridge',
]

const LoadingState = ({ close }) => {
  const [randomMessage, setRandomMessage] = useState( loadingMessages[Math.floor(Math.random()*loadingMessages.length)])
  useEffect(() => {
    const interval = setInterval(() => {
      setRandomMessage(
        loadingMessages[Math.floor(Math.random()*loadingMessages.length)]
      )
    }, 1500)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box flex
         justifyContent="center"
         alignItems="center"
         flexDirection="column"
         textAlign="center"
         width="150px"
         height="150px"
         p="0 20px"
         margin="0 auto"
    >
      <Spinner
        style={{
          verticalAlign: 'top',
          marginRight: '3px',
        }}
        size={22}
        color="var(--ELK-Sea-Foam-Green)"
      />
      setting up... <br />
      <strong style={{ fontWeight: 800}}>{randomMessage}</strong>
    </Box>
  )
}

const LoadingDesktopDialog = observer(({ close }) => {
  const store = useStore()

  useEffect(() => {
    if(store.isDesktopAudioInitializing === false){
      close()
    }
    // eslint-disable-next-line
  }, [store.isDesktopAudioInitializing])

  return (
    <Dialog width="auto">
      <LoadingState close={close}  />
    </Dialog>
  )
})


export function showLoadingDesktopDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store,
      <LoadingDesktopDialog close={close}  />
    )
  )
}
