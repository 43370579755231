import { useRosterState } from 'amazon-chime-sdk-component-library-react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../store'
import RemoteParticipant from './remote-participant'

const RemoteParticipants = observer(({ partnerIds }) => {
  const store = useStore()

  partnerIds.forEach((id) => {
      store.requestFetchUserInfo(id)
    }
  )

  return <>
    {partnerIds.map((id) => (<Attendee id={id} /> ))}
  </>
})

const Attendee =observer(({ id }) => {
  const { roster } = useRosterState()
  const store = useStore()
  const attendees = Object.values(roster)
  const user = store.users.get(id)
  const joinedAttendee = attendees.find((a) => parseInt(a.externalUserId) === id)
  const localAttendee = attendees.find(a => parseInt(a.externalUserId) === store.currentUserId)
  return <RemoteParticipant key={id} attendee={joinedAttendee} user={user} localAttendee={localAttendee} />
})

export default RemoteParticipants
