import React from 'react'
import { H3 } from '../ui/primitives'
import { observer } from 'mobx-react-lite'
import { ProfilePicture } from '../ui/profile-picture'
import {
  CardContainer,
  CardContent,
  CardList,
  CardListItem,
} from './card-components'
import styled from 'styled-components'

const StyledCardListItem = styled(CardListItem)`
  background-color: ${(props) => props.bgColor && props.bgColor};
  color: ${(props) => props.color && props.color};
`

const StyledCardContainer = styled(CardContainer)`
  cursor: default;
  margin-right: 0;
`

export const NonInteractiveCard = observer(({ user }) => {
  return (
    <StyledCardContainer onMouseEnter={() => {}} onMouseLeave={() => {}}>
      <CardContent>
        <ProfilePicture user={user} />
      </CardContent>
      <CardList>
        <StyledCardListItem color="#fff" bgColor="var(--ELK-Black)">
          <H3>{user?.name}</H3>
        </StyledCardListItem>
      </CardList>
    </StyledCardContainer>
  )
})
