import React from 'react'
import styled from 'styled-components'
import { Box, Center } from '../../ui/primitives'
import { ProfilePicture } from '../../ui/profile-picture'

const Container = styled.div`
  position: relative;
  background: var(--Elk-Background-Grey);
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const VideoPlaceholder = ({ user }) => (
  <Container>
    <Center absolute inset="0rem">
      <Box width="20rem" height="20rem">
        <ProfilePicture user={user}/>
      </Box>
    </Center>
  </Container>
)

export default VideoPlaceholder
