import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { isBetaMode, isDesktop } from '../../../../utils/utils'
import BetaOnboarding from '../banners/beta-onboarding'
import { AddFriendCard, FriendsList , EmptyFriendsContainer, FriendsStyledH2} from '../styles'
import { Card } from '../../../cards/card'
import { H2 } from '../../../ui/primitives'
import { AddFriendIcon } from '../../../ui/icons'
import { showAddFriendsDialog } from '../../../dialogs/add-friends'

const FriendsView = observer(() => {
  const store = useStore()
  const sortedFriends = store.getSortedFriends()

  return (
    <>
      {isDesktop() && isBetaMode() &&
        (
          <BetaOnboarding />
        )}
      <FriendsList>
        {sortedFriends.length > 0 &&  <FriendsStyledH2>the friends</FriendsStyledH2>}
        {sortedFriends.map((user) => {
          if (
            user.status === 'Confirmed' ||
            user.status === 'Requested' ||
            user.status === 'Pending'
          ) {
            return <Card key={user.id} user={{ ...store.users.get(user.id), id: user.id }} type="friend" />
          } else {
            return false
          }
        })}
        <AddFriendCard onClick={() => {showAddFriendsDialog({ store })}}>
          <AddFriendIcon width={80} height={80} />
          <H2>Add friends</H2>
        </AddFriendCard>

        {sortedFriends.length === 0 && (
          <EmptyFriendsContainer>
            <H2>Time to find & <br />add some friends. </H2>
            <p>
              This area will be the place for your friends. If you already know who you’re looking for, just click “add friends” to the left and search using email or name.
            </p>
          </EmptyFriendsContainer>
        )}

      </FriendsList>
    </>
  );
})

export default FriendsView