import { openModal } from '../overlays'
import { Box, Text } from '../../ui/primitives'
import { Dialog } from '../base'
import { ProfilePicture } from '../../ui/profile-picture'
import React, { useEffect, useState } from 'react'
import { withStore } from '../../../store'
import { MapMarker } from '../../ui/icons'
import { observer } from 'mobx-react-lite'
import {
  NameSection,
  UserInfoSection,
  StyledTextArea,
  Title,
  ActionButton,
  PersonalSummaryText,
  UserInfoScrollable
} from './styles'
import { StatusInfoHeader } from './components/status-header'
import { ActionButtons } from './components/actions'
import { MessageBubble } from './components/message'

const UserProfileModal = observer(({ store, user, close }) => {

  const [friendRequest, setFriendRequest] = useState(false)
  const [initialMessage,setInitialMessage] = useState('')

  useEffect(() => {
    store.requestFetchUserInfo(user?.id, true)
    // eslint-disable-next-line
  }, [])

  const getFormattedLocation = (location) => {
    const data = location && location.split(':')
    if(data.length > 0) return `${data[1]}, ${data[0]}`
    return ''
  }

  const friendRequestData = store.friendsList.find((friend) => friend?.id === user?.id)
  const storedUser = store.users.get(user?.id)
  const userData = storedUser || user
  const isOwnUser = user.id === store.currentUserId
  const status = friendRequestData?.status
  const message = friendRequestData?.initialMessage
  const location = user?.last_seen_location || userData?.location
  const requested = status === 'Requested'

  return (
    <Dialog width="300px" py="0" px="0">
      {friendRequest ? (
          <>
            <UserInfoSection>
              <Title>Friend Request</Title>
              <Text py="5px" fontWeight="300">
                Who are you? What do you play? Why is this person interesting? Add a personal message here! It will result in better matches! (and show that you’re serious)
              </Text>
              <StyledTextArea
                onChange={(e) => {
                  setInitialMessage(e.target.value)
                }}
                value={initialMessage}
                maxLength={500}
              ></StyledTextArea>
              <Box textAlign="end" fontWeight="300">
                <Text>{initialMessage.length}/500</Text>
              </Box>
              <ActionButton disabled={requested} onClick={async () => {
                await store.sendFriendRequest({ friendId: user.id, initialMessage })
                setFriendRequest(false)
              }}>Send Request
              </ActionButton>
            </UserInfoSection>
          </>)
        : (
          <>
            {!isOwnUser && (
              <StatusInfoHeader status={status} close={close} />
            )}
            <Box height="250px">
              <ProfilePicture user={user} nonFriend />
            </Box>
            <NameSection>
              <Text>{userData.display_name}</Text>
            </NameSection>
            <UserInfoSection>
              <UserInfoScrollable>
                {location &&
                  (
                    <Text py="5px" display="flex" alignItems="center">
                      <MapMarker width={10} />
                      <Text ml="5px">{getFormattedLocation(location)}</Text>
                    </Text>
                  )
                }
                <PersonalSummaryText py="5px" fontWeight="300">
                  {userData.personal_summary}
                </PersonalSummaryText>

                {message && status !== 'Confirmed' && (
                  <MessageBubble>
                    <p>
                      <strong>Message
                        {status === 'Requested' && ' to '}
                        {status === 'Pending' && ' from '}
                        {userData.display_name}:</strong> <br />
                      {message}
                    </p>
                  </MessageBubble>
                )}
              </UserInfoScrollable>
              <ActionButtons
                isOwnUser={isOwnUser}
                user={user}
                status={status}
                close={close}
                onConnect={ () => setFriendRequest(true)} />
            </UserInfoSection>
          </>
        )}
    </Dialog>
  )
})

export function showUserProfileModal({ store, user }) {
  return openModal(
    ({ close }) => withStore(store, <UserProfileModal close={close} store={store} user={user}  />),
    {
      autoDismissable: true,
      alwaysOnTop: true
    },
  )
}