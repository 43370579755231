import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/button'
import { showReportIssueDialog } from './report-issue'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text, H1 } from '../ui/primitives'
import { useStore, withStore } from '../../store'
import { startTour } from '../../store/onboarding'

const HelpDialog = observer(({ close }) => {
  const store = useStore()

  return (
    <Dialog
      width="60rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)"/>

      <H1 color="var(--ELK-Black)" textAlign="center" mb="2.5rem">Need some help?</H1>
      <Text textAlign="center" mb="3rem" color="var(--ELK-Black)" fontSize="2.4rem">Don't worry. We got you!</Text>

      <Box mt="2rem" maxWidth="32rem" mx="auto" flex column rowGap="2rem">
        <a href="https://help.elk.live/en/articles/7034218-elk-live-requirements" target="_blank" rel="noreferrer">
          <Button primary height="3.8rem" width="100%" fontSize="1.8rem">Requirements</Button>
        </a>
        <a href="https://help.elk.live/" target="_blank" rel="noreferrer">
          <Button primary height="3.8rem" width="100%" fontSize="1.8rem">Go to support</Button>
        </a>
        <Button primary height="3.8rem" width="100%" fontSize="1.8rem" onClick={() => {showReportIssueDialog({ store }); close()}}>Report an Issue</Button>
        <Button primary height="3.8rem" width="100%" fontSize="1.8rem" onClick={() => {close(); startTour(store, true)}}>Show Onboarding</Button>
      </Box>
    </Dialog>
  )
})

export function showHelpDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <HelpDialog close={close}/>), {
      autoDismissable: true,
    }
  )
}
