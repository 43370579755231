import { BgContainer, BgOverlay, Container, Header, IconWrapper } from './styles'
import { ElkLiveLogo } from '../../../ui/icons'

export const LoginLayout = ({ children }) => {
  return (
    <Container>
      <Header>
        <IconWrapper>
          <ElkLiveLogo
            size={58}
          />
        </IconWrapper>
      </Header>
      <BgContainer>
        <BgOverlay>
          {children}
        </BgOverlay>
      </BgContainer>
    </Container>
  )
}